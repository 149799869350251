import React, { useState, useEffect } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import HostingPackagesItem from './HostingPackagesItem'
import HostingPackageServices from './HostingPackageServices'
import ModalWindow from '../layout/ModalWindow'
import SlickSlider from '../layout/SlickSlider'

const HostingPackages = ({ packages }) => {
  const { width } = useWindowDimensions()

  let slidesToShowValue = 3
  let centerModeValue = false
  let sliderMarginValue = '0px -20px 100px -20px'

  if (width <= settings.desktopBreakpoint && width > settings.padBreakpoint) {
    slidesToShowValue = 2
  } else if (width <= settings.desktopBreakpoint) {
    slidesToShowValue = 1
    centerModeValue = true
  }

  if (width > settings.padBreakpoint && packages.length > slidesToShowValue) {
    sliderMarginValue = '0px 44px 100px 44px'
  } else if (width > settings.padBreakpoint) {
    sliderMarginValue = '0px -20px 100px -20px'
  } else if (width >= 600) {
    sliderMarginValue = '0px -24px 50px -24px'
  } else {
    sliderMarginValue = '0px -16px 50px -16px'
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [slides, setSlides] = useState([])
  const [slidesToShow, setSlidesToShow] = useState(slidesToShowValue)
  const [centerMode, setCenterMode] = useState(centerModeValue)
  const [sliderMargin, setSliderMargin] = useState(sliderMarginValue)

  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxPrev = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '45%',
    left: '-44px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const sxNext = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '45%',
    right: '-44px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  const handleOpenServicesModal = ({ title, services }) => {
    setModalOpen(true)
    setModalTitle(title)
    setModalContent(<HostingPackageServices services={services} />)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (width > settings.desktopBreakpoint) {
      setSlidesToShow(3)
      setCenterMode(false)
    } else if (width > settings.padBreakpoint) {
      setSlidesToShow(2)
      setCenterMode(false)
    } else {
      setSlidesToShow(1)
      setCenterMode(true)
    }
  }, [width])

  useEffect(() => {
    let t = []
    packages.forEach((item, index) => {
      t.push(<HostingPackagesItem
        key={index}
        {...item}
        handleOpenServicesModal={handleOpenServicesModal}
        setModalOpen={setModalOpen}
        setModalTitle={setModalTitle}
        setModalContent={setModalContent}
      />)
    })
    setSlides(t)
  }, [packages])

  useEffect(() => {
    if (width > settings.padBreakpoint && packages.length > slidesToShow) {
      setSliderMargin('0px 44px 100px 44px')
    } else if (width > settings.padBreakpoint) {
      setSliderMargin('0px -20px 100px -20px')
    } else if (width >= 600) {
      setSliderMargin('0px -24px 50px -24px')
    } else {
      setSliderMargin('0px -16px 50px -16px')
    }
  }, [width, packages, slidesToShow])
  
  return (
    <>
      <SlickSlider
        slides={slides}
        sxBody={{ position: 'relative', margin: sliderMargin }}
        arrows={sliderArrows}
        pagination={{}}
        autoplay={false}
        draggable={true}
        speed={sliderSpeed}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        centerMode={centerMode}
        handleAfterChange=''
      />
      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButtons=''
        modalWidth='md'
        onClose={handleCloseModal}
      />
    </>
  )
}

export default HostingPackages