import React from 'react'
import { useSelector } from 'react-redux'
import PersonIcon from '@mui/icons-material/Person'
import { ProfileButton } from '../../themes/button'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const HeaderProfileButton = () => {
  const { width } = useWindowDimensions()
  
  const lang = useSelector(state => state.lang)

  const sxButton = width > settings.desktopBreakpoint ? '' : { width: '100%', margin: '30px 0px' }


  const handleClickProfile = () => {
    window.open(settings.myGrenaUrl, '_blank')
  }

  return (
    <>
      <ProfileButton variant='outlined' sx={sxButton} startIcon={<PersonIcon />} disableRipple={true} onClick={handleClickProfile}>{constants.profile[lang]}</ProfileButton>
    </>    
  )
}

export default HeaderProfileButton