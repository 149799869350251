const getMonthName = (month, language) => {
  let monthsKa = []
  let monthsEn = []

  monthsKa[0] = 'იანვარი'
  monthsKa[1] = 'თებერვალი'
  monthsKa[2] = 'მარტი'
  monthsKa[3] = 'აპრილი'
  monthsKa[4] = 'მაისი'
  monthsKa[5] = 'ივნისი'
  monthsKa[6] = 'ივლისი'
  monthsKa[7] = 'აგვისტო'
  monthsKa[8] = 'სექტემბერი'
  monthsKa[9] = 'ოქტომბერი'
  monthsKa[10] = 'ნოემბერი'
  monthsKa[11] = 'დეკემბერი'

  monthsEn[0] = 'January'
  monthsEn[1] = 'February'
  monthsEn[2] = 'March'
  monthsEn[3] = 'April'
  monthsEn[4] = 'May'
  monthsEn[5] = 'June'
  monthsEn[6] = 'July'
  monthsEn[7] = 'August'
  monthsEn[8] = 'September'
  monthsEn[9] = 'October'
  monthsEn[10] = 'November'
  monthsEn[11] = 'December'

  if (language === 'ka') return monthsKa[month]
  else if (language === 'en') return monthsEn[month]
}

export const formatNewsDate = (start_date, end_date, language) => {
  const startDate = start_date ? new Date(start_date) : new Date()
  const endDate = end_date ? new Date(end_date) : new Date()

  const startDay = startDate.getDate()
  const startMonth = startDate.getMonth()
  const startYear = startDate.getFullYear()

  const endDay = endDate.getDate()
  const endMonth = endDate.getMonth()
  const endYear = endDate.getFullYear()

  if (!end_date) {
    if (language === 'ka') {
      return ( startDay + ' ' + getMonthName(startMonth, language) + ', ' + startYear )
    } else {
      return ( getMonthName(startMonth, language) + ' ' + startDay + ', ' + startYear )
    }
  } else if (startMonth === endMonth && startYear === endYear) {
    if (language === 'ka') {
      return ( startDay + '-' + endDay + ' ' + getMonthName(startMonth, language) + ', ' + startYear )
    } else {
      return ( getMonthName(startMonth, language) + ' ' + startDay + '-' + endDay + ', ' + startYear )
    }
  } else if (startYear === endYear) {
    if (language === 'ka') {
      return ( startDay + ' ' + getMonthName(startMonth, language) + ' - ' + endDay + ' ' + getMonthName(endMonth, language) + ', ' + startYear )
    } else {
      return ( getMonthName(startMonth, language) + ' ' + startDay + ' - ' + getMonthName(endMonth, language) + ' ' + endDay + ', ' + startYear )
    }
  } else {
    if (language === 'ka') {
      return ( startDay + ' ' + getMonthName(startMonth, language) + ', ' + startYear + ' - ' + endDay + ' ' + getMonthName(endMonth, language) + ', ' + endYear )
    } else {
      return ( getMonthName(startMonth, language) + ' ' + startDay + ', ' + startYear + ' - ' + getMonthName(endMonth, language) + ' ' + endDay + ', ' + endYear )
    }
  }
}

const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 1 : months + 1;
}

export const formatProjectDate = (start_date, end_date, language) => {
  const monthName = language === 'ka' ? 'თვე' : 'months'

  const startDate = start_date ? new Date(start_date) : new Date()
  const endDate = end_date ? new Date(end_date) : new Date()

  const startDay = ('0' + startDate.getDate()).slice(-2)
  const startMonth = ('0' + (startDate.getMonth() + 1)).slice(-2)
  const startYear = startDate.getFullYear()

  const endDay = ('0' + endDate.getDate()).slice(-2)
  const endMonth = ('0' + (endDate.getMonth() + 1)).slice(-2)
  const endYear = endDate.getFullYear()

  const duration = startDay + '/' + startMonth + '/' + startYear + '-' + endDay + '/' + endMonth + '/' + endYear + ' (' + monthDiff(startDate, endDate) + ' ' + monthName + ')'

  return duration
}

export const getFormattedDate = (date) => {
  let now = new Date()

  if (date) now = new Date(date)

  const formattedDate = now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2)
  
  return formattedDate
}