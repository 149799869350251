import {
  partnerCiscoImage,
  partnerLpiImage,
  partnerPearsonImage,
  partnerEyeImage,
  partnerGeantImage,
  partnerEgiImage,
  partnerTfImage,
  partnerTcImage,
} from '../themes/image'

export const partnersItems = [
  {
    id: '',
    name: 'Cisco',
    logo_url: partnerCiscoImage,
    website: 'https://www.cisco.com/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerLpiImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerPearsonImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerEyeImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerGeantImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerEgiImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerTfImage,
    website: '/',
  },
  {
    id: '',
    name: '',
    logo_url: partnerTcImage,
    website: '/',
  },
]