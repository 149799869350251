import React from 'react'
import { useTheme } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const DomainSearchSelect = ({ value, list, placeholder, handleChange }) => {
  const theme = useTheme()

  const sx = {
    width: '100%',
    height: '72px',
    background: `${theme.palette.offwhite.main}`,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.darkblue.main,
    borderTop: '#b2b4bc 1px solid',
    borderRight: '#b2b4bc 1px solid',
    borderBottom: '#b2b4bc 1px solid',
    borderRadius: '0px 8px 8px 0px',

    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none',
    },

    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  }

  const sxPaper = {
    background: theme.palette.offwhite.main,
    border: '#b2b4bc 1px solid',
    borderRadius: '8px',
    boxShadow: 'none',
  }

  return (
    <Select
      sx={sx}
      MenuProps={{ PaperProps: {sx: sxPaper}, disableScrollLock: true }}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    >
      {
        list.map((item, index) => (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))
      }
    </Select>
  )
}

export default DomainSearchSelect