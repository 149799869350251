import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { alpha } from '@mui/material'

const ModalWindow = ({ modalOpen, modalTitle, modalContent, modalButtons, modalWidth, onClose }) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [buttons, setButtons] = useState([])
  const [width, setWidth] = useState('')
  
  const sxModalBox = {
    position: 'absolute',
    maxHeight: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    background: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '10px 10px 30px rgba(0, 0, 0, 0.25)',
    padding: '30px 0px 30px 30px',
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 1199px)': {
      width: '90%',
    }
  }

  const sxModalContainer = {
    overflowY: 'auto',
    padding: '10px 30px 0px 0px',
  }

  const sxHeadBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
  }

  const sxTitleBox = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    textTransform: theme.typography.mainmenu.textTransform,
    fontSize: '20px',
    lineHeight: '28px',
    color: theme.palette.secondary.main,
    marginRight: '30px',
  }

  const sxCloseBox = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '32px',
    lineHeight: '32px',
    marginRight: '30px',
    color: 'rgba(6, 14, 54, 0.5)',
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.8'
    }
  }

  const sxFootBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: buttons.length > 0 ? '30px' : '10px',
    gap: '20px',
  }

  const sxButtonDefault = {
    height: '56px',
    fontFamily: theme.typography.mainmenu.fontFamily,
    textTransform: theme.typography.mainmenu.textTransform,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.offwhite.main,
    padding: '0px 30px',
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: '8px',

    '&:hover': {
      boxShadow: 'none',
    }
  }

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setTitle('')
      setContent('')
      setButtons([])
    }, 500)
    if (onClose) onClose()
  }

  useEffect(() => {
    setOpen(modalOpen)
    setTitle(modalTitle)
    setContent(modalContent)
    if (modalButtons) setButtons(modalButtons)
    setWidth(modalWidth)
  }, [modalOpen, modalTitle, modalContent, modalButtons, modalWidth])
  
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock>
      <Fade in={open} timeout={500}>
        <Box sx={sxModalBox} maxWidth={width}>
          
            <Box sx={sxHeadBox}>
              <Box sx={sxTitleBox}>{title}</Box>
              <Box sx={sxCloseBox} onClick={handleClose}>&#10006;</Box>
            </Box>

            <Box sx={sxModalContainer}>
              <Box>{content}</Box>
              <Box sx={sxFootBox}>
                {
                  buttons.map((item, index) => {
                    const background = item.bg ? item.bg : theme.palette.primary.main
                    const bg = {
                      background,

                      '&:hover': {
                        background: alpha(`${background}`, 0.7),
                        boxShadow: 'none',
                      }
                    }
                    const sxButton = { ...sxButtonDefault, ...bg }

                    const func = item.func ? item.func : handleClose
                    
                    return (
                      <Button variant='contained' disableRipple key={index} sx={sxButton} onClick={func}>{item.text}</Button>
                    )
                  })
                }
              </Box>
          </Box>
          
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalWindow