import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { RegularPageContentR5Box } from '../../themes/boxLayout'
import { CourseInfoTitleBox, CourseInfoUlBox, CourseInfoDescriptionBox } from '../../themes/boxEducation'
import { CourseSyllabusDownloadButton } from '../../themes/button'
import { constants } from '../../data/constants'

const CourseInfo = ({
  duration,
  frequency,
  lessonDuration,
  startTime,
  language,
  materialsLanguage,
  price,
  paymentDescription,
  studyProcess,
  syllabus,
  registrationInstructions
}) => {
  const lang = useSelector(state => state.lang)

  const handleDownloadSyllabus = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(syllabus, '_blank')
    } else if (e.type === 'click') {
      window.open(syllabus, '_blank')
    }    
  }

  return (
    <Container maxWidth='xl'> 
      <RegularPageContentR5Box sx={{ paddingTop: '0px' }}>

        <Box sx={{ paddingBottom: '60px' }}>
          <CourseInfoTitleBox>{constants.process[lang]}</CourseInfoTitleBox>
          {
            duration && (                
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.courseDuration[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{duration}</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }

          {
            frequency && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.lectures[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{constants.frequency[lang].replace('###NUM###', frequency)}</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }

          {
            lessonDuration && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.lectureDuration[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{lessonDuration} {constants.hours[lang]}</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }

          {
            startTime.length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box>{constants.lectureStart[lang]}:&nbsp;</Box>
                </CourseInfoDescriptionBox>
                <CourseInfoDescriptionBox>
                  <Box sx={{ fontWeight: 'bold' }}>
                    {
                      startTime.map((item, index) => (
                        <Box key={index}>{item}</Box>
                      ))
                    }
                  </Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }
          
          {
            language && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.language[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{language}</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }
          
          {
            materialsLanguage && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.learningMaterial[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{materialsLanguage}</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }
        </Box>

        <Box sx={{ paddingBottom: '60px' }}>
          <CourseInfoTitleBox>{constants.priceTerms[lang]}</CourseInfoTitleBox>

          {
            price && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <CourseInfoUlBox></CourseInfoUlBox>
                <CourseInfoDescriptionBox>
                  <Box component='span'>{constants.coursePrice[lang]}: </Box>
                  <Box component='span' sx={{ fontWeight: 'bold' }}>{price} &#8382;</Box>
                </CourseInfoDescriptionBox>
              </Box>
            )
          }

          {
            paymentDescription.length > 0 && (
              paymentDescription.map((item, index) => (
                <CourseInfoDescriptionBox key={index} sx={{ paddingBottom: (index !== paymentDescription.length - 1 ? '30px' : '0px') }}>{item[lang]}</CourseInfoDescriptionBox>
              ))
            )
          }
        </Box>

        <Box sx={{ paddingBottom: '60px' }}>
          <CourseInfoTitleBox>{constants.learningIssues[lang]}</CourseInfoTitleBox>

          {
            syllabus && <CourseSyllabusDownloadButton variant='contained' disableRipple onClick={handleDownloadSyllabus} onMouseDown={handleDownloadSyllabus}>{constants.syllabusInfo[lang]}</CourseSyllabusDownloadButton>
          }

          {
            studyProcess.length > 0 && (
              studyProcess.map((item, index) => (
                <CourseInfoDescriptionBox key={index} sx={{ paddingBottom: (index !== studyProcess.length - 1 ? '30px' : '0px') }}>{item[lang]}</CourseInfoDescriptionBox>
              ))
            )
          }
        </Box>

        <Box sx={{ paddingBottom: '60px' }}>
          <CourseInfoTitleBox>{constants.enrollmentRules[lang]}</CourseInfoTitleBox>

          {
            registrationInstructions.length > 0 && (
              registrationInstructions.map((item, index) => (
                <CourseInfoDescriptionBox key={index} sx={{ paddingBottom: (index !== registrationInstructions.length - 1 ? '30px' : '0px') }}>{item[lang]}</CourseInfoDescriptionBox>
              ))
            )
          }
        </Box>

      </RegularPageContentR5Box>
    </Container>
  )
}

export default CourseInfo