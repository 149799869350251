import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { edugainPage, edugainDocs, edugainMembers } from '../../data/edugain'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEdugain } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import EdugainPage from './EdugainPage'
import EdugainMembers from './EdugainMembers'
import EdugainDocs from './EdugainDocs'
import ContentButton from '../form/ContentButton'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Edugain = () => {
  const { width } = useWindowDimensions()
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [members, setMembers] = useState([])
  const [docs, setDocs] = useState([])
  const [page, setPage] = useState([])
  const [buttonText, setButtonText] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const sxButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: '20px',
    color: theme.palette.offwhite.main,
    background: theme.palette.primary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    borderRadius: '8px',
    padding: '0px 100px',
    marginBottom: '30px',
    height: '92px',
    boxShadow: 'none',

    '&:hover': {
      background: 'rgba(7, 77, 120, 0.9)',
      boxShadow: 'none',
    },

    '@media(max-width: 899px)': {
      width: '100%',
      height: 'auto',
      fontSize: '18px',
      padding: '10px',
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchMembers = async () => {
      const response = await request(settings.domain + settings.api + apiEdugain.getMembers, 'GET')

      if (response && response.length > 0) {
        setMembers(response)
      } else {
        setMembers([])
      }
    }

    const fetchDocs = async () => {
      const response = await request(settings.domain + settings.api + apiEdugain.getDocs, 'GET')

      if (response && response.length > 0) {
        setDocs(response)
      } else {
        setDocs([])
      }
    }

    const fetchPage = async () => {
      const response = await request(settings.domain + settings.api + apiEdugain.getPage, 'GET')

      if (response && response.length > 0) {
        setPage(response)
      } else {
        setPage([])
      }
    }

    if (settings.mode === 'live') {
      fetchMembers()
      fetchDocs()
      fetchPage()
    } else {
      setMembers(edugainMembers)
      setDocs(edugainDocs)
      setPage(edugainPage)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (page.length > 0 && members.length > 0 && docs.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [page, members, docs])

  useEffect(() => {
    const getButtonText = (a) => {
      const object = a.find(item => item.var_name === 'buttontext')
      return object ? object.data[0][lang] : ''
    }
    
    setButtonText(getButtonText(page))
  }, [page, lang])
  
  return (
    <>
      <HelmetElement
        metaDescription='eduGAIN, with just one trusted identity provided by user’s institution, users can access services from other participating federations, it works with SSO'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.edugain[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            <EdugainPage page={page} />
            <EdugainMembers page={page} members={members} />
            <EdugainDocs docs={docs} />
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', }}>
              <ContentButton
                formTitle=''
                formType='content'
                formName={settings.formEdugain}
                formID=''
                openButton={buttonText}
                openButtonStyle={sxButton}
                submitButton={constants.send[lang]}
                submitButtonStyle=''
                setModalOpen={setModalOpen}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
              />
              <ModalWindow
                modalOpen={modalOpen}
                modalTitle={modalTitle}
                modalContent={modalContent}
                modalButtons={[]}
                modalWidth='lg'
                onClose={handleCloseModal}
              />
            </Box>
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Edugain