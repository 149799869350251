import React from 'react'
import Box from '@mui/material/Box'
import parse from 'html-react-parser'

const EduroamLocationMap = ({ content }) => {
  return (
    <Box>{parse(content)}</Box>
  )
}

export default EduroamLocationMap