import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import mainMenuArrow from './images/mainmenu_arrow.png'
import mainMenuArrowHover from './images/mainmenu_arrow_hover.png'

export const ProfileButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '16px',
  height: '56px',
  color: theme.palette.offwhite.main,
  borderColor: theme.palette.offwhite.main,
  borderRadius: '8px',
  textTransform: theme.typography.mainmenu.textTransform,
  width: '140px',
  marginLeft: '24px',

  '&:hover': {
    borderColor: theme.palette.offwhite.main,
    backgroundColor: alpha('#B5C8D4', 0.5),
  },

  '@media(max-width: 1199px)': {
    marginLeft: '0px',
  }
}))

export const MainMenuButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: 16,
  marginRight: 15,
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: 'none',
    color: theme.palette.secondary.main
  },
}))

export const MainMenuDropdownButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: 16,
  marginRight: 15,
  paddingRight: 15,
  background: `url(${mainMenuArrow}) no-repeat right center`,
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: `url(${mainMenuArrowHover}) no-repeat right center`,
    color: theme.palette.secondary.main
  },
}))

export const MainMenuDropdownItemButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: '16px',
  borderRadius: 0,
  padding: '15px 0px 3px 0px',
  minWidth: '1px',
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: 'none',
    color: theme.palette.secondary.main,
  },
}))

export const MainMenuDropdownMobileItemButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: '12px',
  borderRadius: 0,
  padding: '15px 0px 3px 0px',
  minWidth: '1px',
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: 'none',
  },
}))

export const HomeServicesMoreButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  float: 'right',
  
  '&:hover': {
    background: 'none'
  },
}))

export const HomeProjectsMoreButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  border: `1px dashed ${theme.palette.offwhite.main}`,
  borderRadius: '4px',
  height: '56px',

  '&:hover': {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.offwhite.main, 0.5),
  },
}))

export const NewsMoreButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  padding: '0px',

  '&:hover': {
    background: 'none',
  },
}))

export const EducationCertificationMoreButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: '#0180A3',
  padding: '0px',
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: 'none',
  },
}))

export const AcademyCoursesRegistrationButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  background: '#2E9CD6',
  borderRadius: '8px',
  width: '80%',
  height: '72px',
  boxShadow: 'none',

  '&:hover': {
    background: alpha('#2E9CD6', 0.7),
    boxShadow: 'none',
  },
}))

export const AcademyCoursesRegistrationDisabledButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  background: '#FF9A03',
  borderRadius: '8px',
  width: '80%',
  height: '72px',
  boxShadow: 'none',
  opacity: '1',
  cursor: 'pointer',

  '&:hover': {
    background: alpha('#FF9A03', 0.7),
    boxShadow: 'none',
  },
}))

export const CourseSyllabusDownloadButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  borderRadius: '8px',
  minHeight: '48px',
  boxShadow: 'none',
  padding: '0px 2%',
  marginBottom: '30px',

  '&:hover': {
    background: alpha(`${theme.palette.primary.main}`, 0.7),
    boxShadow: 'none',
  },
}))

export const CertContactButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  borderRadius: '8px',
  padding: '0px 100px',
  marginBottom: '30px',
  height: '92px',
  boxShadow: 'none',

  '&:hover': {
    background: 'rgba(7, 77, 120, 0.9)',
    boxShadow: 'none',
  },
}))

export const EduroamRegistrationButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: `${theme.palette.secondary.main}`,
  textTransform: theme.typography.mainmenu.textTransform,
  borderRadius: '8px',
  height: '48px',
  boxShadow: 'none',

  '&:hover': {
    background: alpha(`${theme.palette.secondary.main}`, 0.7),
    boxShadow: 'none',
  },

  '@media(max-width: 1199px)': {
    width: '60%',
    marginTop: '20px',
  },

  '@media(max-width: 600px)': {
    width: '100%',
  }
}))

export const EduroamMenuButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',
  padding: '0px',
  minWidth: 'auto',

  '&:hover': {
    background: 'none',
    color: theme.palette.secondary.main
  },
}))

export const EduroamMenuItemButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: 'none',
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,
  textTransform: theme.typography.mainmenu.textTransform,
  padding: '16px 30px',
  minWidth: 'auto',
  width: '100%',
  borderRadius: '0px',
  justifyContent: 'flex-start',
  textAlign: 'left',

  '&:hover': {
    background: 'none',
    color: alpha(`${theme.palette.offwhite.main}`, 0.7),
  },
}))

export const DomainCheckButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: `${theme.palette.primary.main}`,
  textTransform: theme.typography.mainmenu.textTransform,
  borderRadius: '8px',
  width: '90%',
  marginLeft: '10%',
  height: '70px',
  boxShadow: 'none',

  '&:hover': {
    background: `${theme.palette.secondary.main}`,
    boxShadow: 'none',
  },

  '@media(max-width: 899px)': {
    width: '100%',
    margin: '20px 0px 0px 0px',
  }
}))

export const DomainsRulesButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.secondary.main,
  borderRadius: '8px',
  height: '48px',
  boxShadow: 'none',
  padding: '0px 10%',
  textTransform: theme.typography.mainmenu.textTransform,
  marginBottom: '50px',

  '&:hover': {
    background: alpha(`${theme.palette.secondary.main}`, 0.7),
    boxShadow: 'none',
  },

  '@media(max-width: 1199px)': {
    marginBottom: '20px',
  }
}))

export const DomainsProfileButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderRadius: '8px',
  height: '48px',
  boxShadow: 'none',
  padding: '0px 10%',
  textTransform: theme.typography.mainmenu.textTransform,
  margin: '0px 0px 50px 20px',

  '&:hover': {
    background: alpha(`${theme.palette.primary.main}`, 0.7),
    boxShadow: 'none',
  },

  '@media(max-width: 1199px)': {
    margin: '0px 0px 20px 0px',
  }
}))

export const DomainsSearchResultButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderRadius: '8px',
  width: '100%',
  height: '48px',
  boxShadow: 'none',
  textTransform: theme.typography.mainmenu.textTransform,

  '&:hover': {
    background: theme.palette.secondary.main,
    boxShadow: 'none',
  },

  '@media(max-width: 1199px)': {
    marginBottom: '20px',
  }
}))

export const DomainsSearchResultSmallButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderRadius: '8px',
  height: '40px',
  padding: '0px 10%',
  boxShadow: 'none',
  textTransform: theme.typography.mainmenu.textTransform,
  position: 'relative',
  zIndex: '5',

  '&:hover': {
    background: theme.palette.secondary.main,
    boxShadow: 'none',
  },
}))

export const HostingOrderButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderRadius: '8px',
  width: '100%',
  height: '56px',
  padding: '0px',
  boxShadow: 'none',
  textTransform: theme.typography.mainmenu.textTransform,
  position: 'relative',
  zIndex: '4',

  '&:hover': {
    background: theme.palette.secondary.main,
    boxShadow: 'none',
  },
}))

export const FormSubmitButton = styled(Button)(({ theme }) => ({
  height: '56px',
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.offwhite.main,
  padding: '0px 30px',
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: '8px',

  '&:hover': {
    boxShadow: 'none',
  }
}))

export const DomainsRegisterModeButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.primary.main,
  borderRadius: '8px',
  boxShadow: 'none',
  padding: '10px 30px',
  textTransform: theme.typography.mainmenu.textTransform,
  margin: '0px 10px 50px 10px',

  '&:hover': {
    background: alpha(`${theme.palette.primary.main}`, 0.8),
    boxShadow: 'none',
  },

  '@media(max-width: 899px)': {
    margin: '0px 10px 20px 10px',
    width: '100%',
  }
}))

export const DomainsTransferModeButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  background: theme.palette.secondary.main,
  borderRadius: '8px',
  boxShadow: 'none',
  padding: '10px 30px',
  textTransform: theme.typography.mainmenu.textTransform,
  margin: '0px 10px 50px 10px',

  '&:hover': {
    background: alpha(`${theme.palette.secondary.main}`, 0.8),
    boxShadow: 'none',
  },

  '@media(max-width: 899px)': {
    margin: '0px 10px 20px 10px',
    width: '100%',
  }
}))