import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { HomeServicesMoreButton } from '../../themes/button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { constants } from '../../data/constants'

const HomeServicesMore = ({ url }) => {
  const navigate = useNavigate()

  const lang = useSelector(state => state.lang)

  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(url, '_blank')
    } else if (e.type === 'click') {
      navigate(url)
    } 
  }

  return (
    <>
      <HomeServicesMoreButton variant='text' endIcon={<ArrowForwardIcon />} disableRipple onClick={handleClick} onMouseDown={handleClick}>{constants.seeFull[lang]}</HomeServicesMoreButton>
      <Box sx={{ clear: 'both' }}></Box>
    </>
  )
}

export default HomeServicesMore