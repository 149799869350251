import antivirusIcon from './images/software/antivirus_icon.png'
import sslIcon from './images/software/ssl_icon.png'
import officeIcon from './images/software/office_icon.png'
import graphicsIcon from './images/software/graphics_icon.png'
import programmingIcon from './images/software/programming_icon.png'
import adobeLogo from './images/software/adobe_logo.png'
import asposeLogo from './images/software/aspose_logo.png'
import bitdefenderLogo from './images/software/bitdefender_logo.png'
import comodoLogo from './images/software/comodo_logo.png'
import corelLogo from './images/software/corel_logo.jpg'
import devartLogo from './images/software/devart_logo.jpg'
import devexpressLogo from './images/software/devexpress_logo.png'
import digicertLogo from './images/software/digicert_logo.jpg'
import embarcaderoLogo from './images/software/embarcadero_logo.jpg'
import esetLogo from './images/software/eset_logo.png'
import geotrustLogo from './images/software/geotrust_logo.png'
import microsoftLogo from './images/software/microsoft_logo.png'
import officeLogo from './images/software/office_logo.png'
import redgateLogo from './images/software/redgate_logo.png'
import redgiantLogo from './images/software/redgiant_logo.png'
import redhatLogo from './images/software/redhat_logo.png'
import sophosLogo from './images/software/sophos_logo.png'
import symantecLogo from './images/software/symantec_logo.png'
import thawteLogo from './images/software/thawte_logo.png'
import dellsoftwareLogo from './images/software/dellsoftware_logo.png'

export const antivirusIconImage = antivirusIcon
export const sslIconImage = sslIcon
export const officeIconImage = officeIcon
export const graphicsIconImage = graphicsIcon
export const programmingIconImage = programmingIcon
export const adobeLogoImage = adobeLogo
export const asposeLogoImage = asposeLogo
export const bitdefenderLogoImage = bitdefenderLogo
export const comodoLogoImage = comodoLogo
export const corelLogoImage = corelLogo
export const devartLogoImage = devartLogo
export const devexpressLogoImage = devexpressLogo
export const digicerLogoImage = digicertLogo
export const embarcaderoLogoImage = embarcaderoLogo
export const esetLogoImage = esetLogo
export const geotrustLogoImage = geotrustLogo
export const microsoftLogoImage = microsoftLogo
export const officeLogoImage = officeLogo
export const redgateLogoImage = redgateLogo
export const redgiantLogoImage = redgiantLogo
export const redhatLogoImage = redhatLogo
export const sophosLogoImage = sophosLogo
export const symantecLogoImage = symantecLogo
export const thawteLogoImage = thawteLogo
export const dellsoftwareLogoImage = dellsoftwareLogo