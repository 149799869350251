import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  SoftwareCategoryTitleBox,
  SoftwareCategoryTitleBodyBox,
  SoftwareCategoryTitleTextBox,
  SoftwareCategoryLineBox,
  SoftwarePartnersBox,
  SoftwareDescriptionBox
} from '../../themes/boxSoftware'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import SoftwarePartnersItem from './SoftwarePartnersItem'
import SlickSlider from '../layout/SlickSlider'

const SoftwarePartners = ({ cats, partners }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [activeIndex, setActiveIndex] = useState(-1)
  const [logoHoverIndex, setLogoHoverIndex] = useState(-1)
  const [filteredPartners, setFilteredPartners] = useState([])
  const [containerOpacity, setContainerOpacity] = useState(0)
  const [contentOpacity, setContentOpacity] = useState(0)
  const [slides, setSlides] = useState([])
  const [slidesToShow, setSlidesToShow] = useState(4)

  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxBody = {
    height: '100px',
    padding: '0px 40px',
    position: 'relative',
  }

  const sxPrev = {
    color: '#476E84',
    position: 'absolute',
    top: '20%',
    left: '-30px',
    cursor: 'pointer',
  }

  const sxNext = {
    color: '#476E84',
    position: 'absolute',
    top: '20%',
    right: '-30px',
    cursor: 'pointer',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon fontSize='large' />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon fontSize='large' />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  const filterPartners = (category) => {
    const newPartners = partners.filter(item => item.categories.includes(category))
    setFilteredPartners(newPartners)

    let num = width > settings.desktopBreakpoint ? 4 : 3
    if ( newPartners.length > 0 && newPartners.length < num ) num = newPartners.length
    setSlidesToShow(num)
  }
  
  const handleCategoryClick = (index) => {
    if (index === activeIndex) {  
      setContainerOpacity(0)

      setTimeout(() => {
        setActiveIndex(-1)
        setContentOpacity(0)
        filterPartners(0)
      }, 300)
    } else if (contentOpacity === 0) {
      setActiveIndex(index)
      setContentOpacity(1)
      filterPartners(cats[index].id)

      setTimeout(() => {
        setContainerOpacity(1)
      }, 300)

      setTimeout(() => {
        const element = document.getElementById('categories')
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    } else {
      setContentOpacity(0)
      
      setTimeout(() => {
        setActiveIndex(index)
        setContentOpacity(1)
        filterPartners(cats[index].id)
      }, 200)
    }
  }

  const handleLogoMouseEnter = (index) => {
    setLogoHoverIndex(index)
  }

  const handleLogoMouseLeave = () => {
    setLogoHoverIndex(-1)
  }

  useEffect(() => {
    const filterPartnersHook = (category) => {
      const newPartners = partners.filter(item => item.categories.includes(category))
      setFilteredPartners(newPartners)
  
      let num = width > settings.desktopBreakpoint ? 4 : 3
      if ( newPartners.length > 0 && newPartners.length < num ) num = newPartners.length
      setSlidesToShow(num)
    }

    if (activeIndex > -1) filterPartnersHook(cats[activeIndex].id)
  }, [width, activeIndex, cats, partners])

  useEffect(() => {
    const sxLogoDefault = {
      maxWidth: '100%',
      maxHeight: '60px',
      cursor: 'pointer',
      transition: 'all 0.3s',
    }
  
    const sxLogoHover = {
      maxWidth: 'calc(100% + 40px)',
      maxHeight: '80px',
      cursor: 'pointer',
      transition: 'all 0.3s',
    }

    let t = []
    filteredPartners.forEach((item, index) => {
      const sxLogo = index === logoHoverIndex ? sxLogoHover : sxLogoDefault
      t.push(<SoftwarePartnersItem
        key={index}
        index={index}
        {...item}
        sxLogo={sxLogo}
        handleLogoMouseEnter={handleLogoMouseEnter}
        handleLogoMouseLeave={handleLogoMouseLeave}
      />)
    })
    setSlides(t)
  }, [filteredPartners, logoHoverIndex, lang])

  return (
    <>
      <Grid container columnSpacing={{ md: 1, lg: 4 }} rowSpacing={0} id='categories'>
        {
          cats.map((item, index) => {
            const background = index === activeIndex ? '#2E9CD6' : 'rgba(6, 14, 54, 0.9)'

            return (
              <Grid md key={index}>
                <SoftwareCategoryTitleBox sx={{ background }} onClick={() => handleCategoryClick(index)}>
                  <SoftwareCategoryTitleBodyBox>
                    <SoftwareCategoryTitleTextBox>{item.name[lang]}</SoftwareCategoryTitleTextBox>
                  </SoftwareCategoryTitleBodyBox>
                  <SoftwareCategoryTitleBodyBox>
                    { item.icon_url && <img src={ settings.s3URL + settings.s3Bucket + item.icon_url } alt='' style={{ maxWidth: '80%' }} /> }
                  </SoftwareCategoryTitleBodyBox>
                </SoftwareCategoryTitleBox>
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container columnSpacing={4} rowSpacing={0}>
        {
          cats.map((item, index) => (
              <Grid md key={index}>{ index === activeIndex && <SoftwareCategoryLineBox sx={{ opacity: containerOpacity }}></SoftwareCategoryLineBox> }</Grid>
            )
          )
        }
      </Grid>
      {
        activeIndex > -1 && (
          <SoftwarePartnersBox sx={{ opacity: containerOpacity }}>
            <Box sx={{ opacity: contentOpacity, transition: 'opacity 0.2s' }}>
              <SoftwareDescriptionBox>{ cats.length > 0 && cats[activeIndex].description[lang] }</SoftwareDescriptionBox>
              <SlickSlider
                slides={slides}
                sxBody={sxBody}
                arrows={sliderArrows}
                pagination={{}}
                autoplay={false}
                draggable={false}
                speed={sliderSpeed}
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                centerMode={false}
              />
            </Box>
          </SoftwarePartnersBox>
        )
      }
    </>
  )
}

export default SoftwarePartners