export const constants = {
  services: { ka: 'სერვისები', en: 'Services' },
  projects: { ka: 'პროექტები', en: 'Projects' },
  ongoingProjects: { ka: 'მიმდინარე პროექტები', en: 'Ongoing Projects' },
  news: { ka: 'ახალი ამბები', en: 'News' },
  staff: { ka: 'ჩვენი გუნდი', en: 'Our Team' },
  founders: { ka: 'დამფუძნებლები', en: 'Founders' },
  education: { ka: 'სწავლება', en: 'Education' },
  internet: { ka: 'ინტერნეტი', en: 'Internet' },
  geant: { ka: 'GÉANT', en: 'GÉANT' },
  eduroam: { ka: 'eduroam', en: 'eduroam' },
  edugain: { ka: 'eduGAIN', en: 'eduGAIN' },
  software: { ka: 'ლიცენზირებული პროგრამები', en: 'Licensed Software' },
  about: { ka: 'ჩვენ შესახებ', en: 'About Us' },
  aup: { ka: 'Rules (AUP)', en: 'Rules (AUP)' },
  genderEqualityPlan: { ka: 'Gender Equality Plan', en: 'Gender Equality Plan' },
  vps: { ka: 'ვირტუალური სერვერები', en: 'Virtual Servers' },
  hosting: { ka: 'Web / Mail ჰოსტინგი', en: 'Web / Mail Hosting' },
  domains: { ka: 'დომენების რეგისტრაცია', en: 'Domain Registration' },
  registrationOpen: { ka: 'მიმდინარეობს რეგისტრაცია ###NUM### კურსზე', en: 'Registration for ###NUM### courses is open' },
  certification: { ka: 'სერტიფიცირება', en: 'Certification' },
  learningEnvironment: { ka: 'სასწავლო გარემო', en: 'Learning Environment' },
  ourInstructors: { ka: 'ჩვენი ლექტორები', en: 'Our Instructors' },
  courses: { ka: 'სასწავლო კურსები', en: 'Courses' },
  activeCourses: { ka: 'აქტიური კურსები', en: 'Active Courses' },
  duration: { ka: 'ხანგრძლივობა', en: 'Duration', },
  lectures: { ka: 'ლექციები', en: 'Lectures', },
  language: { ka: 'ენა', en: 'Language', },
  learningMaterial: { ka: 'სასწავლო მასალა', en: 'Learning material', },
  price: { ka: 'ღირებულება', en: 'Price', },
  register: { ka: 'რეგისტრაცია', en: 'Register', },
  process: { ka: 'სწავლის პროცესი და ხანგრძლივობა', en: 'Learning process and duration', },
  courseDuration: { ka: 'კურსის ხანგრძლივობა', en: 'Course duration', },
  lectureDuration: { ka: 'ლექციის ხანგრძლივობა', en: 'Lecture duration', },
  lectureStart: { ka: 'ლექციის დაწყების დრო', en: 'Lecture start time', },
  priceTerms: { ka: 'ღირებულება და გადახდის პირობები', en: 'Price and payment terms', },
  coursePrice: { ka: 'კურსის ღირებულება', en: 'Course price', },
  learningIssues: { ka: 'სასწავლო მასალა და საკითხები', en: 'Learning material and issues', },
  download: { ka: 'გადმოწერა', en: 'Download', },
  syllabusInfo: { ka: 'სილაბუსი და კურსის შესახებ ინფორმაცია', en: 'Syllabus and course information', },
  enrollmentRules: { ka: 'კურსზე ჩარიცხვის წესები', en: 'Course enrollment rules', },
  registrationIsOpen: { ka: 'კურსზე მიმდინარეობს რეგისტრაცია', en: 'Registration is open', },
  frequency: { ka: 'კვირაში ###NUM###-ჯერ', en: '###NUM### times a week', },
  hours: { ka: 'სთ.', en: 'hours', },
  chairman: { ka: 'თავმჯდომარე', en: 'Chairman' },
  ourServices: { ka: 'ჩვენი სერვისები', en: 'Our Services' },
  certContactUs: { ka: 'მოგვწერეთ ინციდენტის შესახებ', en: 'Contact us about the incident', },
  profile: { ka: 'პროფილი', en: 'profile', },
  seeFull: { ka: 'სრულად ნახვა', en: 'Learn more', },
  seeAll: { ka: 'ყველას ნახვა', en: 'See all', },
  learnMore: { ka: 'გაიგე მეტი', en: 'Learn more', },
  ourPartners: { ka: 'ჩვენი პარტნიორები', en: 'Our Partners', },
  available: { ka: 'ჩართულია', en: 'Available', },
  planned: { ka: 'დაგეგმილია', en: 'Planned', },
  instructions: { ka: 'ინსტრუქციები', en: 'Instructions', },
  documents: { ka: 'დოკუმენტები', en: 'Documents', },
  metadata: { ka: 'Metadata', en: 'Metadata', },
  faq: { ka: 'ხდკ', en: 'FAQ', },
  faqFull: { ka: 'ხშირად დასმული კითხვები', en: 'FAQ', },
  organization: { ka: 'ორგანიზაცია', en: 'Organization', },
  location: { ka: 'მდებარეობა', en: 'Location', },
  status: { ka: 'სტატუსი', en: 'Status', },
  membersInGeorgia: { ka: 'წევრები საქართველოში', en: 'Members in Georgia', },
  projectTitle: { ka: 'პროექტის დასახელება', en: 'Proposal Title', },
  contractNumber: { ka: 'საგრანტო ხელშეკრულების ნომერი', en: 'Grant Agreement Number', },
  enterDomain: { ka: 'შეიყვანეთ დომენის სახელი', en: 'Enter domain name', },
  check: { ka: 'შემოწმება', en: 'Check', },
  gel: { ka: 'ლარი', en: 'GEL', },
  generalDomains: { ka: 'ზოგადი დომენები', en: 'General Domains', },
  rules: { ka: 'წესები', en: 'Rules', },
  free: { ka: 'თავისუფალია', en: 'Available', },
  unavailable: { ka: 'დაკავებულია', en: 'Unavailable', },
  invalidDomain: { ka: 'არასწორი დომენის სახელი', en: 'Invalid domain name', },
  renew: { ka: 'ვადის განახლება', en: 'Renew', },
  transfer: { ka: 'ტრანსფერი', en: 'Transfer', },
  registrator: { ka: 'რეგისტრატორი', en: 'Registrator', },
  creationDate: { ka: 'რეგისტრაციის თარიღი', en: 'Creation Date', },
  expiryDate: { ka: 'გათიშვის თარიღი', en: 'Expiry Date', },
  owner: { ka: 'მფლობელი', en: 'Owner', },
  registrant: { ka: 'რეგისტრანტი', en: 'Registrant', },
  registrantEmail: { ka: 'რეგისტრანტის ელ. ფოსტა', en: 'Registrant email', },
  administrator: { ka: 'ადმინისტრატორი', en: 'Administrator', },
  administratorEmail: { ka: 'ადმინისტრატორის ელ. ფოსტა', en: 'Administrator email', },
  techPerson: { ka: 'ტექნიკური პირი', en: 'Technical person', },
  techPersonEmail: { ka: 'ტექნიკური პირის ელ. ფოსტა', en: 'Technical person email', },
  nameServers: { ka: 'NS მისამართები', en: 'Name Servers', },
  month: { ka: 'თვე', en: 'Month', },
  traffic: { ka: 'ტრაფიკი', en: 'Traffic', },
  speed: { ka: 'სიჩქარე', en: 'Speed', },
  backup: { ka: 'სარეზერვო ასლი', en: 'Backup', },
  cpu: { ka: 'CPU CORES', en: 'CPU CORES', },
  ram: { ka: 'GB RAM', en: 'GB RAM', },
  ssd: { ka: 'GB SSD', en: 'GB SSD', },
  corpEmails: { ka: 'კორპორატიული ელ.ფოსტა', en: 'Corporate emails', },
  hostingDomains: { ka: 'დომენები', en: 'Domains', },
  websites: { ka: 'ვებ გვერდები', en: 'Websites', },
  small: { ka: 'Small', en: 'Small', },
  medium: { ka: 'Medium', en: 'Medium', },
  order: { ka: 'შეუკვეთე', en: 'Order', },
  whyService: { ka: 'რატომ უნდა აირჩიოთ ჩვენი სერვისი?', en: 'Why should you choose our service?', },
  registrationOkTitle: { ka: 'დიდი მადლობა!', en: 'Thank you!', },
  registrationOkText: { ka: 'თქვენ წარმატებით გაიარეთ რეგისტრაცია მოცემულ კურსზე. ჩვენ დაგიკავშირდებით, როგორც კი დასრულდება კურსზე რეგისტრაცია.', en: 'You have successfully registered to this course. We will contact you as soon as registration is closed.', },
  registrationFailedTitle: { ka: 'დაფიქსირდა შეცდომა!', en: 'Error Occurred!', },
  registrationFailedText: { ka: 'დაფიქსირდა ტექნიკური ხარვეზი. გთხოვთ, თავიდან სცადოთ რეგისტრაციის გავლა.', en: 'Something went wrong. Please, try again to submit your registration.', },
  subscribeOkTitle: { ka: 'დიდი მადლობა!', en: 'Thank you!', },
  subscribeOkText: { ka: 'თქვენი შეტყობინება წარმატებით გაიგზავნა. ჩვენ მალე დაგიკავშირდებით.', en: 'Your request was successfully submitted. We\'ll contact you soon.', },
  subscribeFailedTitle: { ka: 'დაფიქსირდა შეცდომა!', en: 'Error Occurred!', },
  subscribeFailedText: { ka: 'დაფიქსირდა ტექნიკური ხარვეზი. გთხოვთ, თავიდან სცადოთ შეტყობინების გაგზავნა.', en: 'Something went wrong. Please, try again to submit your request.', },
  priceCustom: { ka: 'შეთანხმებით', en: 'Custom', },
  individualApproach: { ka: 'ყველა პროექტისადმი ინდივიდუალური მიდგომა', en: 'Individual approach to every project', },
  whatService: { ka: 'რას მოიცავს ჩვენი სერვისი', en: 'What does our service include?', },
  send: { ka: 'გაგზავნა', en: 'Send', },
  messageOK: { ka: 'თქვენი შეტყობინება წარმატებით გაიგზავნა.<br />ჩვენი კონსულტანტი მალე დაგიკავშირდებათ.', en: 'Your request was successfully submitted.<br />Our consultant will contact you soon.', },
  subscribe: { ka: 'როდისაა რეგისტრაცია?', en: 'Subscribe', },
  subscribeDescription: { ka: 'მიუთითეთ თქვენი ელ. ფოსტის მისამართი და კურსზე რეგისტრაციის დაწყებისას მიიღებთ შეტყობინებას.', en: 'If you\'d like to get notification about course registration opening, please provide your e-mail address, and we\'ll send you automatic notification, as soon as it is opened.', },
  emailLabel: { ka: 'ელ. ფოსტა', en: 'E-mail', },
  emailPlaceholder: { ka: 'შეიყვანეთ თქვენი ელ. ფოსტა', en: 'Enter your e-mail', },
  softwareProcessStep1: { ka: 'უფასო კონსულტაცია', en: 'Free consultation', },
  softwareProcessStep2: { ka: 'სწორი პროდუქტის შერჩევა', en: 'Select right product', },
  softwareProcessStep3: { ka: 'სწრაფი მიწოდება', en: 'Fast delivery', },
  softwareButtonText: { ka: 'თუ დაინტერესდებით, მოგვწერეთ', en: 'If you are interested, contact us', },
  reCaptchaError: { ka: 'reCAPTCHA-ს შემოწმება ვერ მოხერხდა', en: 'reCAPTCHA check failed', },
  domainLogInToProfile: { ka: 'პროფილში შესვლა', en: 'Login to profile', },
  domain: { ka: 'დომენი', en: 'domain', },
  allDomains: { ka: 'ყველა დომენი', en: 'all domains', },
  tariffs: { ka: 'ტარიფები', en: 'Tariffs', },
  year: { ka: 'წელი', en: 'year', },
  years: { ka: 'წელი', en: 'years', },
  eppCode: { ka: 'სატრანსფერო კოდი', en: 'EPP Code', },
  priceFree: { ka: 'უფასო', en: 'Free', },
  email: { ka: 'ელ. ფოსტა', en: 'E-mail', },
  password: { ka: 'პაროლი', en: 'Password', },
  login: { ka: 'შესვლა', en: 'Login', },
  loginError: { ka: 'არასწორი ელ. ფოსტა ან პაროლი', en: 'Incorrect e-mail or password', },
  forgotPassword: { ka: 'დაგავიწყდათ პაროლი?', en: 'Forogt password?', },
  restore: { ka: 'აღდგენა', en: 'Restore', },
  notRegistered: { ka: 'არ ხართ რეგისტრირებული?', en: 'Not registered?', },
  registerNewAccount: { ka: 'ახალი ანგარიშის რეგისტრაცია', en: 'Register new account', },
  confirmPassword: { ka: 'გაიმეორეთ პაროლი', en: 'Confirm password', },
  accountType: { ka: 'ანგარიშის ტიპი', en: 'Account type', },
  accountTypePerson: { ka: 'ფიზიკური პირი', en: 'Individual', },
  accountTypeCompany: { ka: 'იურიდიული პირი', en: 'Organization', },
  name: { ka: 'სახელი', en: 'Name', },
  lastname: { ka: 'გვარი', en: 'Lastname', },
  companyName: { ka: 'ორგანიზაციის დასახელება', en: 'Organization name', },
  personalId: { ka: 'პირადი ნომერი', en: 'Personal number', },
  companyId: { ka: 'საიდენტიფიკაციო კოდი', en: 'Organization code', },
  alreadyHaveAnAccount: { ka: 'უკვე მაქვს ანგარიში', en: 'I already have an account', },
  passwordInfoText: {
    ka: 'პაროლი უნდა შედგებოდეს მინინუმ 8 სიმბოლოსგან და შეიცავდეს დიდ ასოებს, პატარა ასოებს, ციფრებს და სპეციალურ სიმბოლოებს',
    en: 'Password must be at least 8 characters long and contain uppercase letters, lowercase letters, numbers and special symbols',
  },
  pleaseFillOut: { ka: 'გთხოვთ შეავსოთ', en: 'Please fill out', },
  registerErrorEmail: { ka: 'არასწორი ელ. ფოსტის ფორმატი', en: 'Incorrect e-mail format', },
  registerErrorConfirmPassword: { ka: 'პაროლი არ ემთხვევა', en: 'Password does not match', },
  registerErrorPersonalId: { ka: 'პირადი ნომერი უნდა შეიცავდეს 11 ციფრს', en: 'Personal number must contain 11 symbols', },
  registerErrorCompanyId: { ka: 'საიდენტიფიკაციო კოდი უნდა შეიცავდეს 9 ციფრს', en: 'Organization code must contain 9 symbols', },
  registerOk1: { ka: 'თქვენ წარმატებით დარეგისტრირდით.', en: 'You have successfully registered.', },
  registerOk2: { ka: 'დადასტურების ბმული გაგზავნილია თქვენი ელ. ფოსტის მისამართზე.', en: 'Confirmation link has been sent to your e-mail.', },
  passwordRecovery: { ka: 'პაროლის აღდგენა', en: 'Password Recovery', },
  passwordRecoveryOk: { ka: 'დადასტურების ბმული გაგზავნილია თქვენი ელ. ფოსტის მისამართზე', en: 'Confirmation link has been sent to your e-mail', },
  incorrectEmail: { ka: 'არასწორი ელ. ფოსტის მისამართი', en: 'Incorrect e-mail', },
  enterNewPassword: { ka: 'შეიყვანეთ ახალი პაროლი', en: 'Enter New Password', },
  passwordHasChanged: { ka: 'პაროლი შეცვლილია', en: 'Password has changed', },
  save: { ka: 'შენახვა', en: 'Save', },
  emailIsConfirmed: { ka: 'თქვენი ელ. ფოსტა დადასტურებულია', en: 'Your e-mail has been confirmed', },
  error: { ka: 'შეცდომა', en: 'Error', },
  haveNotReceivedEmailConfirmationLink: { ka: 'არ მიგიღიათ ელ. ფოსტის დადასტურების ბმული?', en: 'Have not received e-mail confirmation link?', },
  resend: { ka: 'ხელახლა გაგზავნა', en: 'Resend', },
  confirmEmail: { ka: 'ელ. ფოსტის დადასტურება', en: 'Confirm E-mail', },
  enterEmail: { ka: 'შეიყვანეთ ელ. ფოსტა', en: 'Enter e-mail', },
  userAlreadyExists: { ka: 'მომხმარებელი ###EMAIL### ელ. ფოსტით უკვე არსებობს', en: 'User with ###EMAIL### e-mail already exists', },
  userNotExists: { ka: 'მომხმარებელი არ არსებობს ან დაბლოკილია', en: 'User does not exist or is blocked', },
  userAlreadyActive: { ka: 'მომხმარებელი უკვე აქტიურია', en: 'User is already active', },
}