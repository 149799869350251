import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SlickSlider = ({ slides, sxBody, arrows, pagination, autoplay, draggable, speed, slidesToShow, slidesToScroll, centerMode, handleAfterChange }) => {
  const slider = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  const sliderSettings = {
    dots: false,
    autoplay,
    arrows: false,
    draggable,
    infinite: true,
    speed,
    slidesToShow,
    slidesToScroll,
    centerMode,
  }

  const handlePrev = () => {
    slider.current.slickPrev()
    if (arrows.leftFunction) arrows.leftFunction()
  }

  const handleNext = () => {
    slider.current.slickNext()
    if (arrows.rightFunction) arrows.rightFunction()
  }

  const handleGoTo = (index) => {
    slider.current.slickGoTo(index)
  }

  useEffect(() => {
    if (handleAfterChange) handleAfterChange(activeSlide)
  }, [activeSlide, handleAfterChange])
  
  return (
    <Box sx={sxBody}>
      {
        (arrows && slides.length > slidesToShow && centerMode === false) && (
          <>
            <Box sx={arrows.leftStyle} onClick={handlePrev}>{arrows.leftIcon}</Box>
            <Box sx={arrows.rightStyle} onClick={handleNext}>{arrows.rightIcon}</Box>
          </>
        )
      }

      {
        (pagination && slides.length > slidesToShow) && (
          <Box sx={pagination.style}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                slides.map((item, index) => (
                  <Box key={index} onClick={() => handleGoTo(index)}>
                    { index === activeSlide ? pagination.iconActive : pagination.icon }
                  </Box>
                ))
              }
            </Box>
          </Box>
        )
      }

      <Slider ref={slider} {...sliderSettings} afterChange={(e) => setActiveSlide(e)}>
        {
          slides.map(item => item)
        }
      </Slider>
    </Box>
  )
}

export default SlickSlider