import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import domainsBackground from './images/domains_background.png'
import domainsPopupVector from './images/domains_popup_vector.png'
import domainsPopupVectorMobile from './images/domains_popup_vector_mobile.png'
import closeIcon from './images/close_icon.png'
import okIcon from './images/ok_icon.png'

export const DomainsSearchContainerBox = styled(Box)({
  height: '515px',
  background: `url(${domainsBackground}) no-repeat center / cover`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media(max-width: 899px)': {
    height: 'auto',
    padding: '50px 0px',
  }
})

export const DomainsPriceContainerBox = styled(Box)({
  position: 'relative',
  marginTop: '-15px',
})

export const DomainsPriceItemBox = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  color: theme.palette.darkblue.main,
  border: '1px solid rgba(6, 14, 54, 0.2)',
  borderRadius: '8px',
  padding: '30px',
  margin: '0px 20px 0px 20px',
  textAlign: 'center',

  '@media(max-width: 1199px)': {
    margin: '0px 10px 0px 10px',
    padding: '20px',
  },

  '@media(max-width: 899px)': {
    margin: '0px 10px 0px 10px ',
    minHeight: '320px',
  },

  '@media(max-width: 357px)': {
    padding: '20px',
    minHeight: '400px',
  }
}))

export const DomainsPriceTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.secondary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  borderBottom: '1px solid rgba(6, 14, 54, 0.2)',
  marginBottom: '20px',
  paddingBottom: '10px',

  '@media(max-width: 940px)': {
    minHeight: '72px',
  },

  '@media(max-width: 357px)': {
    fontSize: '18px',
  }
}))

export const DomainsPriceDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '16px',
  marginBottom: '25px',
  minHeight: '50px',
}))

export const DomainsPriceValueBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.secondary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  border: '1px solid rgba(6, 14, 54, 0.2)',
  borderRadius: '8px',
  padding: '20px 15%',
}))

export const DomainsRulesLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: theme.typography.regular.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const DomainSearchResultContainerBox = styled(Box)(({ theme }) => ({
  border: `${theme.palette.primary.main} 2px solid`,
  borderRadius: '8px',
  padding: '30px 50px',
  margin: '0px 0px 50px 0px',

  '@media(max-width: 1024px)': {
    padding: '20px 20px',
  }
}))

export const DomainSearchResultGreenIconBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '20px',
  lineHeight: '26px',
  width: '30px',
  height: '30px',
  borderRadius: '15px',
  color: '#04AB3D',
  border: '#04AB3D 2px solid',
  textAlign: 'center',
  marginRight: '20px',
  background: `url(${okIcon}) no-repeat center`,
}))

export const DomainSearchResultGreenStatusBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '16px',
  color: '#04AB3D',
}))

export const DomainSearchResultRedIconBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '20px',
  lineHeight: '30px',
  width: '30px',
  height: '30px',
  borderRadius: '15px',
  color: theme.palette.offwhite.main,
  textAlign: 'center',
  marginRight: '20px',
  background: `rgba(245, 52, 52, 0.7) url(${closeIcon}) no-repeat center / 16px 16px`,
}))

export const DomainSearchResultRedStatusBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '16px',
  color: '#FF0000',
}))

export const DomainSearchResultNameBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.secondary.main,
  fontSize: '24px',
  margin: '20px 0px 50px 50px',

  '@media(max-width: 899px)': {
    margin: '20px 0px 0px 50px',
  }
}))

export const DomainSearchResultPriceBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '24px',
  margin: '0px 0px 20px 0px',
  textAlign: 'center',
}))

export const DomainSearchResultInfoLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const DomainSearchResultInfoTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '14px',
  lineHeight: '22px',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const DomainSearchResultInfoValueBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
}))

export const DomainSearchResultCheckLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  lineHeight: '32px',
  marginRight: '30px',
}))

export const DomainSearchResultCheckTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '16px',
  lineHeight: '32px',
  margin: '0px 30px 0px 10px',
}))

export const DomainSearchResultSimilarContainerBox = styled(Box)(({ theme }) => ({
  border: `${theme.palette.primary.main} 2px solid`,
  borderRadius: '8px',
  padding: '15px 25px',
}))

export const DomainSearchResultSimilarIconBox = styled(Box)(({ theme }) => ({
  width: '22px',
  height: '22px',
  borderRadius: '11px',
  border: `${theme.palette.secondary.main} 2px solid`,
  color: theme.palette.secondary.main,
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '15px',
  cursor: 'pointer',
  position: 'relative',
  zIndex: '5',
}))

export const DomainSearchResultSimilarGreenStatusBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '14px',
  color: '#04AB3D',
  marginBottom: '20px',
  textAlign: 'right',

  '@media(max-width: 899px)': {
    textAlign: 'left',
  }
}))

export const DomainSearchResultSimilarRedStatusBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '14px',
  color: '#FF0000',
  marginBottom: '20px',
  textAlign: 'right',

  '@media(max-width: 899px)': {
    textAlign: 'left',
  }
}))

export const DomainSearchResultSimilarPriceBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  marginLeft: '20px',
}))

export const DomainSearchResultSimilarNameBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.secondary.main,
  fontSize: '16px',
  textAlign: 'right',

  '@media(max-width: 899px)': {
    textAlign: 'left',
    marginBottom: '20px',
  }
}))

export const DomainSearchResultPopupBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: '14px',
  padding: '10px',
  background: '#476E84',
  borderRadius: '8px',
  fontWeight: 'normal',
}))

export const DomainSearchResultPopupVectorBox = styled(Box)({
  width: '42px',
  height: '28px',
  background: `url(${domainsPopupVector}) no-repeat`,
  marginLeft: '30px',

  '@media(max-width: 1199px)': {
    background: `url(${domainsPopupVectorMobile}) no-repeat`,
    marginLeft: '55px',
  }
})

export const DomainsBottomBox = styled(Box)({
  padding: '50px 0px 0px 0px',

  '@media (max-width: 1800px)': {
    padding: '50px 60px 0px 60px',
  },

  '@media (max-width: 1199px)': {
    padding: '50px 0px 0px 0px',
  }
})