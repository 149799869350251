import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox } from '../../themes/boxLayout'
import { academyItems, cerCentersItems, classroomPhotosItems, courseRegistrationsItems } from '../../data/education'
import { staffItems } from '../../data/staff'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEducation, apiStaff } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import EducationAcademies from './EducationAcademies'
import EducationCertification from './EducationCertification'
import EducationClassroom from './EducationClassroom'
import EducationInstructors from './EducationInstructors'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const RegularPage = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [academies, setAcademies] = useState([])
  const [certCenters, setCertCenters] = useState([])
  const [classroomPhotos, setClassroomPhotos] = useState([])
  const [courseRegistrations, setCourseRegistrations] = useState([])
  const [instructors, setInstructors] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchAcademies = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getAcademies, 'GET')

      if (response && response.length > 0) {
        setAcademies(response)
      } else {
        setAcademies([])
      }
    }

    const fetchCourseRegistrations = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseRegistrations, 'GET')

      if (response && response.length > 0) {
        setCourseRegistrations(response)
      } else {
        setCourseRegistrations([])
      }
    }

    const fetchCertCenters = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCertCenters, 'GET')

      if (response && response.length > 0) {
        setCertCenters(response)
      } else {
        setCertCenters([])
      }
    }

    const fetchClassroomPhotos = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getClassroomPhotos, 'GET')

      if (response && response.length > 0) {
        setClassroomPhotos(response)
      } else {
        setClassroomPhotos([])
      }
    }

    const fetchInstructors = async () => {
      const response = await request(settings.domain + settings.api + apiStaff.getItems, 'GET')

      if (response && response.length > 0) {
        setInstructors(response.filter(item => item.is_instructor))
      } else {
        setInstructors([])
      }
    }

    if (settings.mode === 'live') {
      fetchAcademies()
      fetchCourseRegistrations()
      fetchCertCenters()
      fetchClassroomPhotos()
      fetchInstructors()
    } else {
      setAcademies(academyItems)
      setCourseRegistrations(courseRegistrationsItems)
      setCertCenters(cerCentersItems)
      setClassroomPhotos(classroomPhotosItems)
      setInstructors(staffItems.filter(item => item.is_instructor))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (academies.length > 0 && certCenters.length > 0 && classroomPhotos.length > 0 && instructors.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [academies, certCenters, classroomPhotos, instructors])
  
  return (
    <>
      <HelmetElement
        metaDescription='Education, Cisco Networking Academy, LPI Academy, MS SQL Databases, Oracle Databases, Pearson VUE Testing Center'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}>
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.education[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
        </Container>
        <EducationAcademies academies={academies} courseRegistrations={courseRegistrations} />
        <EducationCertification certCenters={certCenters} />
        <EducationClassroom classroomPhotos={classroomPhotos} />
        <EducationInstructors instructors={instructors} />
      </RegularPageFluidBox>
    </>
  )
}

export default RegularPage