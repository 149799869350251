import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { EduroamMembersHeadTableCell } from '../../themes/table'
import { constants } from '../../data/constants'
import EduroamMembersItem from './EduroamMembersItem'
import EduroamLocationMap from './EduroamLocationMap'
import ModalWindow from '../layout/ModalWindow'

const EduroamMembers = ({ members }) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const sxHeadLeft = {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  }

  const sxHeadRight = {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  }

  const sxHeadCenter = {
    borderLeft: `${theme.palette.offwhite.main} 1px solid`,
    borderRight: `${theme.palette.offwhite.main} 1px solid`,
  }

  const hanldeOpenModal = ({ title, content }) => {
    setModalTitle(title)
    setModalContent(<EduroamLocationMap content={content} />)
    setModalOpen(true)
  }

  return (
    <>
      <TableContainer sx={{ marginBottom: '50px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <EduroamMembersHeadTableCell sx={sxHeadLeft} colSpan={2}>{constants.organization[lang]}</EduroamMembersHeadTableCell>
              <EduroamMembersHeadTableCell sx={sxHeadCenter}>{constants.location[lang]}</EduroamMembersHeadTableCell>
              <EduroamMembersHeadTableCell sx={sxHeadRight}>{constants.status[lang]}</EduroamMembersHeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              members.map((item, index) => (
                <EduroamMembersItem key={index} handleOpenModal={hanldeOpenModal} {...item} />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButtons=''
        modalWidth='md'
      />
    </>
  )
}

export default EduroamMembers