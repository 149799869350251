import { createTheme } from '@mui/material'
import darkScrollbar from '@mui/material/darkScrollbar'
import { grey } from '@mui/material/colors'

export const themeGeo = createTheme({
  palette: {
    primary: {
      main: '#074D78'
    },
    secondary: {
      main: '#2E9CD6'
    },
    offwhite: {
      main: '#F4F4F4'
    },
    darkblue: {
      main: '#060E36'
    },
  },
  typography: {
    regular: {
      fontFamily: 'ingiri',
      fontSize: 14
    },
    roboto: {
      fontFamily: 'roboto',
      fontSize: 14
    },
    mainmenu: {
      fontFamily: 'ninom',
      fontSize: 16,
      textTransform: 'none'
    },
    labels: {
      fontFamily: 'ninomb',
      fontSize: 24,
      textTransform: 'none'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            {
              track: grey[200],
              thumb: grey[400],
              active: grey[400]
            }
          ),
          scrollbarWidth: 'thin'
        },
        div: {
          ...darkScrollbar(
            {
              track: grey[200],
              thumb: grey[400],
              active: grey[400]
            }
          ),
          scrollbarWidth: 'thin'
        },
        body: {
          background: '#F4F4F4',
        },
      }
    }
  }
})

export const themeEng = createTheme({
  palette: {
    primary: {
      main: '#074D78'
    },
    secondary: {
      main: '#2E9CD6'
    },
    offwhite: {
      main: '#F4F4F4'
    },
    darkblue: {
      main: '#060E36'
    },
  },
  typography: {
    regular: {
      fontFamily: 'roboto',
      fontSize: 14
    },
    roboto: {
      fontFamily: 'roboto',
      fontSize: 14
    },
    mainmenu: {
      fontFamily: 'roboto',
      fontSize: 16,
      textTransform: 'uppercase'
    },
    labels: {
      fontFamily: 'roboto',
      fontSize: 24,
      textTransform: 'uppercase'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            {
              track: grey[200],
              thumb: grey[400],
              active: grey[400]
            }
          ),
          scrollbarWidth: 'thin'
        },
        div: {
          ...darkScrollbar(
            {
              track: grey[200],
              thumb: grey[400],
              active: grey[400]
            }
          ),
          scrollbarWidth: 'thin'
        },
        body: {
          background: '#F4F4F4',
        },
      }
    }
  }
})