import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { getFormattedDate } from '../../helpers/dates'
import { isValidEmail, isValidPhone } from '../../helpers/string'
import FormText from '../form/FormText'
import FormDatePicker from '../form/FormDatePicker'
import FormSelect from '../form/FormSelect'
import FormMultipleSelect from '../form/FormMultipleSelect'
import FormInteger from '../form/FormInteger'
import FormFloat from '../form/FormFloat'
import FormCheckbox from '../form/FormCheckbox'

const ContentFormItem = ({
  field_id,
  priority,
  type,
  required,
  has_subfield,
  subfields,
  subfields_mapping,
  options,
  errors,
  updateFilled,
  modifySubfields,
  updateContentTypes,
  formWidth
}) => {
  const lang = useSelector(state => state.lang)

  const [label, setLabel] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [value, setValue] = useState('')
  const [values, setValues] = useState([])
  const [selectItems, setSelectItems] = useState([])
  const [choiceType, setChoiceType] = useState('s')
  const [lineNumber, setLineNumber] = useState(1)
  const [contentType, setContentType] = useState('')
  const [filteredSubfields, setFilteredSubfields] = useState([])
  const [integerMin, setIntegerMin] = useState(0)
  const [integerMax, setIntegerMax] = useState(0)
  const [error, setError] = useState(errors.includes(field_id))

  const toggleSubfields = (value) => {
    const subfieldsCond = subfields_mapping.filter(item => {
      if (item.condition === 'equal') return item.value === value
      else if (item.condition === 'not_equal') return item.value !== value
      else if (item.condition === 'contain') return value.includes(item.value)
      else return !value.includes(item.value)
    })

    let temp = []

    subfieldsCond.forEach(item => {
      temp.push(subfields.find(item2 => item2.subfield_id === item.subfield_id))
    })

    setFilteredSubfields(temp)

    let ids = []
    temp.forEach(item => ids.push(item.subfield_id))
    modifySubfields(field_id, ids)
  }

  const handleTextChange = (v) => {
    if (type === 'integer') {
      if (v >= integerMin && v <= integerMax) {
        setValue(v)
        updateFilled({ field_id, priority, type, required, value: v })
      }
    } else {
      setValue(v)
      updateFilled({ field_id, priority, type, required, value: v })
    }
    
    if (contentType === 'email') {
      if (isValidEmail(v)) setError(false)
      else setError(true)
    } else if (contentType === 'phone') {
      if (isValidPhone(v)) setError(false)
      else setError(true)
    } else if (contentType === 'id_number') {
      if (v.length === 11) setError(false)
      else setError(true)
    } else {
      if (v) setError(false)
      else if (required) setError(true)
    }

    if (has_subfield) toggleSubfields(v)
  }

  const handleDateChange = (e) => {
    const v = getFormattedDate(e.$d)
    setValue(v)
    updateFilled({ field_id, priority, type, required, value: v })
    if (v) setError(false)
    else if (required) setError(true)
  }

  const handleFloatChange = (v) => {
    if (v.match(/^[0-9]*\.?[0-9]*$/)) {
      setValue(v)
      updateFilled({ field_id, priority, type, required, value: parseFloat(v) })
      if (v) setError(false)
      else if (required) setError(true)
    }
  }

  const handleSelectChange = (v) => {
    setValue(v)
    updateFilled({ field_id, priority, type, required, value: v })
    if (has_subfield) toggleSubfields(v)
    if (v !== null) setError(false)
    else if (required) setError(true)
  }

  const handleMultiSelectChange = (v) => {
    setValues(v)
    updateFilled({ field_id, priority, type, required, value: v })
    if (v) setError(false)
    else if (required) setError(true)
  }
  
  useEffect(() => {
    if (options) {
      if (options.label) setLabel(options.label[lang])
      if (options.placeholder) setPlaceholder(options.placeholder[lang])
      if (options.choice_type) setChoiceType(options.choice_type)
      if (options.line_number) setLineNumber(options.line_number)
      if (options.range) {
        setIntegerMin(options.range.min)
        setIntegerMax(options.range.max)
      }

      if (options.content_type) {
        setContentType(options.content_type)
        updateContentTypes({ field_id, contentType: options.content_type })
      }

      if (options.choices) {
        let temp = []
        options.choices.forEach(item => temp.push({ value: item.value, title: item.title[lang] }))
        const tempObject = options.choices.find(item => item.is_selected)
        if (tempObject) setValue(tempObject.value)
        setSelectItems(temp)
      }
    }
  }, [field_id, lang, options, updateContentTypes])

  useEffect(() => {
    setError(errors.includes(field_id))
  }, [errors, field_id])
  
  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        {
          (type === 'text' && contentType !== 'hidden') && (
            <FormText
              label={label}
              placeholder={placeholder}
              value={value}
              required={required}
              error={error}
              lineNumber={lineNumber}
              handleChange={handleTextChange}
              fixedWidth={formWidth}
            />
          )
        }

        {
          type === 'date' && (       
            <FormDatePicker
              label={label}
              value={value}
              required={required}
              error={error}
              handleChange={handleDateChange}
              fixedWidth={formWidth}
            />         
          )
        }

        {
          (type === 'select' && choiceType === 's') && ( 
            <FormSelect
              id={field_id}
              label={label}
              value={value}
              required={required}
              error={error}
              selectItems={selectItems}
              handleChange={handleSelectChange}
              fixedWidth={formWidth}
            />
          )
        }

        {
          (type === 'select' && choiceType === 'm') && ( 
            <FormMultipleSelect
              id={field_id}
              label={label}
              values={values}
              required={required}
              error={error}
              selectItems={selectItems}
              handleChange={handleMultiSelectChange}
              fixedWidth={formWidth}
            />
          )
        }

        {
          type === 'integer' && (
            <FormInteger
              label={label}
              placeholder={placeholder}
              value={value ? value : integerMin}
              required={required}
              error={error}
              handleChange={handleTextChange}
              min={integerMin}
              max={integerMax}
              fixedWidth={formWidth}
            />
          )
        }

        {
          type === 'float' && (
            <FormFloat
              label={label}
              placeholder={placeholder}
              value={value}
              required={required}
              error={error}
              handleChange={handleFloatChange}
              fixedWidth={formWidth}
            />
          )
        }

        {
          type === 'boolean' && (
            <FormCheckbox
              label={label}
              checked={ value ? true : false}
              required={required}
              error={error}
              handleChange={handleTextChange}
            />
          )
        }

      </Box>
      {
        filteredSubfields.map((item, index) => (
          <ContentFormItem
            key={index}
            field_id={item.subfield_id}
            priority={priority + 1}
            type={item.type}
            required={required}
            has_subfield={false}
            subfields={[]}
            subfields_mapping={[]}
            options={item.options}
            errors={errors}
            updateFilled={updateFilled}
            modifySubfields={modifySubfields}
            formWidth={formWidth}
          />
        ))
      }
    </>
  )
}

export default ContentFormItem