import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import {
  HostingPackageItemBox,
  HostingPackageItemTitleBox,
  HostingPackageItemPriceBox,
  HostingPackageItemPriceValueBox,
  HostingPackageItemOptionBox,
  HostingPackageItemIconBox,
  HostingPackageItemServicesBox
} from '../../themes/boxHosting'
import WifiIcon from '@mui/icons-material/Wifi'
import SpeedIcon from '@mui/icons-material/Speed'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PublicIcon from '@mui/icons-material/Public'
import { DatabaseBackup } from 'lucide-react'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import ContentButton from '../form/ContentButton'

const HostingPackagesItem = ({
  name,
  price,
  backup,
  traffic,
  bandwidth,
  corp_emails,
  domains,
  services,
  handleOpenServicesModal,
  setModalOpen,
  setModalTitle,
  setModalContent
}) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const priceValue = price ? (
    <>
      <HostingPackageItemPriceValueBox component='span'>{price} &#8382; / </HostingPackageItemPriceValueBox>
      <Box component='span'>{constants.month[lang]}</Box>
    </>  
  ) : constants.priceCustom[lang]

  const sxButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.offwhite.main,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '100%',
    height: '56px',
    padding: '0px',
    boxShadow: 'none',
    textTransform: theme.typography.mainmenu.textTransform,
    position: 'relative',
    zIndex: '4',

    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
  }

  const handleClickServices = () => {
    handleOpenServicesModal({ title: name[lang], services })
  }

  return (
    <HostingPackageItemBox>

      <HostingPackageItemTitleBox>{name[lang]}</HostingPackageItemTitleBox>
      <HostingPackageItemPriceBox>{priceValue}</HostingPackageItemPriceBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <WifiIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.traffic[lang]} {traffic[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <SpeedIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.speed[lang]} {bandwidth[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <DatabaseBackup size={32} />
        </HostingPackageItemIconBox>
        <Box>{backup[lang]} {constants.backup[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <AlternateEmailIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.corpEmails[lang]} {corp_emails}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox sx={{ borderBottom: 'none' }}>
        <HostingPackageItemIconBox>
          <PublicIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.hostingDomains[lang]} {domains}</Box>
      </HostingPackageItemOptionBox>

      <Box sx={{ margin: '30px 15%' }}>
        <ContentButton
          formTitle={name[lang]}
          formType='content'
          formName={settings.formHosting}
          formID=''
          openButton={constants.order[lang]}
          openButtonStyle={sxButton}
          submitButton={constants.send[lang]}
          submitButtonStyle=''
          setModalOpen={setModalOpen}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', }}>
        <HostingPackageItemServicesBox onClick={handleClickServices}>{constants.services[lang]}</HostingPackageItemServicesBox>
      </Box>

    </HostingPackageItemBox>
  )
}

export default HostingPackagesItem