import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { EdugainMembersItemBox } from '../../themes/boxEdugain'

const EdugainMembersItem = ({ name, website }) => {
  const lang = useSelector(state => state.lang)

  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(website, '_blank')
    } else if (e.type === 'click') {
      window.open(website, '_blank')
    } 
  }

  return (
    <>
      <EdugainMembersItemBox onClick={handleClick} onMouseDown={handleClick}>{name[lang]}</EdugainMembersItemBox>
      <Box sx={{ clear: 'both' }}></Box>
    </>
  )
}

export default EdugainMembersItem