import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import useWindowDimensions from '../../helpers/dimensions'
import { MainMenuDropdownItemButton, MainMenuDropdownMobileItemButton } from '../../themes/button'
import { MainMenuDropdownLineBox } from '../../themes/boxLayout'
import settings from '../../helpers/settings'
import { setBurgerRight } from '../../reducers/burgerRight'

const MainMenuDropdownItem = ({ title, go_to_url, handleClose }) => {
  const theme = useTheme()
  const elRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [lineWidth, setLineWidth] = useState('37px')
  const [lineBg, setLineBg] = useState(theme.palette.offwhite.main)

  const handleMouseEnter = () => {
    setLineWidth(`${elRef.current.clientWidth}px`)
    setLineBg(theme.palette.secondary.main)
  }

  const handleMouseLeave = () => {
    if (width > settings.desktopBreakpoint) {
      setLineWidth('37px')
      setLineBg(theme.palette.offwhite.main)
    } 
  }

  const handleClick = (e) => {
    if (go_to_url.includes('/files')) {
      if (e.ctrlKey || e.button === 1 || e.type === 'click') {
        window.open(settings.s3URL + settings.s3Bucket + go_to_url, '_blank')
      } 
    } else {
      if (e.ctrlKey || e.button === 1) {
        window.open(go_to_url, '_blank')
      } else if (e.type === 'click') {
        navigate(go_to_url)
        handleClose()
        dispatch(setBurgerRight('-100%'))
      }
    }
  }

  useEffect(() => {
    if (elRef.current && width <= settings.desktopBreakpoint) {
      setLineWidth(`${elRef.current.clientWidth}px`)
    }
  }, [width, elRef, lang])
  
  return (
    <>
      {
        width > settings.desktopBreakpoint ? <MainMenuDropdownItemButton onClick={handleClick} onMouseDown={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={elRef} disableRipple>{title[lang]}</MainMenuDropdownItemButton> : <MainMenuDropdownMobileItemButton ref={elRef} onClick={handleClick} onMouseDown={handleClick} disableRipple>{title[lang]}</MainMenuDropdownMobileItemButton>
      }
      <MainMenuDropdownLineBox sx={{ width: lineWidth, backgroundColor: lineBg }}></MainMenuDropdownLineBox>
    </>
  )
}

export default MainMenuDropdownItem