import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import edugainDescriptionBorderLeft from './images/edugain_description_border_left.png'
import edugainDescriptionBorderRight from './images/edugain_description_border_right.png'
import edugainMembersFigureLeft from './images/edugain_members_figure_left.png'
import edugainMembersFigureRight from './images/edugain_members_figure_right.png'

export const EdugainPageHeadBox = styled(Box)({
  height: '60px',
  border: '11px solid rgba(7, 77, 120, 0.2)',
  borderBottom: 'none',
  borderRadius: '8px 8px 0px 0px',
  margin: '0px 17px',

  '@media(max-width: 1024px)': {
    display: 'none',
  }
})

export const EdugainPageBodyBox = styled(Box)({
  background: `url(${edugainDescriptionBorderLeft}) no-repeat left center, url(${edugainDescriptionBorderRight}) no-repeat right center`,
  padding: '0px 105px',

  '@media(max-width: 1024px)': {
    background: 'none',
    padding: '0px',
    marginBottom: '50px',
  }
})

export const EdugainPageFootBox = styled(Box)({
  height: '60px',
  border: '11px solid rgba(7, 77, 120, 0.2)',
  borderTop: 'none',
  borderRadius: '0px 0px 8px 8px',
  margin: '0px 17px 50px 17px',

  '@media(max-width: 1024px)': {
    display: 'none',
  }
})

export const EdugainPageTitleBox = styled(Box)({
  height: '70px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})

export const EdugainPageTitleTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  padding: '10px 30px 0px 0px',
}))

export const EdugainPageDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
}))

export const EdugainMembersLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  borderBottom: '2px solid rgba(6, 14, 54, 0.5)',
  marginBottom: '70px',
  padding: '0px 10px 4px 10px',

  '@media(max-width: 1199px)': {
    fontSize: '20px',
    marginBottom: '30px',
  }
}))

export const EdugainMembersContainerBox = styled(Box)({
  padding: '0px 5%',

  '@media(max-width: 1199px)': {
    padding: '0px',
  }
})

export const EdugainMembersCategoryTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  background: 'rgba(7, 77, 120, 0.2)',
  borderRadius: '8px',
  marginBottom: '30px',
  padding: '5px 30px',
}))

export const EdugainMembersCategoryDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  marginBottom: '40px',

  '@media(max-width: 1199px)': {
    marginBottom: '20px',
  }
}))

export const EdugainMembersLeftFigureBox = styled(Box)({
  height: '264px',
  background: `url(${edugainMembersFigureLeft}) no-repeat right top`,
  marginBottom: '15px',
})

export const EdugainMembersRightFigureBox = styled(Box)({
  height: '264px',
  background: `url(${edugainMembersFigureRight}) no-repeat left top`,
  marginBottom: '15px',
})

export const EdugainMembersCategoryBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  lineHeight: '24px',
  padding: '30px 30px 10px 30px',
  background: '#476E84',
  borderRadius: '8px',
  minHeight: '200px',
  margin: '0px 10% 100px 10%',

  '@media(max-width: 1199px)': {
    margin: '0px 0px 50px 0px',
    minHeight: '10px',
    padding: '20px',
  }
}))

export const EdugainMembersItemBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,
  lineHeight: '24px',
  marginBottom: '17px',
  paddingBottom: '3px',
  display: 'inline-block',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:hover': {
    color: theme.palette.secondary.main,
    borderBottom: `${theme.palette.secondary.main} 1px solid`,
  }
}))

export const EdugainDocsBox = styled(Box)(({ theme }) => ({
  padding: '50px 50px 30px 50px',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '8px',
  marginBottom: '100px',

  '@media(max-width: 1199px)': {
    padding: '30px 30px 15px 30px',
    marginBottom: '30px',
  }
}))

export const EdugainDocsCategoryBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  marginBottom: '40px',

  '@media(max-width: 1199px)': {
    marginBottom: '20px',
  }
}))

export const EdugainDocsItemBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.secondary.main,
  lineHeight: '24px',
  marginBottom: '20px',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  }
}))