export const internetServiceCategory = [
  {
    id: '1',
    title: { ka: 'ქსელური მომსახურება', en: 'Network Service', },
    priority: '1',
  },
  {
    id: '2',
    title: { ka: 'ქსელის უსაფრთხოება', en: 'Network Security', },
    priority: '2',
  },
  {
    id: '3',
    title: { ka: 'ქსელის მონიტორინგი', en: 'Network Monitoring', },
    priority: '3',
  },
]

export const internetService = [
  {
    id: '1',
    category: '1',
    title: { ka: 'ინტერნეტ მომსახურება', en: 'Internet Service', },
    priority: '1',
  },
  {
    id: '2',
    category: '1',
    title: { ka: 'ევროპის სამეცნიერო ქსელ GEANT-ში ჩართვა', en: '', },
    priority: '2',
  },
  {
    id: '3',
    category: '1',
    title: { ka: 'eduroam საგანმანათლებლო როუმინგი', en: '', },
    priority: '3',
  },
  {
    id: '4',
    category: '1',
    title: { ka: 'ქსელური ინფრასტრუქტურის დაგეგმვა და ინსტალაცია', en: '', },
    priority: '4',
  },
  {
    id: '5',
    category: '2',
    title: { ka: 'Scrubing center', en: '', },
    priority: '1',
  },
  {
    id: '6',
    category: '2',
    title: { ka: 'Blackhole service', en: '', },
    priority: '2',
  },
  {
    id: '7',
    category: '2',
    title: { ka: 'Application layer DDoS protection system', en: '', },
    priority: '3',
  },
  {
    id: '8',
    category: '2',
    title: { ka: 'eduGAIN აუტენტიფიკაცია და ავტორიზაცია', en: '', },
    priority: '4',
  },
  {
    id: '9',
    category: '2',
    title: { ka: 'IDS/IPS სისტემების საწყისი კონფიგურაცია და/ან სრული ოპერირება', en: '', },
    priority: '5',
  },
  {
    id: '10',
    category: '3',
    title: { ka: 'Scrutinizer', en: '', },
    priority: '1',
  },
  {
    id: '11',
    category: '3',
    title: { ka: 'Cacti', en: '', },
    priority: '2',
  },
  {
    id: '12',
    category: '3',
    title: { ka: 'perfSONAR', en: '', },
    priority: '3',
  },
  {
    id: '13',
    category: '3',
    title: { ka: 'Check_MK', en: '', },
    priority: '4',
  },
]

export const internetPage = [
  {
    id: '',
    var_name: '',
    data: [
      {
        ka: 'ასოციაცია “გრენა” მომხმარებელს სთავაზობს გადაწყვეტილებების სრულ სპექტრს: კონსულტაცია, პროექტის დაგეგმვა, ანალიზი, ინსტალაცია, ქსელური ინფრასტრუქტურის ოპერირება და ინტერნეტის მიწოდება. მომხმარებლების მხარდაჭერა ხორციელდება მაღალი კვალიფიკაციის მქონე გუნდის მიერ.',
        en: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      },
      {
        ka: 'ასოციაცია ”გრენა” ინტერნეტ მომსახურებისათვის იყენებს ოპტიკურ-ბოჭკოვან არხებს.<br /><br />”გრენა”-ს გააჩნია ინტერნეტ კავშირის სამი საერთაშორისო არხი, რაც უზრუნველყოფს მომხმარებლებისთვის მაღალი ხარისხის სერვისის მიწოდებას.',
        en: 'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
      },
    ],
  }
]