import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { VpsProcessNumberBox, VpsProcessOuterBox, VpsProcessInnerBox, VpsProcessTitleBox } from '../../themes/boxHosting'
import { hostingArrow1Image, hostingArrow2Image, hostingArrow3Image, hostingArrow4Image, hostingArrow5Image } from '../../themes/image'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const VpsProcessItem = ({ step_name, icon_url, index }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const colors = ['#868686', '#E5EB0B', '#B9D40B', '#70AF1A', '#399D39', '#048D04']
  const arrows = [hostingArrow1Image, hostingArrow2Image, hostingArrow3Image, hostingArrow4Image, hostingArrow5Image]

  const itemWidth = width > 1300 ? 'calc((100%/17)*2)' : '100%'

  return (
    <>
      <Box sx={{ width: itemWidth }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <VpsProcessNumberBox>{index + 1}</VpsProcessNumberBox>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <VpsProcessOuterBox sx={{ borderColor: colors[index] }}>
            <VpsProcessInnerBox sx={{ borderColor: colors[index] }}>
              { icon_url && <img src={ settings.s3URL + settings.s3Bucket + icon_url } alt='' style={{ maxWidth: '90%' }} /> }
            </VpsProcessInnerBox>
          </VpsProcessOuterBox>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <VpsProcessTitleBox>{step_name[lang]}</VpsProcessTitleBox>
        </Box>
      </Box>
      {
        (width > 1300 && index !== 5) && (
          <Box sx={{ width: 'calc(100%/17)', marginTop: '122px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <img src={arrows[index]} alt='' />
            </Box>
          </Box>
        )
      }
    </>
  )
}

export default VpsProcessItem