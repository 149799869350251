import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const ProjectsTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  paddingBottom: '40px',
}))

export const ProjectsOngoingTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  background: 'rgba(46, 156, 214, 0.2)',
  borderLeft: '#2E9CD6 8px solid',
  borderRadius: '0px 4px 4px 0px',
  marginBottom: '32px',
  padding: '20px 24px 13px 24px',
}))

export const ProjectsOngoingTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  paddingBottom: '60px'
}))