import React from 'react'
import { useSelector } from 'react-redux'
import { EdugainDocsItemBox } from '../../themes/boxEdugain'
import settings from '../../helpers/settings'

const EdugainDocsItem = ({ name, file_url }) => {
  const lang = useSelector(state => state.lang)

  const handleClick = (e) => {
    const url = file_url.includes('http') ? file_url : settings.s3URL + settings.s3Bucket + file_url

    if (e.ctrlKey || e.button === 1) {
      window.open(url, '_blank')
    } else if (e.type === 'click') {
      window.open(url, '_blank')
    }
  }

  return (
    <EdugainDocsItemBox onClick={handleClick} onMouseDown={handleClick}>{name[lang]}</EdugainDocsItemBox>
  )
}

export default EdugainDocsItem