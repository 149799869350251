import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { closeIconImage } from '../../themes/image'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

const ModalImage = ({ modalOpen, modalContent }) => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [content, setContent] = useState('')
  
  const sxModalBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 1024px)': {
      width: '90%',
    }
  }

  const sxCloseBox = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: '32px',
    lineHeight: '32px',
    width: '32px',
    height: '32px',
    color: '#FFFFFF',
    position: 'fixed',
    top: '20px',
    right: '20px',
    cursor: 'pointer',
    background: `url(${closeIconImage}) no-repeat center`,

    '&:hover': {
      opacity: '0.8'
    }
  }
  
  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      setContent('')
    }, 500)
  }

  useEffect(() => {
    setOpen(modalOpen)
    setContent(modalContent)
  }, [modalOpen, modalContent])
  
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock> 
      <Fade in={open} timeout={500}>
        <Box sx={sxModalBox} maxWidth='lg'>
          <Box sx={sxCloseBox} onClick={handleClose}></Box>
          <Box>{content}</Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalImage