import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ConstructionIcon from '@mui/icons-material/Construction'
import {
  ToolbarBox,
  ToolbarLabelBox,
  ToolbarLabelIconBox,
  ToolbarLabelTextBox,
  ToolbarItemBox,
  ToolbarItemTextBox,
  ToolbarArrowBox
} from '../../themes/boxLayout'
import { toolbarArrowLeftImage, toolbarArrowRightImage } from '../../themes/image'
import { toolbarItems, toolbarOptions } from '../../data/toolbar'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiToolbar } from '../../helpers/urls'
import ToolbarMirrors from './ToolbarMirrors'

const Toolbar = () => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [toolbar, setToolbar] = useState([])
  const [mirrorsCount, setMirrorsCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [heights, setHeights] = useState([])
  const [widths, setWidths] = useState([])
  const [hoverIndex, setHoverIndex] = useState(-1)

  const sxClose = {
    right: '-144px',
    border: '#074D78 1px solid',
    borderRight: 'none',
  }

  const sxOpen = {
    right: '0px',
    border: '#074D78 1px solid',
    borderRight: 'none',
  }

  const sxLabelClose = {
    width: '32px',
    marginLeft: '-4px'
  }

  const sxLabelOpen = {
    with: 'auto'
  }

  const sxItem = {
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'all 0.1s'
  }

  const sxItemHover = {
    cursor: 'pointer',
    fontSize: '20px',
    background: 'rgba(51, 64, 131, 0.5)',
    marginRight: '-16px',
    marginLeft: '-26px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    padding: '0px 16px 0px 16px',
    transition: 'all 0.1s'
  }

  const sxIcon = { width: '31px', color: theme.palette.offwhite.main }
  const sxIconHover = { width: '40px', color: theme.palette.offwhite.main }

  const handleClick = (index, url) => {
    if (url) window.open(url, '_blank')
    else if (open) {
      let newHeights = heights
      const newHeight = mirrorsCount * 24
      if (newHeights[index] === '0px') newHeights[index] = `${newHeight}px`
      else newHeights[index] = '0px'
      setHeights([...newHeights])
    } else {
      let newWidths = widths
      if (newWidths[index] === '0px') newWidths[index] = '200px'
      else newWidths[index] = '0px'
      setWidths([...newWidths])
    }
  }

  const handleMouseEnter = (index) => {
    if (open) setHoverIndex(index)
  }

  const handleMouseLeave = () => {
    setHoverIndex(-1)
  }

  const toggleToolbar = () => {
    if (open) setOpen(false)
    else setOpen(true)
  }

  const handleSetMirrorsCount = (num) => {
    setMirrorsCount(num)
  }

  useEffect(() => {
    const fetchToolbar = async () => {
      const response = await request(settings.domain + settings.api + apiToolbar.getItems, 'GET')

      if (response && response.length > 0) {
        setToolbar(response)
      } else {
        setToolbar([])
      }
    }

    if (settings.mode === 'live') {
      fetchToolbar()
    } else {
      setToolbar(toolbarItems)
    }
  }, [])

  useEffect(() => {
    const setDimensionsToDefault = () => {
      let heightsArray = []
      let widthsArray = []
  
      toolbar.forEach((item, index) => {
        heightsArray[index] = '0px'
        widthsArray[index] = '0px'
      })
  
      setWidths(widthsArray)
      setHeights(heightsArray)
    }

    setDimensionsToDefault()
  }, [toolbar, open])
  
  return (
    <ToolbarBox sx={ open ? sxOpen : sxClose }>
      <ToolbarLabelBox sx={ open ? sxLabelOpen : sxLabelClose }>
        <ToolbarLabelIconBox onClick={toggleToolbar}>
          <ConstructionIcon />
        </ToolbarLabelIconBox>
        { open && <ToolbarLabelTextBox>Toolbar</ToolbarLabelTextBox> }
      </ToolbarLabelBox>
      {
        toolbar.map((item, index) => {
          const sxSubmenuIfOpen = {
            overflow: 'hidden',
            transition: 'all 0.5s',
            height: heights[index]
          }

          const sxSubmenuIfClose = {
            position: 'absolute',
            right: '200px',
            overflow: 'hidden',
            transition: 'all 0.5s',
            width: widths[index],
            background: '#060E36',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            padding: '8px 0px'
          }

          const toolbarOptionsObject = toolbarOptions.find(item3 => item3.name === item.icon_url)
          const iconUrl = toolbarOptionsObject ? toolbarOptionsObject.icon_url : ''
          const iconUrlHover = toolbarOptionsObject ? toolbarOptionsObject.icon_url_hover : ''

          return (
            <Box key={index}>
              <ToolbarItemBox
                sx={ index === hoverIndex ? sxItemHover : sxItem }
                onClick={() => handleClick(index, item.tool_url)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <Box sx={ index === hoverIndex ? sxIconHover : sxIcon }>{ index === hoverIndex ? iconUrlHover : iconUrl }</Box>
                <ToolbarItemTextBox>{ open && item.title[lang] }</ToolbarItemTextBox>
              </ToolbarItemBox>
              {
                item.has_submenu && (
                  <ToolbarMirrors
                    sxSubmenuIfOpen={sxSubmenuIfOpen}
                    sxSubmenuIfClose={sxSubmenuIfClose}
                    submenu={item.submenu_id}
                    handleSetMirrorsCount={handleSetMirrorsCount}
                  />
                )
              }
            </Box>
          )
        })
      }
      <ToolbarArrowBox onClick={toggleToolbar}>{ open ? toolbarArrowRightImage : toolbarArrowLeftImage }</ToolbarArrowBox>
    </ToolbarBox>
  )
}

export default Toolbar