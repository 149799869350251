import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import langReducer from '../reducers/lang'
import burgerRightReducer from '../reducers/burgerRight'
import webpageDataReducer from '../reducers/webpageData'
import userDataReducer from '../reducers/userData'
import actionsReducer from '../reducers/actions'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  lang: langReducer,
  burgerRight: burgerRightReducer,
  webpageData: webpageDataReducer,
  userData: userDataReducer,
  actions: actionsReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)