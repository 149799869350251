import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { EduroamMenuSeparatorBox, EduroamDropdownBox } from '../../themes/boxEduroam'
import { EduroamRegistrationButton, EduroamMenuButton } from '../../themes/button'
import { constants } from '../../data/constants'
import EduroamFileItem from './EduroamFileItem'

const EduroamMenu = ({ docs, handleFAQClick }) => {
  const lang = useSelector(state => state.lang)
  
  const [anchorElInst, setAnchorElInst] = useState(null)
  const [anchorElDoc, setAnchorElDoc] = useState(null)

  const openInst = Boolean(anchorElInst)
  const openDoc = Boolean(anchorElDoc)

  const instructions = docs.filter(item => item.type === 'instruction')
  const doc = docs.filter(item => item.type === 'doc')

  const handleInstClick = (e) => {
    setAnchorElInst(e.currentTarget)
  }

  const handleInstClose = () => {
    setAnchorElInst(null)
  }

  const handleDocClick = (e) => {
    setAnchorElDoc(e.currentTarget)
  }

  const handleDocClose = () => {
    setAnchorElDoc(null)
  }

  const handleRegisterClick = () => {
    const langName = lang === 'ka' ? 'geo' : 'eng'
    window.open(`https://eduroam.ge/${langName}/register`, '_blank')
  }

  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent='space-between'
      alignItems='center'
      spacing={0}
      sx={{ marginBottom: '50px' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

        <ClickAwayListener onClickAway={handleInstClose}>
          <EduroamMenuButton variant='text' disableRipple onClick={handleInstClick}>{constants.instructions[lang]}</EduroamMenuButton>
        </ClickAwayListener>
        <Popper
          id={`inst`}
          anchorEl={anchorElInst}
          open={openInst}
          transition
          placement='bottom-start'
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={400}>
              <EduroamDropdownBox>
                {
                  instructions.map((item, index) => (
                    <EduroamFileItem key={index} isLast={ index === instructions.length - 1 } {...item} />
                  ))
                }
              </EduroamDropdownBox>
            </Fade>
          )}
        </Popper>
        

        <EduroamMenuSeparatorBox>|</EduroamMenuSeparatorBox>

        <ClickAwayListener onClickAway={handleDocClose}>
          <EduroamMenuButton variant='text' disableRipple onClick={handleDocClick}>{constants.documents[lang]}</EduroamMenuButton>
        </ClickAwayListener>
        <Popper
          id={`doc`}
          anchorEl={anchorElDoc}
          open={openDoc}
          transition
          placement='bottom-start'
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={400}>
              <EduroamDropdownBox>
                {
                  doc.map((item, index) => (
                    <EduroamFileItem key={index} isLast={ index === doc.length - 1 } {...item} />
                  ))
                }
              </EduroamDropdownBox>
            </Fade>
          )}
        </Popper>
        
        <EduroamMenuSeparatorBox>|</EduroamMenuSeparatorBox>

        <EduroamMenuButton variant='text' disableRipple onClick={handleFAQClick}>{constants.faq[lang]}</EduroamMenuButton>

      </Box>
      <EduroamRegistrationButton variant='contained' disableRipple onClick={handleRegisterClick}>{constants.register[lang]}</EduroamRegistrationButton>
    </Stack>
  )
}

export default EduroamMenu