import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const FormDatePicker = ({ label, value, required, error, handleChange }) => {
  const theme = useTheme()

  const labelStyles = {
    display: 'inline',
    fontFamily: theme.typography.regular.fontFamily,
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>  
      <DatePicker
        label={label && <Box sx={labelStyles}>{label}</Box>}
        value={dayjs(value)}
        onChange={e => handleChange(e)}
        format='DD/MM/YYYY'
        sx={{ width: '100%' }}
        slotProps={{
          textField: {
            required,
            error,
            InputProps: { sx: { borderRadius: '8px' }, }
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default FormDatePicker