import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'
// import headerBackgroundLeftHome from './images/header_background_left_home.png'
import headerBackgroundLeft from './images/header_background_left.png'
import headerBackgroundLeftCourse from './images/header_background_left_course.png'
import footerBackgroundRight from './images/footer_background_right.png'

export const HeaderBottomLeftHomeGrid = styled(Grid)({
  height: '104px',
  // background: 'linear-gradient(270deg, #DEDFE0 0.84%, #DBDDDF 15.37%, #DDDEDF 32.09%, #DFDFE0 52.17%, #DFDFDF 75.91%, #E0E0E0 98.62%)',
  background: '#F4F4F4',
})

export const HeaderBottomCenterHomeGrid = styled(Grid)({
  height: '104px',
  // background: `url(${headerBackgroundLeftHome}) no-repeat right top`,
  background: `url(${headerBackgroundLeft}) no-repeat right top`,
})

export const HeaderBottomLeftGrid = styled(Grid)({
  height: '104px',
  background: '#F4F4F4',
})

export const HeaderBottomCenterGrid = styled(Grid)({
  height: '104px',
  background: `url(${headerBackgroundLeft}) no-repeat right top`,
})

export const HeaderBottomLeftCourseGrid = styled(Grid)({
  height: '104px',
  background: '#C2CFD7',
  zIndex: '4',
})

export const HeaderBottomCenterCourseGrid = styled(Grid)({
  height: '104px',
  background: `url(${headerBackgroundLeftCourse}) no-repeat right top`,
})

export const FooterTopCenterGrid = styled(Grid)({
  height: '104px',
  background: `url(${footerBackgroundRight}) no-repeat left bottom`
})

export const FooterTopRightGrid = styled(Grid)({
  height: '104px',
  background: '#F4F4F4',
})

export const EducationAcademyItemLeftGrid = styled(Grid)({
  width: '495px',
  height: '148px',
  position: 'absolute',
  left: '1px',
  top: '2px',
  cursor: 'pointer',
})

export const EducationAcademyItemRightGrid = styled(Grid)({
  width: '495px',
  height: '148px',
  position: 'absolute',
  left: '81px',
  top: '2px',
  cursor: 'pointer',
})