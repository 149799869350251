import React from 'react'
import Box from '@mui/material/Box'
import { SoftwarePartnersImageBox } from '../../themes/boxSoftware'
import settings from '../../helpers/settings'

const SoftwarePartnersItem = ({ index, logo_url, website, sxLogo, handleLogoMouseEnter, handleLogoMouseLeave }) => {
  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(website, '_blank')
    } else if (e.type === 'click') {
      window.open(website, '_blank')
    }
  }

  return (
    <Box sx={{ height: '80px', padding: '0px 30px' }}>
      <SoftwarePartnersImageBox>
        <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={sxLogo} onClick={handleClick} onMouseDown={handleClick} onMouseEnter={() => handleLogoMouseEnter(index)} onMouseLeave={handleLogoMouseLeave} />
      </SoftwarePartnersImageBox>
    </Box>
  )
}

export default SoftwarePartnersItem