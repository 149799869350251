import React from 'react'
import { useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

const FormSelect = ({ id, label, value, required, error, helpertext, selectItems, handleChange, fixedWidth }) => {
  const theme = useTheme()

  const selectStyles = {
    fontFamily: theme.typography.regular.fontFamily,
    borderRadius: '8px',
  }

  const labelStyles = {
    fontFamily: theme.typography.regular.fontFamily,
  }
  
  return (
    <FormControl sx={{ width: fixedWidth ? fixedWidth : '100%' }}>
      <InputLabel id={ id ? id : '' } sx={labelStyles}>{ required ? `${label} *` : label}</InputLabel>
      <Select
        labelId={ id ? id : '' }
        label={ required ? `${label} *` : label}
        error={error}
        value={value}
        onChange={e => handleChange(e.target.value)}
        sx={selectStyles}
      >
        {
          selectItems.map((item, index) => (
            <MenuItem key={index} value={item.value} sx={labelStyles}>{item.title}</MenuItem>
          ))
        }
      </Select>
      { helpertext && <FormHelperText>{helpertext}</FormHelperText> }
    </FormControl>
  )
}

export default FormSelect