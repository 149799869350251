import React from 'react'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const HostingPackageServices = ({ services }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  return (
    <Box sx={ width > settings.desktopBreakpoint && { minWidth: '800px' } }>
      {
        services.map((item, index) => (
          <Box key={index} sx={{ marginBottom: index !== services.length -1 && '10px', borderBottom: `${theme.palette.secondary.main} 1px solid`, paddingBottom: '5px' }}>{item[lang]}</Box>
        ))
      }
    </Box>
  )
}

export default HostingPackageServices