import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { EducationInstructorBox, EducationInstructorNameBox, EducationInstructorBioBox, EducationInstructorAvatarBox } from '../../themes/boxEducation'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const EducationInstructorsItem = ({ name, bio, photo_url }) => {
  const { width } = useWindowDimensions()

  const sxAvatarInitial = {
    background: `url(${ settings.s3URL + settings.s3Bucket + photo_url }) no-repeat center / cover`,
    width: `240px`,
    height: `240px`,
    borderRadius: `120px`,
  }

  const [avatarStyle, setAvatarStyle] = useState(sxAvatarInitial)

  const sxItem = width > settings.desktopBreakpoint ? { padding: '0px 64px' } : { padding: '0px', margin: '0px 10px' }

  useEffect(() => {
    const sxAvatarInitialHook = {
      background: `url(${ settings.s3URL + settings.s3Bucket + photo_url }) no-repeat center / cover`,
      width: `240px`,
      height: `240px`,
      borderRadius: `120px`,
    }

    if (width < 420) {
      const newSize = width * 0.5
      setAvatarStyle({
        background: `url(${ settings.s3URL + settings.s3Bucket + photo_url }) no-repeat center / cover`,
        width: `${newSize}px`,
        height: `${newSize}px`,
        borderRadius: `${newSize/2}px`,
      })
    } else {
      setAvatarStyle(sxAvatarInitialHook)
    }
  }, [width, photo_url])
  
  return (
    <Box sx={sxItem}>
      <EducationInstructorBox>
        {
          width > settings.desktopBreakpoint ? (
            <Grid container>
              <Grid xs={8}>
                <Box sx={{ padding: '50px 0px 50px 56px' }}>
                  <EducationInstructorNameBox>{name}</EducationInstructorNameBox>
                  <EducationInstructorBioBox>{bio}</EducationInstructorBioBox>
                </Box>
              </Grid>
              <Grid xs={4}>
                <EducationInstructorAvatarBox>
                  { photo_url && <Box sx={sxAvatarInitial}></Box> }
                </EducationInstructorAvatarBox>
              </Grid>
            </Grid>
          ) : (
            <>
              <EducationInstructorAvatarBox>
                { photo_url && <Box sx={avatarStyle}></Box> }
              </EducationInstructorAvatarBox>
              <Box sx={{ height: '32px', background: '#2E9CD6' }}></Box>
              <Box sx={{ padding: '30px 20px' }}>
                <EducationInstructorNameBox>{name}</EducationInstructorNameBox>
                <EducationInstructorBioBox>{bio}</EducationInstructorBioBox>
              </Box>
            </>
          )
        }
      </EducationInstructorBox>
    </Box>
  )
}

export default EducationInstructorsItem