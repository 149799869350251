import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const FoundersItemBox = styled(Box)(({ theme }) => ({
  height: '270px',
  padding: '28px',
  background: '#ffffff',
  borderTop: `${theme.palette.primary.main} 4px solid`,
  borderRadius: '8px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
  textAlign: 'center',
  cursor: 'pointer',
}))

export const FoundersLogoBox = styled(Box)({
  height: '120px',
  marginBottom: '32px',
})

export const FoundersNameBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  paddingBottom: '30px',
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',
}))

export const FoundersDirectorBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.darkblue.main,
  paddingBottom: '8px',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const FoundersRepresentativeBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
}))