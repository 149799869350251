import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { IconContext } from 'react-icons'
import { RiFontSize } from 'react-icons/ri'
import { HiOutlineBookOpen } from 'react-icons/hi'
import { CourseSummaryBox, CourseInfoBox, CoursePriceLabelBox, CoursePriceValueBox } from '../../themes/boxEducation'
import { alpha } from '@mui/material'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import ContentButton from '../form/ContentButton'
import ModalWindow from '../layout/ModalWindow'

const CourseSummary = ({ id, name, duration, frequency, language, materialsLanguage, price, registrationOpen, registrationID, registrationFormID, setBackdropOpen }) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  
  const lang = useSelector(state => state.lang)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const paddingLeft = width > settings.padBreakpoint ? '0px' : '10px'

  const sxRegistrationButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.offwhite.main,
    textTransform: theme.typography.mainmenu.textTransform,
    background: '#2E9CD6',
    borderRadius: '8px',
    width: '80%',
    height: '72px',
    boxShadow: 'none',

    '&:hover': {
      background: alpha('#2E9CD6', 0.7),
      boxShadow: 'none',
    },
  }

  const sxSubscribtionButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.primary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    background: '#FF9A03',
    borderRadius: '8px',
    width: '80%',
    height: '72px',
    boxShadow: 'none',
    opacity: '1',
    cursor: 'pointer',

    '&:hover': {
      background: alpha('#FF9A03', 0.7),
      boxShadow: 'none',
    },
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }
  
  return (
    <CourseSummaryBox>
      <Container maxWidth='xl'>
        <Grid container>
          {
            width > settings.padBreakpoint && (
              <Grid xs={12} md={8} lg={6}>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent='space-around'
                  alignItems={{ xs: 'flex-start', md: 'flex-start' }}
                  spacing={0}
                >
                  {
                    duration && (
                      <CourseInfoBox>
                        <CalendarTodayIcon sx={{ fontSize: '48px', marginBottom: '14px' }} />
                        <Box sx={{ paddingBottom: '4px', paddingLeft }}>{constants.duration[lang]}:</Box>
                        <Box sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', paddingLeft }}>{duration}</Box>
                      </CourseInfoBox>
                    )
                  }

                  {
                    frequency && (
                      <CourseInfoBox>
                        <ScheduleIcon sx={{ fontSize: '48px', marginBottom: '14px', }} />
                        <Box sx={{ paddingBottom: '4px', paddingLeft }}>{constants.lectures[lang]}:</Box>
                        <Box sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', paddingLeft }}>{constants.frequency[lang].replace('###NUM###', frequency)}</Box>
                      </CourseInfoBox>
                    )
                  }
                  
                  {
                    language && (
                      <CourseInfoBox>
                        <IconContext.Provider value={{ color: '#060E36', size: '48px' }}>
                          <RiFontSize style={{ marginBottom: '14px' }} />
                        </IconContext.Provider>
                        <Box sx={{ paddingBottom: '4px', paddingLeft }}>{constants.language[lang]}:</Box>
                        <Box sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', paddingLeft }}>{language}</Box>
                      </CourseInfoBox>
                    )
                  }
                  
                  {
                    materialsLanguage && (
                      <CourseInfoBox>
                        <IconContext.Provider value={{ color: '#060E36', size: '48px' }}>
                          <HiOutlineBookOpen style={{ marginBottom: '14px' }} />
                        </IconContext.Provider>
                        <Box sx={{ paddingBottom: '4px', paddingLeft }}>{constants.learningMaterial[lang]}:</Box>
                        <Box sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', paddingLeft }}>{materialsLanguage}</Box>
                      </CourseInfoBox>
                    )
                  }
                  
                </Stack>
              </Grid>
            )
          } 
          <Grid xs={12} md={4} lg={4} xsOffset={0} mdOffset={0} lgOffset={2}>
            {
              price > 0 && (
                <>
                  <CoursePriceLabelBox>{constants.price[lang]}</CoursePriceLabelBox>
                  <CoursePriceValueBox>{price} &#8382;</CoursePriceValueBox>
                </> 
              )
            }

            {
              registrationOpen ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContentButton
                    formTitle=''
                    formType='academy_registration'
                    formName={registrationID}
                    formID={registrationFormID}
                    openButton={constants.register[lang]}
                    openButtonStyle={sxRegistrationButton}
                    submitButton={constants.register[lang]}
                    submitButtonStyle=''
                    setModalOpen={setModalOpen}
                    setModalTitle={setModalTitle}
                    setModalContent={setModalContent}
                    setBackdropOpen={setBackdropOpen}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContentButton
                    formTitle=''
                    formType='academy_subscription'
                    formName={id}
                    formID=''
                    openButton={constants.subscribe[lang]}
                    openButtonStyle={sxSubscribtionButton}
                    submitButton={constants.send[lang]}
                    submitButtonStyle=''
                    setModalOpen={setModalOpen}
                    setModalTitle={setModalTitle}
                    setModalContent={setModalContent}
                    setBackdropOpen={setBackdropOpen}
                  />
                </Box>
              )
            }

            <ModalWindow
              modalOpen={modalOpen}
              modalTitle={modalTitle}
              modalContent={modalContent}
              modalButtons={[]}
              modalWidth='lg'
              onClose={handleCloseModal}
            />
          </Grid>
        </Grid>
      </Container>
    </CourseSummaryBox>
  )
}

export default CourseSummary