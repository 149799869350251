import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { HostingAdvantagesItemBox } from '../../themes/boxHosting'
import settings from '../../helpers/settings'

const HostingAdvantagesItem = ({ description, icon_url }) => {
  const lang = useSelector(state => state.lang)
  
  return (
    <HostingAdvantagesItemBox>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', height: '90px', marginBottom: '20px' }}>
        { icon_url && <img src={ settings.s3URL + settings.s3Bucket + icon_url } alt='' style={{ maxWidth: '90px', maxHeight: '90px' }} /> }
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center' }}>
        {description[lang]}
      </Box>
    </HostingAdvantagesItemBox>
  )
}

export default HostingAdvantagesItem