import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { ProjectsOngoingTitleBox, ProjectsOngoingTextBox } from '../../themes/boxProjects'
import { formatProjectDate } from '../../helpers/dates'
import { constants } from '../../data/constants'
import parse from 'html-react-parser'

const ProjectsOngoingItem = ({ title, project_name, start_date, end_date, contract, description }) => {
  const lang = useSelector(state => state.lang)

  return (
    <>
      <ProjectsOngoingTitleBox>{title[lang]}</ProjectsOngoingTitleBox>
      <ProjectsOngoingTextBox>
        <Box sx={{ paddingBottom: '30px' }}><Box component='span' sx={{ fontWeight: 'bold' }}>{constants.projectTitle[lang]}:</Box> {project_name[lang]}</Box>
        { contract && <Box sx={{ paddingBottom: '30px' }}><Box component='span' sx={{ fontWeight: 'bold' }}>{constants.contractNumber[lang]}:</Box> {contract}</Box> }
        <Box sx={{ paddingBottom: '30px' }}><Box component='span' sx={{ fontWeight: 'bold' }}>{constants.duration[lang]}:</Box> {formatProjectDate(start_date, end_date, lang)}&nbsp;&nbsp;<ScheduleIcon fontSize='small' sx={{ marginBottom: '-5px' }} /></Box>
        <Box>{parse(description[lang])}</Box>
      </ProjectsOngoingTextBox>
    </>
  )
}

export default ProjectsOngoingItem