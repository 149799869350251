import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  HomePartnersBox,
  HomePartnersButtonBox,
  HomePartnersStaticBox,
  HomePartnersOuterBox,
  HomePartnersInnerBox,
  HomePartnersLogosBox,
  HomePartnersGraphicsBox,
  HomePartnersPaginationBox
} from '../../themes/boxHome'
import { RegularPageTitleBox, OnlyPaddingBox } from '../../themes/boxLayout'
import { partnersPaginationImage, partnersPaginationActiveImage } from '../../themes/image'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import parse from 'html-react-parser'
import useWindowDimensions from '../../helpers/dimensions'
import SlickSlider from '../layout/SlickSlider'
import HomePartnersSlideItem from './HomePartnersSlideItem'

const HomePartners = ({ partners }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let sliderPaddingValue = '0px 70px'

  if (width > 599) {
    sliderPaddingValue = '0px 70px'
  } else {
    sliderPaddingValue = '0px 30px'
  }

  const [slides, setSlides] = useState([])
  const [opacity, setOpacity] = useState( width > settings.desktopBreakpoint ? 0 : 1)
  const [graphicsDisplay, setGraphicsDisplay] = useState( width > settings.desktopBreakpoint ? 'block' : 'none' )
  const [sliderPadding, setSliderPadding] = useState(sliderPaddingValue)

  const slidesToShow = 1
  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxArrow = { cursor: 'pointer' }

  const sxPrev= {
    position: 'absolute',
    left: '15px',
    top: '45%',

    '@media (max-width: 599px)': {
      left: '-15px',
    }
  }

  const sxNext= {
    position: 'absolute',
    right: '15px',
    top: '45%',

    '@media (max-width: 599px)': {
      right: '-15px',
    }
  }

  const sxPagination = {
    position: 'absolute',
    bottom: '-70px',
    left: '50%',
    transform: 'translateX(-50%)',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon color='primary' fontSize='large' sx={sxArrow} />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon color='primary' fontSize='large' sx={sxArrow} />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  const sliderPagination = {
    icon: <HomePartnersPaginationBox sx={{ background: `url(${partnersPaginationImage}) no-repeat center` }} />,
    iconActive: <HomePartnersPaginationBox sx={{ background: `url(${partnersPaginationActiveImage}) no-repeat center` }} />,
    style: sxPagination,
  }

  const togglePartnersBlock = () => {
    if (opacity === 0) {
      setOpacity(1)
      setGraphicsDisplay('none')
    }
    else {
      setOpacity(0)
      setTimeout(() => {
        setGraphicsDisplay('block')
      }, 800)
    }
  }

  useEffect(() => {
    if (width <= settings.desktopBreakpoint && opacity === 0) {
      setOpacity(1)
      setGraphicsDisplay('none')
    }

    if (width > 599) {
      setSliderPadding('0px 70px')
    } else {
      setSliderPadding('0px 30px')
    }
  }, [width, opacity])

  useEffect(() => {
    let t = []
    partners.forEach((item, index) => {
      t.push(<HomePartnersSlideItem key={index} logos={item} opacity={opacity} />)
    })
    setSlides(t)
  }, [partners, opacity])

  return (
    <Container maxWidth='xl'>
      <OnlyPaddingBox>
        <Grid container>
          {
            width > settings.desktopBreakpoint && (
              <Grid lg={5}>
                <HomePartnersBox>
                  <HomePartnersButtonBox onClick={togglePartnersBlock}>
                    <HomePartnersOuterBox></HomePartnersOuterBox>
                    <HomePartnersStaticBox>
                      <Box>{parse(constants.ourPartners[lang].replace(' ', '<br />'))}</Box>
                    </HomePartnersStaticBox>
                    <HomePartnersInnerBox></HomePartnersInnerBox>
                  </HomePartnersButtonBox>
                </HomePartnersBox>
              </Grid>
            )
          }
          <Grid xs={12} lg={6} xsOffset={0} lgOffset={1}>
            { width <= settings.desktopBreakpoint && <RegularPageTitleBox>{constants.ourPartners[lang]}</RegularPageTitleBox> }
            <HomePartnersGraphicsBox sx={{ display: graphicsDisplay }} onClick={togglePartnersBlock}></HomePartnersGraphicsBox>
            <HomePartnersLogosBox sx={{ opacity }}>
              <SlickSlider
                slides={slides}
                sxBody={{ position: 'relative', padding: sliderPadding }}
                arrows={sliderArrows}
                pagination={sliderPagination}
                autoplay={false}
                draggable={false}
                speed={sliderSpeed}
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                centerMode={false}
                handleAfterChange=''
              />
            </HomePartnersLogosBox>
          </Grid>
        </Grid>
      </OnlyPaddingBox>
    </Container>
  )
}

export default HomePartners