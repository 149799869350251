import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { VpsServicesLabelBox } from '../../themes/boxHosting'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import VpsServicesItem from './VpsServicesItem'
import SlickSlider from '../layout/SlickSlider'

const VpsServices = ({ services }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let slidesToShowValue = 1
  let slidesToScrollValue = 1

  if (width > 899) {
    slidesToShowValue = 2
    slidesToScrollValue = 2
  } else {
    slidesToShowValue = 1
    slidesToScrollValue = 1
  }

  const [slides, setSlides] = useState([])
  const [slidesToShow , setSlidesToShow] = useState(slidesToShowValue)
  const [slidesToScroll , setSlidesToScroll] = useState(slidesToScrollValue)

  const sliderSpeed = 500

  const sxBody = {
    padding: '0px 30px',
    marginBottom: '50px',
    position: 'relative',
  }

  const sxPrev = {
    position: 'absolute',
    top: '45%',
    left: '0px',
    cursor: 'pointer',
  }

  const sxNext = {
    position: 'absolute',
    top: '45%',
    right: '0px',
    cursor: 'pointer',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon color='primary' sx={sxPrev} />,
    leftStyle: {},
    leftFunction: '',
    rightIcon: <ArrowForwardIcon color='primary' sx={sxNext} />,
    rightStyle: {},
    rightFunction: '',
  }

  useEffect(() => {
    if (width > 899) {
      setSlidesToShow(2)
      setSlidesToScroll(2)
    } else {
      setSlidesToShow(1)
      setSlidesToScroll(1)
    }
  }, [width])

  useEffect(() => {
    let t = []
    services.forEach((item, index) => {
      t.push(<VpsServicesItem key={index} {...item} />)
    })
    setSlides(t)
  }, [services])

  return (
    <>
      <VpsServicesLabelBox>{constants.whatService[lang]}</VpsServicesLabelBox>
      {
        width > settings.desktopBreakpoint ? (
          <Grid container spacing={6} sx={{ marginBottom: '50px' }}>
            {
              services.map((item, index) => (
                <Grid lg={4} key={index}>
                  <VpsServicesItem {...item} />
                </Grid>
              ))
            }
          </Grid>
        ) : (
          <SlickSlider
            slides={slides}
            sxBody={sxBody}
            arrows={sliderArrows}
            pagination={{}}
            autoplay={false}
            draggable={true}
            speed={sliderSpeed}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            centerMode={false}
            handleAfterChange=''
          />
        )
      }
    </>
  )
}

export default VpsServices