import {
  hostingAdvIcon1Image,
  hostingAdvIcon2Image,
  hostingAdvIcon3Image,
  hostingAdvIcon4Image,
  hostingAdvIcon5Image,
  hostingAdvIcon6Image,
  hostingProcessIcon1Image,
  hostingProcessIcon2Image,
  hostingProcessIcon3Image,
  hostingProcessIcon4Image,
  hostingProcessIcon5Image,
  hostingProcessIcon6Image,
  hostingServiceIcon1Image,
  hostingServiceIcon2Image,
  hostingServiceIcon3Image,
  hostingServiceIcon4Image,
  hostingServiceIcon5Image,
  hostingServiceIcon6Image,
  hostingServiceIcon7Image,
  hostingServiceIcon8Image,
  hostingServiceIcon9Image,
} from '../themes/image'

export const vpsPackageItems = [
  {
    id: '1',
    name: {
      ka: 'საწყისი',
      en: '',
    },
    price: '100',
    cpu: '2',
    ram: '2',
    ssd: '30',
    bandwidth: {
      ka: 'ულიმიტო ინტერნეტი',
      en: '',
    },
  },
  {
    id: '2',
    name: {
      ka: 'საშუალო',
      en: '',
    },
    price: '150',
    cpu: '2',
    ram: '4',
    ssd: '60',
    bandwidth: {
      ka: 'ულიმიტო ინტერნეტი',
      en: '',
    },
  },
  {
    id: '3',
    name: {
      ka: 'ბიზნესი',
      en: '',
    },
    price: '250',
    cpu: '4',
    ram: '6',
    ssd: '100',
    bandwidth: {
      ka: 'ულიმიტო ინტერნეტი',
      en: '',
    },
  },
  {
    id: '4',
    name: {
      ka: 'კონსტრუქტორი',
      en: '',
    },
    price: '',
    cpu: '',
    ram: '',
    ssd: '',
    bandwidth: {
      ka: 'ულიმიტო ინტერნეტი',
      en: '',
    },
  },
]

export const vpsServiceItems = [
  {
    id: '1',
    title: {
      ka: 'სერვერის ადმინისტრირება',
      en: '',
    },
    description: {
      ka: 'სერვერის გამართული მუშაობის და საჭირო განახლებების უზრუნველყოფა.',
      en: '',
    },
    icon_url: hostingServiceIcon1Image,
  },
  {
    id: '2',
    title: {
      ka: 'სერვერის კონფიგურირება',
      en: '',
    },
    description: {
      ka: 'ოპერაციული სისტემის და პროგრამული კომპონენტების გამართვა და ოპტიმიზირება.',
      en: '',
    },
    icon_url: hostingServiceIcon2Image,
  },
  {
    id: '3',
    title: {
      ka: 'სერვერების მონიტორინგი',
      en: '',
    },
    description: {
      ka: '24/7 სერვერების მონიტორინგი, ხელმისაწვდომობის და დატვირთვების შემოწმება.',
      en: '',
    },
    icon_url: hostingServiceIcon3Image,
  },
  {
    id: '4',
    title: {
      ka: 'უფასო სატესტო პერიოდი',
      en: '',
    },
    description: {
      ka: '15 დღიანი სატესტო პერიოდი.',
      en: '',
    },
    icon_url: hostingServiceIcon4Image,
  },
  {
    id: '5',
    title: {
      ka: 'მონაცემთა მიგრაცია',
      en: '',
    },
    description: {
      ka: 'ყველა მონაცემის გადმოტანა ახალ სერვერზე.',
      en: '',
    },
    icon_url: hostingServiceIcon5Image,
  },
  {
    id: '6',
    title: {
      ka: 'გაუმართაობის იდენტიფიცირება',
      en: '',
    },
    description: {
      ka: 'პროგრამული შეცდომების / ხარვეზების იდენტიფიცირება და დეველოპერების ჩართულობით მათი გამოსწორება.',
      en: '',
    },
    icon_url: hostingServiceIcon6Image,
  },
  {
    id: '7',
    title: {
      ka: 'სერვერის რესურსების მასშტაბირება',
      en: '',
    },
    description: {
      ka: 'დატვირთვების ან მომხმარებლების ზრდის შემთხვევაში სერვერის რესურსების გაზრდა.',
      en: '',
    },
    icon_url: hostingServiceIcon7Image,
  },
  {
    id: '8',
    title: {
      ka: 'სარეზერვო ასლები',
      en: '',
    },
    description: {
      ka: 'ვირტუალური სერვერის და მონაცემების ავტომატური ყოველდღიური ბეკაპირება და ნებისმიერ დროს აღდგენა.',
      en: '',
    },
    icon_url: hostingServiceIcon8Image,
  },
  {
    id: '9',
    title: {
      ka: 'ტექნიკური მხარდაჭერა',
      en: '',
    },
    description: {
      ka: 'სერტიფიცირებული სისტემური ინჟინრების ტექნიკური დახმარება ტელეფონით, ელ-ფოსტით და სხვადასხხვა ჩატით.',
      en: '',
    },
    icon_url: hostingServiceIcon9Image,
  },
]

export const vpsProcessItems = [
  {
    id: '1',
    step_name: {
      ka: 'უფასო კონსულტაცია',
      en: '',
    },
    icon_url: hostingProcessIcon1Image,
    priority: '1',
  },
  {
    id: '2',
    step_name: {
      ka: 'პაკეტის შერჩევა',
      en: '',
    },
    icon_url: hostingProcessIcon2Image,
    priority: '2',
  },
  {
    id: '3',
    step_name: {
      ka: 'სერვერის კონფიგურირება',
      en: '',
    },
    icon_url: hostingProcessIcon3Image,
    priority: '3',
  },
  {
    id: '4',
    step_name: {
      ka: 'მონაცემების განთავსება',
      en: '',
    },
    icon_url: hostingProcessIcon4Image,
    priority: '4',
  },
  {
    id: '5',
    step_name: {
      ka: 'ტესტირება და ხარვეზების გამოვლენა',
      en: '',
    },
    icon_url: hostingProcessIcon5Image,
    priority: '5',
  },
  {
    id: '6',
    step_name: {
      ka: 'გაშვება',
      en: '',
    },
    icon_url: hostingProcessIcon6Image,
    priority: '6',
  },
]

export const vpsAdvantagesItems = [
  {
    id: '1',
    description: {
      ka: 'საკუთარი დაცული დატაცენტრი თბილისში',
      en: '',
    },
    icon_url: hostingAdvIcon1Image,
  },
  {
    id: '2',
    description: {
      ka: 'თანამედროვე სერვერები, მაღალი IOPS - ის SSD დისკები, კლასტერული გარემო',
      en: '',
    },
    icon_url: hostingAdvIcon2Image,
  },
  {
    id: '3',
    description: {
      ka: 'მაღალსიჩქარიანი დუბლირებული ინტერნეტის არხები  და ურთიერთჩართვა ინტერნეტ ოპერატორებთან',
      en: '',
    },
    icon_url: hostingAdvIcon3Image,
  },
  {
    id: '4',
    description: {
      ka: 'კიბერ უსაფრთხოების თანამედროვე სისტემები',
      en: '',
    },
    icon_url: hostingAdvIcon4Image,
  },
  {
    id: '5',
    description: {
      ka: 'დუბლირებული უწყვეტი დენის წყაროები და გენერატორები',
      en: '',
    },
    icon_url: hostingAdvIcon5Image,
  },
  {
    id: '6',
    description: {
      ka: 'ინტერნეტ სერვისები და რეპუტაციის 20 წლიანი გამოცდილება',
      en: '',
    },
    icon_url: hostingAdvIcon6Image,
  },
]

export const hostingPackageItems = [
  {
    id: '1',
    name: {
      ka: 'Web ჰოსტინგი',
      en: 'Web Hosting',
    },
    price: {
      '5 GB': '29',
      '10 GB': '49',
    }, 
    backup: {
      ka: 'ყოველდღიური',
      en: 'Daily',
    }, 
    traffic: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    bandwidth: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    services: [
      {
        ka: 'SSL სერტიფიკატი',
        en: 'SSL Certificate',
      },
      {
        ka: 'FTP წვდომა',
        en: 'FTP Access',
      },
    ],
  },
  {
    id: '2',
    name: {
      ka: 'E-mail ჰოსტინგი',
      en: 'E-mail hosting',
    },
    price: {
      '5 GB': '29',
      '10 GB': '49',
    }, 
    backup: {
      ka: 'ყოველდღიური',
      en: 'Daily',
    }, 
    traffic: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    bandwidth: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    services: [
      {
        ka: 'SSL სერტიფიკატი',
        en: 'SSL Certificate',
      },
      {
        ka: 'FTP წვდომა',
        en: 'FTP Access',
      },
    ],
  },
  {
    id: '3',
    name: {
      ka: 'Web / Mail ჰოსტინგი',
      en: 'Web / Mail hosting',
    },
    price: {
      '5 GB': '29',
      '10 GB': '49',
    }, 
    backup: {
      ka: 'ყოველდღიური',
      en: 'Daily',
    }, 
    traffic: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    bandwidth: {
      ka: 'ულიმიტო',
      en: 'Unlimited',
    }, 
    services: [
      {
        ka: 'SSL სერტიფიკატი',
        en: 'SSL Certificate',
      },
      {
        ka: 'FTP წვდომა',
        en: 'FTP Access',
      },
    ],
  },
]