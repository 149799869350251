export const apiAbout = {
  getPage: 'about-us',
  getAup: 'aup',
}

export const apiCert = {
  getItems: 'cert-ge-items',
}

export const apiCompany = {
  getInfo: 'company-info',
}

export const apiContentForms = {
  getForms: 'content-forms',
  getFormFields: 'content-form-fields',
  getFormFieldTypes: 'content-form-field-types',
  getTextFields: 'content-text-fields',
  getIntegerFileds: 'content-integer-fields',
  getFloatFields: 'content-float-fields',
  getSelectFields: 'content-select-fields',
  getBooleanFields: 'content-boolean-fields',
  getDateFields: 'content-date-fields',
  getSubmitForm: 'content-submit-form',
}

export const apiDomains = {
  getSpecialDomains: 'special-domains',
  getPrice: 'domains-price',
  getDomains: 'domains',
  getRules: 'domains-rules',
  getPriceList: 'domains-price-list',
}

export const apiEducation = {
  getAcademies: 'academies',
  getCertCenters: 'cert-centers',
  getClassroomPhotos: 'classroom-photos',
  getCourses: 'courses',
  getCourseLanguages: 'course-languages',
  getCourseDurationUnits: 'course-duration-units',
  getCourseRegistrations: 'course-registrations',
  getForms: 'forms',
  getFormFields: 'form-fields',
  getFormFieldTypes: 'form-field-types',
  getTextFields: 'text-fields',
  getIntegerFileds: 'integer-fields',
  getFloatFields: 'float-fields',
  getSelectFields: 'select-fields',
  getBooleanFields: 'boolean-fields',
  getDateFields: 'date-fields',
  getCourseSubscriptions: 'course-subscriptions',
  getStudentRegistrations: 'student-registrations',
}

export const apiEdugain = {
  getMembers: 'edugain-members',
  getDocs: 'edugain-docs',
  getPage: 'edugain-page-contents',
}

export const apiEduroam = {
  getMembers: 'eduroam-members',
  getFaq: 'eduroam-faqs',
  getPage: 'eduroam-page-contents',
  getDocs: 'eduroam-docs',
}

export const apiFounders = {
  getItems: 'founders',
}

export const apiHosting = {
  getVpsPackages: 'virtualization-packages',
  getServices: 'virtualization-services',
  getProcess: 'virtualization-processes',
  getAdvantages: 'company-advantages',
  getHostingPackages: 'hosting-packages',
}

export const apiInternet = {
  getCat: 'internet-service-categories',
  getServices: 'internet-services',
  getPage: 'internet-page-objects',
}

export const apiLang = {
  getItems: 'languages',
}

export const apiMenus = {
  getMenus: 'menus',
  getMenuItems: 'menu-items',
}

export const apiNews = {
  getItems: 'news',
}

export const apiPartners = {
  getItems: 'partners',
}

export const apiProjects = {
  getOngoing: 'ongoing-projects',
  getCat: 'project-categories',
  getItems: 'projects',
}

export const apiServices = {
  getItems: 'services',
}

export const apiSocial = {
  getItems: 'social-networks',
}

export const apiSoftware = {
  getPage: 'licensed-software-content',
  getCat: 'licensed-software-categories',
  getPartners: 'licensed-software-partners',
}

export const apiStaff = {
  getItems: 'employees',
}

export const apiToolbar = {
  getItems: 'tools',
}

export const apiClientsUiUsers = {
  getSignUp: 'sign-up',
  getProfile: 'profile',
  getUsers: 'users',
  getUsersValidationEmailResend: 'users/validation-email-resend',
  getUsersPasswordReset: 'users/password-reset',
  getUsersPasswordResetConfirm: 'users/password-reset-confirm',
  getUsersPasswordUpdate: 'users/password-update',
  getUsersEmailConfirm: 'users/email-confirm',
  getAccounts: 'accounts',
}