import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { AboutDescriptionBox } from '../../themes/boxAbout'
import { aboutPage } from '../../data/about'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiAbout } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import parse from 'html-react-parser'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const About = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const videoWidth = useRef(null)

  const [page, setPage] = useState([])
  const [description, setDescription] = useState('')
  const [activitiesTitle, setActivitiesTitle] = useState('')
  const [activities, setActivities] = useState([])
  const [video, setVideo] = useState('')
  const [videoHeight, setVideoHeight] = useState(315)
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchPage = async () => {
      const response = await request(settings.domain + settings.api + apiAbout.getPage, 'GET')

      if (response && response.length > 0) {
        setPage(response)
      } else {
        setPage([])
      }
    }

    setVideoHeight(videoWidth.current.clientWidth * 0.5625)

    if (settings.mode === 'live') {
      fetchPage()
    } else {
      setPage(aboutPage)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    const getDescription = (a) => {
      const object = a.find(item => item.var_name === 'description')
      return object ? object.data[0][lang] : ''
    }
    
    const getActivitiesTitle = (a) => {
      const object = a.find(item => item.var_name === 'activities')
      return object ? object.data[0].title[lang] : ''
    }
  
    const getActivities = (a) => {
      const object = a.find(item => item.var_name === 'activities')
      return object ? object.data[0].description : []
    }
  
    const getVideo = (a) => {
      const object = a.find(item => item.var_name === 'video')
      const code = object ? object.data[0].video : ''
      return code ? `<iframe style="width:100%; height:${videoHeight}px;" src="https://www.youtube.com/embed/${code}?rel=0" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>` : ''
    }

    setDescription(getDescription(page))
    setActivitiesTitle(getActivitiesTitle(page))
    setActivities(getActivities(page))
    setVideo(getVideo(page))

    if (page.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [page, lang, videoHeight])

  return (
    <>
      <HelmetElement
        metaDescription='Providing Information Technology services to users and implementation of new, innovative services, Providing software products and cyber security solutions to customers'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.about[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            <Grid container spacing={{ xs: 3, lg: 6 }}>
              <Grid xs={12} lg={6}>
                <AboutDescriptionBox>
                  <Box>{description}</Box>
                  <br />
                  <Box>{activitiesTitle}</Box>
                  <br />
                  {
                    activities.map((item, index) => (
                      <Box key={index}>{index + 1}. {parse(item[lang])}</Box>
                    ))
                  }
                </AboutDescriptionBox>
              </Grid>
              <Grid xs={12} lg={6}>
                <Box ref={videoWidth}>
                  {parse(video)}
                </Box>
              </Grid>
            </Grid>
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default About