import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setLang } from '../../reducers/lang'
import { LanguageBox, LanguageMobileBox } from '../../themes/boxLayout'
import { langItems } from '../../data/langs'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiLang } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'

const Language = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [langData, setLangData] = useState([])

  const langObject = langData.find(item => item.is_active)
  const defaultLang = langObject ? langObject.name : 'ka'

  if (!lang) {
    dispatch(setLang(defaultLang))
  }

  const switchLangObject = langData.find(item => item.name !== lang)
  const switchLangName = switchLangObject ? switchLangObject.name : 'ka'
  const switchLangTitle = switchLangObject ? switchLangObject.title.en : 'GEO'

  const handleClick = (name) => {
    dispatch(setLang(name))
    navigate(location.pathname)
  }

  useEffect(() => {
    const fetchLangData = async () => {
      const response = await request(settings.domain + settings.api + apiLang.getItems, 'GET')

      if (response && response.length > 0) {
        setLangData(response)
      } else {
        setLangData([])
      }
    }

    if (settings.mode === 'live') {
      fetchLangData()
    } else {
      setLangData(langItems)
    }
  }, [])

  return (
    <>
      {
        width > settings.desktopBreakpoint ? (
          <LanguageBox onClick={() => handleClick(switchLangName)}>{switchLangTitle}</LanguageBox>
        ) : (
          <LanguageMobileBox onClick={() => handleClick(switchLangName)}>{switchLangTitle}</LanguageMobileBox>
        )
      }
    </>
  )
}

export default Language