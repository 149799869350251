import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { projectsOngoingItems } from '../../data/projects'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiProjects } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import ProjectsOngoingItem from './ProjectsOngoingItem'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const ProjectsOngoing = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [projects, setProjects] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await request(settings.domain + settings.api + apiProjects.getOngoing, 'GET')

      if (response && response.length > 0) {
        setProjects(response)
      } else {
        setProjects([])
      }
    }

    if (settings.mode === 'live') {
      fetchProjects()
    } else {
      setProjects(projectsOngoingItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (projects.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [projects])
  
  return (
    <>
      <HelmetElement
        metaDescription={ projects.length > 0 ? projects[0].title[lang] : '' }
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}>
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.ongoingProjects[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            {
              projects.map((item, index) => (
                <ProjectsOngoingItem key={index} {...item} />
              ))
            }
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>    
  )
}

export default ProjectsOngoing