import React from 'react'
import { useSelector } from 'react-redux'
import TableRow from '@mui/material/TableRow'
import DoneIcon from '@mui/icons-material/Done'
import ScheduleIcon from '@mui/icons-material/Schedule'
import PlaceIcon from '@mui/icons-material/Place'
import { EduroamMembersBodyTableCell } from '../../themes/table'
import { EduroamMemebersLogoBox, EduroamMembersLabelsBox, EduroamMemebersStatusBox } from '../../themes/boxEduroam'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const EduroamMembersItem = ({ name, logo_url, website, status, coordinates, handleOpenModal }) => {
  const { width, height } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const statusIcon = status.en === 'Enabled' ? <DoneIcon fontSize='large' sx={{ color: '#04AB3D', marginRight: '10px' }} /> : <ScheduleIcon fontSize='large' sx={{ color: '#FF9A03', marginRight: '10px' }} />

  const sxLogoCell = width > settings.desktopBreakpoint ? { width: '20%' } : {}
  const sxNameCell = width > settings.desktopBreakpoint ? { width: '40%' } : { width: '1px', fontSize: '1px' }

  const handleLogoClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(website, '_blank')
    } else if (e.type === 'click') {
      window.open(website, '_blank')
    }
  }

  const handleLocationClick = () => {
    let iframeWidth = 800
    let iframeHeight = 600

    if (width < 800) {
      iframeWidth = '100%'
    }

    if (height < 600) {
      iframeHeight = height * 0.7
    }

    const googleMapURL = `https://www.google.com/maps/d/embed?mid=1l837Cs5sx6u5c4pgKhGGD4soWTE&amp;ll=${coordinates.lat},%20${coordinates.lng}`
    const iframe = `<iframe width="${iframeWidth}" height="${iframeHeight}" src="${googleMapURL}"></iframe>`
    handleOpenModal({ title: name[lang], content: iframe })
  }

  return (
    <TableRow>
      <EduroamMembersBodyTableCell sx={sxLogoCell}>
        <EduroamMemebersLogoBox onClick={handleLogoClick} onMouseDown={handleLogoClick}>
          { logo_url && <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90%', maxHeight: '100px' }} /> }
        </EduroamMemebersLogoBox>
      </EduroamMembersBodyTableCell>
      <EduroamMembersBodyTableCell sx={sxNameCell}>{ width > settings.desktopBreakpoint && name[lang] }</EduroamMembersBodyTableCell>
      <EduroamMembersBodyTableCell>
        {
          ( coordinates.lat && coordinates.lng ) && (
            <EduroamMemebersStatusBox>
              <PlaceIcon fontSize='large' sx={{ color: 'rgba(7, 77, 120, 0.7)' }} onClick={handleLocationClick} />
              { width > settings.desktopBreakpoint && <EduroamMembersLabelsBox sx={{ cursor: 'pointer' }} onClick={handleLocationClick}>{constants.location[lang]}</EduroamMembersLabelsBox> }
            </EduroamMemebersStatusBox>
          )
        }
      </EduroamMembersBodyTableCell>
      <EduroamMembersBodyTableCell>
        <EduroamMemebersStatusBox>
          {statusIcon}
          { width > settings.desktopBreakpoint && <EduroamMembersLabelsBox>{status[lang]}</EduroamMembersLabelsBox> }
        </EduroamMemebersStatusBox>
      </EduroamMembersBodyTableCell>
    </TableRow>
  )
}

export default EduroamMembersItem