import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tokens: {},
}

const webpageDataSlice = createSlice({
  name: 'webpageData',
  initialState,
  reducers: {
    setWebpageTokens: (state, action) => {
      state.tokens = action.payload.tokens
    },
  }
})

export const { setWebpageTokens } = webpageDataSlice.actions

export default webpageDataSlice.reducer