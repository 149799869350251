import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import {
  EdugainMembersLabelBox,
  EdugainMembersContainerBox,
  EdugainMembersLeftFigureBox,
  EdugainMembersRightFigureBox,
  EdugainMembersCategoryTitleBox
} from '../../themes/boxEdugain'
import { eduroamGeorgiaMapImage } from '../../themes/image'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import EdugainMemebersCategoryTitle from './EdugainMemebersCategoryTitle'
import EdugainMembersCategory from './EdugainMembersCategory'

const EdugainMembers = ({ page, members }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const membersObject = page.find(item => item.var_name === 'members')
  const label = membersObject ? membersObject.data[0].title[lang] : ''
  const membersData = membersObject ? membersObject.data[0].members : []

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
        <EdugainMembersLabelBox>{label}</EdugainMembersLabelBox>
      </Box>
      <EdugainMembersContainerBox>
        <Grid container>
          {
            membersData.map((item, index) => (
              <EdugainMemebersCategoryTitle key={index} index={index} membersData={membersData} members={members} {...item} />
            ))
          }
        </Grid>

        {
          width > settings.desktopBreakpoint && (
            <>
              <Grid container>
                <Grid lg={4}>
                  <EdugainMembersLeftFigureBox></EdugainMembersLeftFigureBox>
                </Grid>
                <Grid lg={4}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', margin: '25px 0px', }}>
                    <img src={eduroamGeorgiaMapImage} alt='' style={{ height: '64px' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <EdugainMembersCategoryTitleBox>{constants.membersInGeorgia[lang]}</EdugainMembersCategoryTitleBox>
                  </Box>
                </Grid>
                <Grid lg={4}>
                  <EdugainMembersRightFigureBox></EdugainMembersRightFigureBox>
                </Grid>
              </Grid>
              <Grid container>
              {
                membersData.map((item, index) => (
                  <EdugainMembersCategory key={index} index={index} members={members} />
                ))
              }
            </Grid>
            </>
          )
        }
      </EdugainMembersContainerBox>
    </>
  )
}

export default EdugainMembers