import React from 'react'
import TextField from '@mui/material/TextField'

const FormInteger = ({ label, placeholder, value, required, error, handleChange, min, max, fixedWidth }) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      required={required}
      error={error}
      value={value}
      onChange={e => handleChange(e.target.value)}
      variant='outlined'
      type='number'
      sx={{ width: fixedWidth ? fixedWidth : '100%' }}
      InputProps={{
        inputProps: { min, max },
        sx: { borderRadius: '8px', },
      }}
    />
  )
}

export default FormInteger