import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ProjectsItem from './ProjectsItem'

const ProjectsCategory = ({ id, name, expanded, index, handleExpand, projects }) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const projectsFiltered = projects.filter(item => item.category === id)

  const sxAccordion = {
    background: 'none',
    marginBottom: '16px',
    boxShadow: 'none'
  }

  const sxSummary = {
    minHeight: '64px',
    border: `${theme.palette.darkblue.main} 1px solid`,
    borderRadius: '4px',
    fontFamily: theme.typography.labels.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.darkblue.main,
    textTransform: theme.typography.mainmenu.textTransform,
    fontWeight: 'bold',
    padding: '0px 3%',
    margin: '0px',
  }

  const sxSummaryExpanded = {
    minHeight: '64px',
    border: `${theme.palette.darkblue.main} 1px solid`,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontFamily: theme.typography.labels.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.darkblue.main,
    textTransform: theme.typography.mainmenu.textTransform,
    fontWeight: 'bold',
    padding: '0px 3%',
    margin: '0px',
  }

  const sxDetails = {
    borderLeft: `${theme.palette.darkblue.main} 1px solid`,
    borderRight: `${theme.palette.darkblue.main} 1px solid`,
    borderBottom: `${theme.palette.darkblue.main} 1px solid`,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    background: 'rgba(46, 156, 214, 0.1)',
    padding: '40px 3% 0px 5%',
  }

  return (
    <Accordion expanded={ expanded === index } onChange={() => handleExpand(index)} sx={sxAccordion}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon fontSize='large' sx={{ color: theme.palette.darkblue.main }} />}
        sx={ expanded === index ? sxSummaryExpanded : sxSummary }
      >
        <Box>{name[lang]}</Box>
      </AccordionSummary>
      <AccordionDetails sx={sxDetails}>
        {
          projectsFiltered.map((item, index) => (
            <ProjectsItem key={index} {...item} />
          ))
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default ProjectsCategory