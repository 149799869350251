import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import {
  EdugainPageHeadBox,
  EdugainPageBodyBox,
  EdugainPageFootBox,
  EdugainPageTitleBox,
  EdugainPageTitleTextBox,
  EdugainPageDescriptionBox
} from '../../themes/boxEdugain'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const EdugainPage = ({ page }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const descriptionObject = page.find(item => item.var_name === 'description')
  const title = descriptionObject ? descriptionObject.data[0].title[lang] : ''
  const logo = descriptionObject ? descriptionObject.data[0].logo : ''
  const description = descriptionObject ? descriptionObject.data[0].description : []

  return (
    <>
      <EdugainPageHeadBox></EdugainPageHeadBox>
      <EdugainPageBodyBox>
        <EdugainPageTitleBox>
          <EdugainPageTitleTextBox>{title}</EdugainPageTitleTextBox>
          <Box>
            { (logo && width > 1024) && <img src={ settings.s3URL + settings.s3Bucket + logo } alt='' style={{ maxWidth: '200px' }} /> }
          </Box>
        </EdugainPageTitleBox>
        <EdugainPageDescriptionBox>
          {
            description.map((item, index) => (
              <Box key={index} sx={{ marginBottom: index !== description.length -1 && '24px' }}>{item[lang]}</Box>
            ))
          }
        </EdugainPageDescriptionBox>
      </EdugainPageBodyBox>
      <EdugainPageFootBox></EdugainPageFootBox>
    </>
  )
}

export default EdugainPage