import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const InternetPageTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: theme.typography.mainmenu.fontSize,
  lineHeight: '24px',
  paddingBottom: '30px',
}))

export const InternetServicesLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  padding: '30px 0px 50px 0px',
  textAlign: 'center',

  '@media (max-width: 1199px)': {
    fontSize: '20px',
  }
}))

export const InternetServicesCategoryItemBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.offwhite.main,
  minHeight: '430px',
  borderRadius: '8px',
  padding: '50px 30px 30px 30px',

  '@media (max-width: 1500px)': {
    minHeight: '500px',
  },

  '@media (max-width: 1200px)': {
    minHeight: '550px',
  },

  '@media (max-width: 899px)': {
    minHeight: '1px',
  }
}))

export const InternetServicesCategoryTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  lineHeight: '28px',
  paddingBottom: '30px',
  textAlign: 'center',
}))

export const InternetServicesUlBox = styled(Box)(({ theme }) => ({
  background: theme.palette.offwhite.main,
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  margin: '5px 8px 30px 0px',
}))

export const InternetServicesDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontFamily,
  lineHeight: '24px',
  paddingBottom: '24px',
  width: 'calc(100% - 18px)',
}))