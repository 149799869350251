import React from 'react'
import { SoftwarePartnersImageBox } from '../../themes/boxSoftware'
import settings from '../../helpers/settings'

const SoftwarePartnersMobileLogo = ({ logo_url, website }) => {
  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(website, '_blank')
    } else if (e.type === 'click') {
      window.open(website, '_blank')
    } 
  }

  return (
    <SoftwarePartnersImageBox>
      <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90%' }} onClick={handleClick} onMouseDown={handleClick} />
    </SoftwarePartnersImageBox>
  )
}

export default SoftwarePartnersMobileLogo