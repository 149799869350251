import { edugainLogoImage } from '../themes/image'

export const edugainMembers = [
  {
    id: '1',
    name: {
      ka: 'EBSCO საინფორმაციო სერვისები',
      en: 'EBSCO Information Services',
    },
    website: 'https://www.ebsco.com/',
    type: 'sp',
  },
  {
    id: '2',
    name: {
      ka: 'გრენა',
      en: 'GRENA',
    },
    website: 'https://www.grena.ge/',
    type: 'idp',
  },
  {
    id: '3',
    name: {
      ka: 'ეროვნული სამეცნიერო ბიბლიოთეკა',
      en: 'National Scientific Library',
    },
    website: 'https://www.sciencelib.ge/',
    type: 'idp',
  },
  {
    id: '4',
    name: {
      ka: 'კავკასიის უნივერსიტეტი',
      en: 'Caucasus University',
    },
    website: 'https://www.cu.edu.ge/',
    type: 'idp',
  },
]

export const edugainPage = [
  {
    id: '1',
    var_name: 'description',
    data: [
      {
        title: {
          ka: 'რა არის eduGAIN?',
          en: 'What is eduGAIN?',
        },
        logo: edugainLogoImage,
        description: [
          {
            ka: 'The GRENA Identity Federation (GIF) assists cross-institutional services by providing federated authentication and authorization among participating organizations. Any Georgian education or research institution, also third parties wishing to provide services to the community can become member of GRENA Identity Federation.',
            en: 'The GRENA Identity Federation (GIF) assists cross-institutional services by providing federated authentication and authorization among participating organizations. Any Georgian education or research institution, also third parties wishing to provide services to the community can become member of GRENA Identity Federation.',
          },
          {
            ka: 'Identity Provider (IdP) - is education or research institution authenticating their users and certifying their identities for the provision secure and trustworthy access to resources.',
            en: 'Identity Provider (IdP) - is education or research institution authenticating their users and certifying their identities for the provision secure and trustworthy access to resources.',
          },
          {
            ka: 'The GRENA Identity Federation (GIF) assists cross-institutional services by providing federated authentication and authorization among participating organizations. Any Georgian education or research institution, also third parties wishing to provide services to the community can become member of GRENA Identity Federation.',
            en: 'The GRENA Identity Federation (GIF) assists cross-institutional services by providing federated authentication and authorization among participating organizations. Any Georgian education or research institution, also third parties wishing to provide services to the community can become member of GRENA Identity Federation.',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    var_name: 'members',
    data: [
      {
        title: {
          ka: 'eduGAIN-ის წევრები',
          en: 'Members of eduGAIN',
        },
        members: [
          {
            title: {
              ka: 'Service Provider',
              en: 'Service Provider',
            },
            description: {
              ka: 'ლორემ იპსუმ კორალის შემიძლია დაბრძანდი გულისტკივილი მოვიგონოთ იამაიკელი ხოცავენ, ბანაკიდან მივატოვებ წინადღის. გაცხარებულნი ციხეებზე ბერნარდ უგუნებოდ გასაწკეპლი შემოახვია შეძურწდა.',
              en: 'Identity Provider (IDP) - is education or research institution authenticating their users and certifying their identities for the provision secure and trustworthy access to resources.',
            },
          },
          {
            title: {
              ka: 'Identity Provider',
              en: 'Identity Provider',
            },
            description: {
              ka: 'შემოახვია მოძრაობისა ხოცავენ, რჩევისა ხევ, დოლიძის ცერებზე რევალციამ გაგაძრო უსასრულობის ბანაკიდან შემიძლია კლავსო, იამაიკელი ექცეოდნენ. გადადეთ ურჩევენ ბანაკიდან მოძრაობისა ბერნარდ მაძლევდა კორალის ნოსტალგიური ქვეყნად სამპირი.',
              en: 'Identity Provider (IDP) - is education or research institution authenticating their users and certifying their identities for the provision secure and trustworthy access to resources.',
            },
          },
        ],
      }
    ],
  },
  {
    id: '3',
    var_name: 'buttontext',
    data: [
      {
        ka: 'თუ დაინტერესდებით, მოგვწერეთ',
        en: 'If you are interested, contact us',
      }
    ],
  },
]

export const edugainDocs = [
  {
    id: '1',
    name: {
      ka: 'GRENA Identity Federation Policy',
      en: 'GRENA Identity Federation Policy',
    },
    file_url: 'https://gif.grena.ge/admin/editor/uploads/files/GIF%20Identity%20Federation%20Policy.pdf',
    type: 'doc',
  },
  {
    id: '2',
    name: {
      ka: 'GRENA eduGAIN Declaration',
      en: 'GRENA eduGAIN Declaration',
    },
    file_url: 'https://gif.grena.ge/admin/editor/uploads/files/eduGAIN_Declaration_GRENA.pdf',
    type: 'doc',
  },
  {
    id: '3',
    name: {
      ka: 'Georgian Identity Federation - local entities aggregated eduGAIN upstream metadata',
      en: 'Georgian Identity Federation - local entities aggregated eduGAIN upstream metadata',
    },
    file_url: 'https://mtd.gif.grena.ge/md/edugain-upstream.xml',
    type: 'metadata',
  },
  {
    id: '4',
    name: {
      ka: 'Georgian Identity Federation - eduGAIN downstream metadata',
      en: 'Georgian Identity Federation - eduGAIN downstream metadata',
    },
    file_url: 'https://mtd.gif.grena.ge/md/federation-downstream.xml',
    type: 'metadata',
  },
  {
    id: '5',
    name: {
      ka: 'The federation metadata files are digitally signed with the following Certificate',
      en: 'The federation metadata files are digitally signed with the following Certificate',
    },
    file_url: 'https://gif.grena.ge/admin/editor/uploads/files/cert.pem',
    type: 'metadata',
  },
]