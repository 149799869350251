import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { logoKaImage, logoEnImage } from '../../themes/image'
import { setBurgerRight } from '../../reducers/burgerRight'

const Logo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const lang = useSelector(state => state.lang)

  const logo = lang === 'ka' ? logoKaImage : logoEnImage

  const handleLogoClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/', '_blank')
    } else if (e.type === 'click') {
      navigate('/')
      window.scroll(0, 0)
      dispatch(setBurgerRight('-100%'))
    } 
  }

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleLogoClick} onMouseDown={handleLogoClick}><img src={logo} alt='' style={{ maxWidth: '100%' }} /></Box>
  )
}

export default Logo