import React from 'react'
import { useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import { ProjectsTitleBox } from '../../themes/boxProjects'
import { AupOrganizationsUlBox, AupOrganizationsTextBox } from '../../themes/boxAbout'

const ProjectsItem = ({ year, name }) => {
  const lang = useSelector(state => state.lang)

  return (
    <ProjectsTitleBox>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={0}
      >
        <AupOrganizationsUlBox></AupOrganizationsUlBox>
        <AupOrganizationsTextBox>{year} {name[lang]}</AupOrganizationsTextBox>
      </Stack>
    </ProjectsTitleBox>
  )
}

export default ProjectsItem