import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContent0Box } from '../../themes/boxLayout'
import { vpsPackageItems, vpsProcessItems, vpsServiceItems, vpsAdvantagesItems } from '../../data/hosting'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiHosting } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import VpsPackages from './VpsPackages'
import VpsProcess from './VpsProcess'
import VpsServices from './VpsServices'
import HostingAdvantages from '../hosting/HostingAdvantages'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Vps = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [packages, setPackages] = useState([])
  const [process, setProcess] = useState([])
  const [services, setServices] = useState([])
  const [advantages, setAdvantages] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px', }

  useEffect(() => {
    const fetchPackages = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getVpsPackages, 'GET')

      if (response && response.length > 0) {
        setPackages(sortByPriority(response))
      } else {
        setPackages([])
      }
    }

    const fetchProcess = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getProcess, 'GET')

      if (response && response.length > 0) {
        setProcess(sortByPriority(response))
      } else {
        setProcess([])
      }
    }

    const fetchServices = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getServices, 'GET')

      if (response && response.length > 0) {
        setServices(response)
      } else {
        setServices([])
      }
    }

    const fetchAdvantages = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getAdvantages, 'GET')

      if (response && response.length > 0) {
        setAdvantages(response)
      } else {
        setAdvantages([])
      }
    }

    if (settings.mode === 'live') {
      fetchPackages()
      fetchProcess()
      fetchServices()
      fetchAdvantages()
    } else {
      setPackages(vpsPackageItems)
      setProcess(sortByPriority(vpsProcessItems))
      setServices(vpsServiceItems)
      setAdvantages(vpsAdvantagesItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (packages.length > 0 && process.length > 0 && services.length > 0 && advantages.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [packages, process, services, advantages])

  return (
    <>
      <HelmetElement
        metaDescription='Virtual Servers, Server Administration Configuration Monitoring, Data Migration, Fault Detection, Server Resource Scailing, Backups, Technical Support'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}>
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.vps[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContent0Box>
            <VpsPackages packages={packages} />    
          </RegularPageContent0Box>
        </Container>
        <VpsProcess process={process} />
        <Container maxWidth='xl'>
          <RegularPageContent0Box>
            <VpsServices services={services} />
            <HostingAdvantages advantages={advantages} />
          </RegularPageContent0Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Vps