import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageTitleLinkBox, RegularPageContent0Box } from '../../themes/boxLayout'
import { AboutDescriptionBox } from '../../themes/boxAbout'
import { NewsItemDateBox, NewsPageItemBox } from '../../themes/boxNews'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiNews } from '../../helpers/urls'
import { formatNewsDate } from '../../helpers/dates'
import { cleanWordTags } from '../../helpers/string'
import useWindowDimensions from '../../helpers/dimensions'
import parse from 'html-react-parser'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const NewsFullPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [newsItem, setNewsItem] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const sxImage = width > settings.padBreakpoint ? { width: '40%', float: 'left', margin: '0px 20px 10px 0px' } : { width: '100%', float: 'left', margin: '0px 0px 20px 0px' }

  const handleBackToNews = () => {
    navigate('/news')
  }

  useEffect(() => {
    const fetchNewsItem = async () => {
      const response = await request(settings.domain + settings.api + apiNews.getItems + '/' + params.id, 'GET')

      if (response && response.title) {
        setNewsItem(response)
      } else {
        setNewsItem('')
      }
    }

    if (settings.mode === 'live') {
      fetchNewsItem()
    } else {
      setNewsItem('')
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [params.id])

  useEffect(() => {
    if (newsItem.title) {
      setBackdropOpen(false)
      setErrorMessageOpen(false) 
    }
  }, [newsItem])
  
  return (
    <>
      <HelmetElement
        metaDescription={ (newsItem && newsItem.title) ? newsItem.title[lang] : '' }
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>
                <RegularPageTitleLinkBox onClick={handleBackToNews}>{constants.news[lang]}</RegularPageTitleLinkBox>
              </RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContent0Box>
            {
              newsItem && (
                <NewsPageItemBox>
                  { newsItem.image_url && <img src={ settings.s3URL + settings.s3Bucket + newsItem.image_url } alt='' style={sxImage} /> }
                  <NewsItemDateBox>{formatNewsDate(newsItem.start_date, newsItem.end_date, lang)}</NewsItemDateBox>
                  <AboutDescriptionBox>{parse(cleanWordTags(newsItem.description[lang]))}</AboutDescriptionBox>
                  <Box sx={{ clear: 'both' }}></Box>
                </NewsPageItemBox>
              )
            }
          </RegularPageContent0Box>
        </Container>
      </RegularPageFluidBox>
    </>    
  )
}

export default NewsFullPage