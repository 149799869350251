import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const EduroamDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  paddingBottom: '50px',

  '@media(max-width: 1199px)': {
    paddingBottom: '30px',
  }
}))

export const EduroamHowToJoinBox = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  padding: '50px 50px 30px 50px',
  marginBottom: '50px',
  background: 'rgba(46, 156, 214, 0.1)',
  borderRadius: '8px',

  '@media(max-width: 1199px)': {
    padding: '30px 30px 15px 30px',
    marginBottom: '30px',
  }
}))

export const EduroamHowToJoinTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  lineHeight: '28px',
  textTransform: theme.typography.mainmenu.textTransform,
  borderBottom: '1px solid rgba(7, 77, 120, 0.7)',
  marginBottom: '30px',
  paddingBottom: '10px',

  '@media(max-width: 1199px)': {
    fontSize: '18px',
  }
}))

export const EduroamHowToJoinUlBox = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  margin: '5px 15px 20px 0px',
}))

export const EduroamHowToJoinItemBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  paddingBottom: '20px',
  width: 'calc(95% - 25px)',
}))

export const EduroamMembersLabelsBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const EduroamMenuSeparatorBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  padding: '0px 12px',
}))

export const EduroamDropdownBox = styled(Box)({
  background: '#476E84',
  borderRadius: '8px',

  '@media(max-width: 500px)': {
    margin: '0px 10px',
  }
})

export const EduroamLocationsItemBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '24px',
  height: '90px',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '8px',
  marginBottom: '15px',
  padding: '10px',
  cursor: 'pointer',
}))

export const EduroamLocationsLogoBox = styled(Box)({
  minHeight: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const EduroamLocationsTitleBox = styled(Box)({
  minHeight: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

export const EduroamMemebersLogoBox = styled(Box)({
  width: '100%',
  minHeight: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  '@media(max-width: 1199px)': {
    minHeight: '50px',
  }
})

export const EduroamMemebersStatusBox = styled(Box)({
  width: '100%',
  minHeight: '120px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media(max-width: 1199px)': {
    minHeight: '50px',
  }
})

export const EduroamFaqBox = styled(Box)(({ theme }) => ({
  color: theme.palette.offwhite.main,
  background: `${theme.palette.primary.main}`,
  borderRadius: '8px',
  padding: '0px 80px 40px 80px',

  '@media(max-width: 1199px)': {
    padding: '20px 30px 20px 30px',
  }
}))

export const EduroamFaqLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  textTransform: theme.typography.mainmenu.textTransform,
  height: '90px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  marginBottom: '30px',

  '@media(max-width: 1199px)': {
    height: 'auto',
  }
}))

export const EduroamFaqLabelTextBox = styled(Box)(({ theme }) => ({
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,
  padding: '0px 30px 5px 30px',
}))

export const EduroamFaqUlBox = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '24px',
  height: '24px',
  borderRadius: '4px',
  margin: '0px 16px 16px 0px',
  fontFamily: theme.typography.regular.fontFamily,
  textAlign: 'center',
}))

export const EduroamFaqTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  marginBottom: '16px',
  width: 'calc(95% - 40px)',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:hover': {
    textDecoration: 'underline',
  }
}))