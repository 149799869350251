import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageContent0Box } from '../../themes/boxLayout'
import { EducationRegistrationAlertBox, EducationAcademiesBox } from '../../themes/boxEducation'
import { constants } from '../../data/constants'
import EducationAcademyItem from './EducationAcademyItem'

const EducationAcademies = ({ academies, courseRegistrations }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const lang = useSelector(state => state.lang)

  const [hoverIndex, setHoverIndex] = useState(-1)
  
  const courseRegistrationArray = courseRegistrations.filter(item => item.is_opened)
  const coursesRegistrationNumber = courseRegistrationArray.length

  const polyCoordinates = {
    logoLeft: '4,76 44,4 124,4 164,76 124,148 44,148',
    titleLeft: '124,4 454,4 494,76 454,148 124,148 164,76',
    logoRight: '413,76 453,4 533,4 573,76 533,148 453,148',
    titleRight: '83,76 123,4 453,4 413,76 453,148 123,148',
  }

  const polyStyle = {
    fill: 'none',
    stroke: `${theme.palette.primary.main}`,
    strokeWidth: '4px',
    transition: 'all 0.3s',
  }

  const polyStylesHover = [
    {
      logo: {
        fill: 'none',
        stroke: '#15495E',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      title: {
        fill: '#15495E',
        stroke: '#15495E',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      white: true,
    },
    {
      logo: {
        fill: 'none',
        stroke: '#FEC20F',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      title: {
        fill: '#FEC20F',
        stroke: '#FEC20F',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      white: false,
    },
    {
      logo: {
        fill: 'none',
        stroke: '#C31D20',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      title: {
        fill: '#C31D20',
        stroke: '#C31D20',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      white: true,
    },
    {
      logo: {
        fill: 'none',
        stroke: '#E51D20',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      title: {
        fill: '#E51D20',
        stroke: '#E51D20',
        strokeWidth: '8px',
        transition: 'all 0.3s',
      },
      white: true,
    },
  ]

  const handleMouseEnter = (index) => {
    setHoverIndex(index)
  }

  const handleMouseLeave = () => {
    setHoverIndex(-1)
  }

  const handleAlertClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/courses_active', '_blank')
    } else if (e.type === 'click') {
      navigate('/courses_active')
      window.scroll(0, 0)
    }
  }

  return (
    <Grid container>
      <Grid xs={12} lg={3}>
        <EducationRegistrationAlertBox onClick={handleAlertClick} onMouseDown={handleAlertClick}>{constants.registrationOpen[lang].replace('###NUM###', coursesRegistrationNumber)}</EducationRegistrationAlertBox>
      </Grid>
      <Grid xs={12} lg={6}>
        <Container maxWidth='xl'> 
          <RegularPageContent0Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <EducationAcademiesBox>
              {
                academies.map((item, index) => (
                  <EducationAcademyItem
                    key={index}
                    {...item}
                    index={index}
                    polyCoordinates={polyCoordinates}
                    polyStyle={polyStyle}
                    polyStylesHover={polyStylesHover}
                    hoverIndex={hoverIndex}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                  />
                ))
              }
            </EducationAcademiesBox>
          </RegularPageContent0Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default EducationAcademies