import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { EdugainMembersCategoryTitleBox, EdugainMembersCategoryDescriptionBox } from '../../themes/boxEdugain'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import EdugainMembersCategory from './EdugainMembersCategory'

const EdugainMemebersCategoryTitle = ({ title, description, index, membersData, members }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  return (
    <>
      <Grid xs={12} lg={5}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <EdugainMembersCategoryTitleBox>{title[lang]}</EdugainMembersCategoryTitleBox>
        </Box>
        <EdugainMembersCategoryDescriptionBox>{description[lang]}</EdugainMembersCategoryDescriptionBox>
      </Grid>

      { (index%2 ===0 && width > settings.desktopBreakpoint) && <Grid lg={2}></Grid> }

      {
        width <= settings.desktopBreakpoint && (
          <EdugainMembersCategory index={index} members={members} />
        )
      }
    </>
  )
}

export default EdugainMemebersCategoryTitle