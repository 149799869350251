import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { EduroamMenuItemButton } from '../../themes/button'
import settings from '../../helpers/settings'

const EduroamFileItem = ({ name, file_url, isLast }) => {
  const lang = useSelector(state => state.lang)

  const sx = isLast ? { borderBottom: 'none' } : {}

  const handleClick = () => {
    const url = file_url.includes('http') ? file_url : settings.s3URL + settings.s3Bucket + file_url
    window.open(url, '_blank')
  }

  return (
    <Box>
      <EduroamMenuItemButton onClick={handleClick} sx={sx}>{name[lang]}</EduroamMenuItemButton>
    </Box>
  )
}

export default EduroamFileItem