import React from 'react'
import { TypeAnimation } from 'react-type-animation'

const TypingAnimation = ({ text, delay }) => {
  return (
    <TypeAnimation
      sequence={[ delay, text ]}
      speed={60}
      repeat={0}
      cursor={false}
    />
  )
}

export default TypingAnimation