import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { VpsProcessBox, VpsProcessLabelBox } from '../../themes/boxHosting'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { OnlyPaddingBox } from '../../themes/boxLayout'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import VpsProcessItem from './VpsProcessItem'
import SlickSlider from '../layout/SlickSlider'

const VpsProcess = ({ process }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let slidesToShowValue = 2
  let slidesToScrollValue = 2

  if (width > 1000) {
    slidesToShowValue = 3
    slidesToScrollValue = 3
  } else if (width > 650) {
    slidesToShowValue = 2
    slidesToScrollValue = 2
  } else {
    slidesToShowValue = 1
    slidesToScrollValue = 1
  }

  const [slides, setSlides] = useState([])
  const [slidesToShow , setSlidesToShow] = useState(slidesToShowValue)
  const [slidesToScroll , setSlidesToScroll] = useState(slidesToScrollValue)

  const sliderSpeed = 500

  const sxPrev = {
    position: 'absolute',
    top: '45%',
    left: '-20px',
    cursor: 'pointer',
  }

  const sxNext = {
    position: 'absolute',
    top: '45%',
    right: '-20px',
    cursor: 'pointer',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon color='offwhite' sx={sxPrev} />,
    leftStyle: {},
    leftFunction: '',
    rightIcon: <ArrowForwardIcon color='offwhite' sx={sxNext} />,
    rightStyle: {},
    rightFunction: '',
  }

  useEffect(() => {
    if (width > 1000) {
      setSlidesToShow(3)
      setSlidesToScroll(3)
    } else if (width > 650) {
      setSlidesToShow(2)
      setSlidesToScroll(2)
    } else {
      setSlidesToShow(1)
      setSlidesToScroll(1)
    }
  }, [width])

  useEffect(() => {
    let t = []
    process.forEach((item, index) => {
      t.push(<VpsProcessItem key={index} index={index} {...item} />)
    })
    setSlides(t)
  }, [process])

  return (
    <VpsProcessBox>
      <Container maxWidth='xl'>
        <OnlyPaddingBox>
          <VpsProcessLabelBox>{constants.individualApproach[lang]}</VpsProcessLabelBox>
          {
            width > settings.desktopBreakpoint ? (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='flex-start'
                spacing={0}
              >
                {
                  process.map((item, index) => (
                    <VpsProcessItem key={index} index={index} {...item} />
                  ))
                }
              </Stack>
            ) : (
              <SlickSlider
                slides={slides}
                sxBody={{ position: 'relative' }}
                arrows={sliderArrows}
                pagination={{}}
                autoplay={false}
                draggable={true}
                speed={sliderSpeed}
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                centerMode={false}
                handleAfterChange=''
              />
            )
          }
        </OnlyPaddingBox>
      </Container>
    </VpsProcessBox>
  )
}

export default VpsProcess