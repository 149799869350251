import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { EducationClassroomLabelBox } from '../../themes/boxEducation'
import { OnlyPaddingBox } from '../../themes/boxLayout'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import ModalImage from '../layout/ModalImage'
import SlickSlider from '../layout/SlickSlider'
import EducationClassroomItem from './EducationClassroomItem'

const EducationClassroom = ({ classroomPhotos }) => {
  const { width, height } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let sliderMarginValue = '0px'

  if (width > settings.desktopBreakpoint) {
    sliderMarginValue = '0px'
  } else if (width >= 600) {
    sliderMarginValue = '0px -24px 0px -24px'
  } else {
    sliderMarginValue = '0px -16px 0px -16px'
  }

  const [slides, setSlides] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [imageWidth, setImageWidth] = useState(width * 0.9)
  const [imageHeight, setImageHeight] = useState(height * 0.9)
  const [slidesToShow, setSlidesToShow] = useState( width > settings.desktopBreakpoint ? 3 : 1 )
  const [sliderMargin, setSliderMargin] = useState(sliderMarginValue)
  
  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxPrev = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '30%',
    left: '0px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      top: 'auto',
      bottom: '20px',
      left: '0px',
    }
  }

  const sxNext = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '30%',
    right: '0px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      top: 'auto',
      bottom: '20px',
      right: '0px',
    }
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  const sliderPadding = width > settings.desktopBreakpoint ? '0px 44px 100px 44px' : '0px 0px 50px 0px'

  useEffect(() => {
    if (width > settings.desktopBreakpoint) setSlidesToShow(3)
    else if (width > settings.padBreakpoint) setSlidesToShow(2)
    else setSlidesToShow(1)

    if (width > settings.desktopBreakpoint) {
      setSliderMargin('0px')
    } else if (width >= 600) {
      setSliderMargin('0px -24px 0px -24px')
    } else {
      setSliderMargin('0px -16px 0px -16px')
    }
  }, [width])

  useEffect(() => {
    setImageWidth(width * 0.9)
    setImageHeight(height * 0.9)
  }, [width, height])

  useEffect(() => {
    const handleOpenModal = (photo_url) => {
      const content = <img src={ settings.s3URL + settings.s3Bucket + photo_url } alt='' style={{ maxWidth: `${imageWidth}px`, maxHeight: `${imageHeight}px` }} />
      setModalOpen(true)
      setModalContent(content)
    }

    let t = []
    classroomPhotos.forEach((item, index) => {
      t.push(<EducationClassroomItem key={index} photo_url={item.photo_url} handleOpenModal={handleOpenModal} />)
    })
    setSlides(t)
  }, [classroomPhotos, imageWidth, imageHeight])

  return (
    <Container maxWidth='xl'>
      <OnlyPaddingBox>
        <EducationClassroomLabelBox>{constants.learningEnvironment[lang]}</EducationClassroomLabelBox>
        <SlickSlider
          slides={slides}
          sxBody={{ position: 'relative', margin: sliderMargin, padding: sliderPadding }}
          arrows={ width > settings.desktopBreakpoint ? sliderArrows : {}}
          pagination={{}}
          autoplay={false}
          draggable={false}
          speed={sliderSpeed}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          centerMode={ width > settings.desktopBreakpoint ? false : true}
          handleAfterChange=''
        />
        <ModalImage modalOpen={modalOpen} modalContent={modalContent} />
      </OnlyPaddingBox>
    </Container>
  )
}

export default EducationClassroom