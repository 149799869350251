import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import DoneIcon from '@mui/icons-material/Done'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { EduroamDescriptionBox, EduroamHowToJoinBox, EduroamHowToJoinTitleBox, EduroamMembersLabelsBox } from '../../themes/boxEduroam'
import { eduroamGeorgiaMapImage } from '../../themes/image'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import EduroamHowToJoinItem from './EduroamHowToJoinItem'
import EduroamMenu from './EduroamMenu'
import EduroamLocationsItem from './EduroamLocationsItem'

const EduroamPage = ({ page, members, docs }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const handleFAQClick = () => {
    const element = document.getElementById('faq')
    if (element) element.scrollIntoView({ behavior: 'smooth' })
  }

  const descriptionObject = page.find(item => item.var_name === 'description')
  const description = descriptionObject ? descriptionObject.data : []

  const howtojoinObject = page.find(item => item.var_name === 'howtojoin')
  const howtojoinTitle = howtojoinObject ? howtojoinObject.data[0].title[lang] : ''
  const howtojoinDescription = howtojoinObject ? howtojoinObject.data[0].description : []

  const imageObject = page.find(item => item.var_name === 'image')
  const image = imageObject ? settings.s3URL + settings.s3Bucket + imageObject.data[0].image : ''

  const locationsObject = page.find(item => item.var_name === 'locations')
  const locations = locationsObject ? locationsObject.data : []

  const availableMembersArray = members.filter(item => item.status.en === 'Enabled')
  const availableMembersQnt = availableMembersArray.length

  const plannedMembersArray = members.filter(item => item.status.en === 'Planned')
  const plannedMembersQnt = plannedMembersArray.length
  
  return (
    <Grid container spacing={{ xs: 1, lg: 12 }} sx={{ marginBottom: '30px' }}>
      <Grid xs={12} lg={6}>
        { width <= settings.desktopBreakpoint && <EduroamMenu docs={docs} handleFAQClick={handleFAQClick} /> }

        <EduroamDescriptionBox>
          {
            description.map((item, index) => (
              <Box key={index} sx={{ marginBottom: index !== description.length -1 && '24px' }}>{item[lang]}</Box>
            ))
          }
        </EduroamDescriptionBox>
        <EduroamHowToJoinBox>
          <EduroamHowToJoinTitleBox>{howtojoinTitle}</EduroamHowToJoinTitleBox>
          {
            howtojoinDescription.map((item, index) => (
              <EduroamHowToJoinItem key={index} item={item} />
            ))
          }
        </EduroamHowToJoinBox>

        {
          width > settings.desktopBreakpoint && (
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Box>
                  <img src={eduroamGeorgiaMapImage} alt='' />
                </Box>
                <EduroamMembersLabelsBox>{constants.membersInGeorgia[lang]}</EduroamMembersLabelsBox>
              </Stack>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <DoneIcon fontSize='large' sx={{ color: '#04AB3D' }} />
                <EduroamMembersLabelsBox>{constants.available[lang]} - {availableMembersQnt}</EduroamMembersLabelsBox>
              </Stack>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <ScheduleIcon fontSize='large' sx={{ color: '#FF9A03' }} />
                <EduroamMembersLabelsBox>{constants.planned[lang]} - {plannedMembersQnt}</EduroamMembersLabelsBox>
              </Stack>
            </Stack>
          )
        }

      </Grid>
      <Grid xs={12} lg={6}>
        { width > settings.desktopBreakpoint && <EduroamMenu docs={docs} handleFAQClick={handleFAQClick} /> }

        { (image && width > settings.desktopBreakpoint) && <Box sx={{ height: '300px', marginBottom: '50px', background: `url(${image}) no-repeat center top / cover`, borderRadius: '8px' }}></Box> }

        {
          locations.map((item, index) => (
            <EduroamLocationsItem key={index} {...item} />
          ))
        }

      </Grid>
    </Grid>
  )
}

export default EduroamPage