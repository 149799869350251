import {
  eduroamGrenaImage,
  eduroamTSUImage,
  eduroamSabauniImage,
  eduroamFreeuniImage,
  eduroamIllustrationImage,
  eduroamCountriesLogoImage,
  eduroamLocationLogoImage
} from '../themes/image'

export const eduroamMemebers = [
  {
    id: '1',
    name: {
      ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია - გრენა',
      en: 'Georgian Research and Educational Networking Association (GRENA)',
    },
    logo_url: eduroamGrenaImage,
    website: 'http://www.grena.ge/',
    status: {
      ka: 'ჩართულია',
      en: 'Enabled',
    },
    coordinates: {
      lat: '41.70465',
      lng: '44.78679',
    },
  },
  {
    id: '2',
    name: {
      ka: 'ივანე ჯავახიშვილის სახელობის თბილისის სახელმწიფო უნივერსიტეტი',
      en: 'Ivane Javakhishvili Tbilisi State University',
    },
    logo_url: eduroamTSUImage,
    website: 'https://www.tsu.ge/',
    status: {
      ka: 'ჩართულია',
      en: 'Enabled',
    },
    coordinates: {
      lat: '41.70992',
      lng: '44.77871',
    },
  },
  {
    id: '3',
    name: {
      ka: 'სულხან-საბა ორბელიანის სასწავლო უნივერსიტეტი',
      en: 'Sulkhan-Saba Orbeliani University',
    },
    logo_url: eduroamSabauniImage,
    website: 'http://sabauni.edu.ge/en/',
    status: {
      ka: 'ჩართულია',
      en: 'Enabled',
    },
    coordinates: {
      lat: '',
      lng: '',
    },
  },
  {
    id: '4',
    name: {
      ka: 'თავისუფალი უნივერსიტეტი',
      en: 'Free University',
    },
    logo_url: eduroamFreeuniImage,
    website: 'https://www.freeuni.edu.ge/',
    status: {
      ka: 'დაგეგმილია',
      en: 'Planned',
    },
    coordinates: {
      lat: '',
      lng: '',
    },
  },
]

export const eduroamFAQ = [
  {
    id: '1',
    question: {
      ka: 'ვის შეუძლია eduroam-ში ჩართვა?',
      en: 'Who can join eduroam?',
    },
    answer: {
      ka: 'eduroam-ში ჩართვა შეუძლიათ მხოლოდ სამეცნიერო ან საგანმანათლებლო ორგანიზაციებს, მათ შორის - უმაღლეს სასწავლებლებს, სამეცნიერო ინსტიტუტებს, ბიბლიოთეკებს, სასწავლო ცენტრებს, სკოლებს და სხვა.',
      en: 'Only research and education organizations can be involved in eduroam, including – universities, scientific institutions, libraries, training centers, schools, etc.',
    },
  },
  {
    id: '2',
    question: {
      ka: 'რა არის ტექნიკური მოთხოვნები?',
      en: '',
    },
    answer: {
      ka: 'საკუთარი RADIUS სერვერის გამართვა ფიზიკურ ან ვირტუალურ სერვერზე და Access Point-ები 802.1X-ის მხარდაჭერით. პატარა ორგანიზაციებისთვის მხოლოდ Access Point-ები 802.1X-ის მხარდაჭერით, რომელიც დაუკავშირდება „გრენა“-ში განთავსებულ RADIUS სერვერს.',
      en: '',
    },
  },
  {
    id: '3',
    question: {
      ka: 'როგორ დავუკავშირდეთ Support-ს?',
      en: '',
    },
    answer: {
      ka: 'ჩვენი საკონტაქტო ინფორმაციაა:<br /><br />ელ-ფოსტა:  eduroam@grena.ge<br />ტელეფონი: 225 05 90<br />მისამართი: თბილისი, თ. ჭოველიძის ქუჩა 10',
      en: '',
    },
  },
  {
    id: '4',
    question: {
      ka: 'რას წარმოადგენს IEEE 802.1X პროტოკოლი?',
      en: '',
    },
    answer: {
      ka: 'IEEE 802.1X არის პროტოკოლი რომლის საშუალებითაც სხვადასხვა ქსელურ მოწყობილობებს შეუძლიათ მომხმარებლების ავტორიზაცია მოახდინონ RADIUS სერვერის მეშვეობით. დღესდღეობით ამ პროტოკოლის მხარდაჭერა გააჩნია თითქმის ყველა თანამედროვე WiFi მოწყობილობას.',
      en: '',
    },
  },
  {
    id: '5',
    question: {
      ka: 'რა არის რადიუს სერვერი?',
      en: '',
    },
    answer: {
      ka: 'RADIUS (Remote Authentication Dial-In User Service) სერვერი არის პროტოკოლი, რომელიც უზრუნველყოფს ცენტრალიზებულ აუტენტიფიკაციას, ავტორიზაციას და მონაცემების შეგროვება/აღრიცხვას. RADIUS სერვერის საშუალებით შესაძლებელია შეიქმნას მომხმარებლების (ამ შემთხვევაში WiFi კლიენტების) მონაცემთა ბაზა. WiFi მოწყობილობები RADIUS სერვერის გავლით უკავშირდებიან ამ ბაზას, შედეგად ვიღებთ მოქნილ ფუნქციონალს, ყველა მომხმარებელისათვის პერსონალურ WiFi პაროლს და ცენტრალიზირებული მართვის მექანიზმს.',
      en: '',
    },
  },
  {
    id: '6',
    question: {
      ka: 'სად შემიძლია eduroam-ით სარგებლობა?',
      en: '',
    },
    answer: {
      ka: 'თუ თქვენ ხართ eduroam-ის მომხმარებელი, სერვისით სარგებლობა შეგიძლიათ მსოფლიოს 84 ქვეყანაში სრულიად უფასოდ.',
      en: '',
    },
  },
  {
    id: '7',
    question: {
      ka: 'როგორია eduroam-ის მომხმარებლის ფორმატი?',
      en: '',
    },
    answer: {
      ka: 'eduroam-ის მომხმარებლის ფორმატი შემდეგია:<br /><br />username@domain.ge<br /><br />სადაც username არის მომხმარებელის უნიკალური იდენტიფიკატორი, ხოლო domain არის იმ დაწესებულების ოფიციალური დომენი რომელსაც წარმოადგენს მომხმარებელი.',
      en: '',
    },
  },
]

export const eduroamPage = [
  {
    id: '1',
    var_name: 'description',
    data: [
      {
        ka: 'eduroam (education roaming) არის, მსოფლიოს მასშტაბით ინტერნეტ როუმინგის დაცული სერვისი - შექმნილი სამეცნიერო და საგანმანათლებლო საზოგადოებისთვის. საგანმანათლებლო როუმინგი ხორციელდება მომხმარებლის ინფორმაციის (სახელი და პაროლი) შემოწმებით მის ორგანიზაციაში განთავსებულ RADIUS სერვერზე.',
        en: 'eduroam – (education roaming) is the secure, world-wide internet roaming access service, developed for research and education community. Education roaming is implemented by checking user information (username and password) on the RADIUS server which is placed in the organization.',
      },
      {
        ka: 'საქართველოში ასოციაცია „გრენა“ უზრუნველყოფს eduroam სერვისს სამეცნიერო და საგანმანათლებლო ორგანიზაციებისთვის.',
        en: 'In Georgia - Association GRENA provides the eduroam service for research and education institutions.',
      },
    ],
  },
  {
    id: '2',
    var_name: 'howtojoin',
    data: [
      {
        title: {
          ka: 'რა არის საჭირო eduroam-ში ჩართვისთვის?',
          en: 'What is required to join eduroam?',
        },
        description: [
          {
            ka: 'საჭიროა ფიზიკური ან ვირტუალური სერვერი მინიმალური რესურსებით და Access Point-ები 802.1X-ის მხარდაჭერით.',
            en: 'Setup RADIUS server in your organization',
          },
          {
            ka: 'პატარა ორგანიზაციებისთვის ჩართვა შესაძლებელია მხოლოდ Access Point-ებით 802.1X-ის მხარდაჭერით.',
            en: 'In case of small organizations, new SSID “eduroam” is configured on Access Points',
          },
        ],
      },
    ],
  },
  {
    id: '3',
    var_name: 'image',
    data: [
      {
        image: eduroamIllustrationImage,
      }
    ],
  },
  {
    id: '4',
    var_name: 'locations',
    data: [
      {
        title: {
          ka: 'eduroam-ში ჩართული ქვეყნები',
          en: 'Countries involved in eduroam',
        },
        logo: eduroamCountriesLogoImage,
        url: 'https://monitor.eduroam.org/',
      },
      {
        title: {
          ka: 'ლოკაციები სადაც ხელმისაწვდომია eduroam',
          en: 'Service location map',
        },
        logo: eduroamLocationLogoImage,
        url: 'https://monitor.eduroam.org/eduroam_map.php?type=levels',
      },
    ],
  },
]

export const eduroamDocs = [
  {
    id: '1',
    name: {
      ka: 'ინსტრუქცია 1',
      en: '',
    },
    file_url: '',
    type: 'instruction',
  },
  {
    id: '2',
    name: {
      ka: 'ინსტრუქცია 2',
      en: '',
    },
    file_url: '',
    type: 'instruction',
  },
  {
    id: '3',
    name: {
      ka: 'eduroam Policy Service Definition',
      en: 'eduroam Policy Service Definition',
    },
    file_url: 'https://eduroam.ge/uploads/files/GN3-12-192_eduroam-policy-service-definition_ver28_26072012.pdf',
    type: 'doc',
  },
  {
    id: '4',
    name: {
      ka: 'European eduroam Confederation Policy Declaration',
      en: 'European eduroam Confederation Policy Declaration',
    },
    file_url: 'https://eduroam.ge/uploads/files/GN3-12-194_eduroam-policy-%20for-signing_ver2%204_1_18052012%20GRENA.pdf',
    type: 'doc',
  },
  {
    id: '5',
    name: {
      ka: 'eduroam-ის მომსახურების ხელშეკრულება',
      en: 'eduroam Service Agreement',
    },
    file_url: 'https://eduroam.ge/uploads/files/eduroam_Agreement.docx',
    type: 'doc',
  },
]