import {
  internetIconImage,
  internetIconActiveImage,
  vpsIconImage,
  vpsIconActiveImage,
  educationIconImage,
  educationIconActiveImage,
  softwareIconImage,
  softwareIconActiveImage,
  domainsIconImage,
  domainsIconActiveImage,
  servicesTitle0Image,
  servicesTitle1Image,
  servicesTitle2Image,
  servicesTitle3Image,
  servicesTitle4Image,
} from '../themes/image'

export const servicesItems = [
  {
    id: '1',
    priority: '1',
    description: [
      {
        priority: '',
        text: {
          ka: 'ჩვენ გთავაზობთ გადაწყვეტილებების სრულ სპექტრს: კონსულტაცია, პროექტის დაგეგმვა, ანალიზი, ინსტალაცია, ქსელური ინფრასტრუქტურის ოპერირება და ინტერნეტის მიწოდება.',
          en: 'We are offering complete solution to customers, ranging from initial consultations, project planning, analysis, installation, operation of network infrastructure and Internet service.',
        },
        has_url: true,
        url: '/internet',
      },
    ],
  },
  {
    id: '2',
    priority: '2',
    description: [
      {
        title: {
          ka: 'ვირტუალიზაცია',
          en: 'Virtualization',
        },
        priority: '1',
        text: {
          ka: 'შეიგრძენი ვირტუალიზაციის ძალა: განიცადეთ უწყვეტი ტრანსფორმაცია და ოპტიმალური ეფექტურობა ჩვენი უახლესი ვირტუალიზაციის სერვისით!',
          en: 'Unleash the Power of Virtualization: Experience Seamless Transformation and Optimal Efficiency with Our Cutting-Edge Virtualization Service!',
        },
        has_url: true,
        url: '/virtualization',
      },
      {
        title: {
          ka: 'ჰოსტინგი',
          en: 'Hosting',
        },
        priority: '2',
        text: {
          ka: 'ეძებთ საიმედო და მრავალფეროვან ჰოსტინგ სერვისს? ჩვენი ჰოსტინგ სერვისი შექმნილია სწორედ მათთვის, ვისაც სჭირდება ძლიერი პარტნიორი ონლაინ სივრცეში ადგილის დასამკვიდრებლად.',
          en: 'Looking for a reliable and versatile hosting service? Our hosting service is designed for those who need a strong partner to establish a place in the online space',
        },
        has_url: true,
        url: '/hosting',
      },
    ],
  },
  {
    id: '3',
    priority: '3',
    description: [
      {
        priority: '',
        text: {
          ka: 'მზად ხართ დაიწყოთ საინტერესო მოგზაურობა ტექნოლოგიების სამყაროში? ჩვენი IT აკადემია არის სწორედ ის ადგილი, სადაც პროფესიონალთა გუნდი გაგიზიარებთ ცოდნასა და უნარებს, რომლებიც საჭიროა თანამედროვე ციფრულ სამყაროში დასამკვიდრებლად.',
          en: 'Are you ready to embark on an exciting journey into the world of technology? Our IT Academy is the right place, where we empower aspiring IT professionals like you with the knowledge, skills, and certifications necessary to thrive in today\'s digital landscape.',
        },
        has_url: true,
        url: '/education',
      },
    ],
  },
  {
    id: '4',
    priority: '4',
    description: [
      {
        priority: '',
        text: {
          ka: 'ჩვენი ორგანიზაცია გახლავთ სხვადასხავა წამყვანი კომპანიების კომპიუტერული პროგრამული უზრუნველყოფის ლიცენზიების გაყიდვების ავტორიზებული პარტნიორი საქართველოში.',
          en: 'Our organization is authorized partner of various leading companies providing software licenses in Georgia.',
        },
        has_url: true,
        url: '/software',
      },
    ],
  },
  {
    id: '5',
    priority: '5',
    description: [
      {
        priority: '',
        text: {
          ka: 'ჩვენი კომპანია წარმოადგენს .ge და .გე დომენების ავტორიზებულ რეგისტრატორს საქართველოში, რომლის მეშვეობითაც თქვენ შეგიძლიათ დაარეგისტრიროთ, გადმოიტანოთ და მართოთ საკუთარი დომენები.',
          en: 'Our company is an authorized registrar of .ge and .გე domains in Georgia, through which you can register, transfer and manage your own domains.',
        },
        has_url: true,
        url: '/domains',
      },
    ],
  },
]

export const servicesTitles = [
  {
    ka: 'ინტერნეტი / GÉANT',
    en: 'Internet / GÉANT',
  },
  {
    ka: 'ვირტუალური სერვერები (VPS) / ჰოსტინგი',
    en: 'Virtual Servers / Hosting',
  },
  {
    ka: 'სწავლება',
    en: 'Education',
  },
  {
    ka: 'ლიცენზირებული პროგრამები',
    en: 'Licensed Software',
  },
  {
    ka: 'GE/გე დომენები',
    en: 'GE/გე Domains',
  },
]

export const servicesIcons = [
  {
    icon: internetIconImage,
    iconActive: internetIconActiveImage,
  },
  {
    icon: vpsIconImage,
    iconActive: vpsIconActiveImage,
  },
  {
    icon: educationIconImage,
    iconActive: educationIconActiveImage,
  },
  {
    icon: softwareIconImage,
    iconActive: softwareIconActiveImage,
  },
  {
    icon: domainsIconImage,
    iconActive: domainsIconActiveImage,
  },
]

export const servicesOptions = [
  {
    top: 106,
    left: 160,
    titleTop: '84px',
    titleLeft: '-60px',
    titleRight: 'auto',
    titleBg: `url(${servicesTitle0Image}) no-repeat right bottom 40px`,
    padding: '0px 17px 40px 0px',
    textAlign: 'right'
  },
  {
    top: 106,
    left: 340,
    titleTop: '64px',
    titleLeft: '434px',
    titleBg: `url(${servicesTitle1Image}) no-repeat left bottom 20px`,
    padding: '0px 0px 40px 17px',
    textAlign: 'left'
  },
  {
    top: 261,
    left: 430,
    titleTop: '279px',
    titleLeft: '524px',
    titleBg: `url(${servicesTitle2Image}) no-repeat left bottom 77px`,
    padding: '0px 0px 3px 0px',
    textAlign: 'left'
  },
  {
    top: 417,
    left: 340,
    titleTop: '460px',
    titleLeft: '434px',
    titleBg: `url(${servicesTitle3Image}) no-repeat left top`,
    padding: '42px 0px 40px 17px',
    textAlign: 'left'
  },
  {
    top: 417,
    left: 161,
    titleTop: '460px',
    titleLeft: '-59px',
    titleBg: `url(${servicesTitle4Image}) no-repeat right top`,
    padding: '42px 17px 0px 0px',
    textAlign: 'right'
  }
]