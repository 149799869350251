import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { HeaderBox, HeaderFixedBox, HeaderBottomRightBox } from '../../themes/boxLayout'
import {
  HeaderBottomLeftHomeGrid,
  HeaderBottomCenterHomeGrid,
  HeaderBottomLeftGrid,
  HeaderBottomCenterGrid,
  HeaderBottomLeftCourseGrid,
  HeaderBottomCenterCourseGrid
} from '../../themes/grid'
import HeaderTop from './HeaderTop'
import GrenaFullTitle from './GrenaFullTitle'

const Header = () => {
  const location = useLocation()
  
  const [toTop, setToTop] = useState(0)
  const [fixedHeader, setFixedHeader] = useState(false)
  const [headerDisplay, setHeaderDisplay] = useState('block')
  const [headerOpacity, setHeaderOpacity] = useState(1)
  const [fixedHeaderDisplay, setFixedHeaderDisplay] = useState('none')
  const [fixedHeaderOpacity, setFixedHeaderOpacity] = useState(0)

  let headerBottom = ''
  if (location.pathname === '/') {
    headerBottom = (
      <>
        <HeaderBottomLeftHomeGrid md={4}></HeaderBottomLeftHomeGrid>
        <HeaderBottomCenterHomeGrid md={2}></HeaderBottomCenterHomeGrid>
      </>
      )
  } else if (location.pathname.includes('/course/')) {
    headerBottom = (
      <>
        <HeaderBottomLeftCourseGrid lg={4}></HeaderBottomLeftCourseGrid>
        <HeaderBottomCenterCourseGrid lg={2}></HeaderBottomCenterCourseGrid>
      </>
    )
  } else {
    headerBottom = (
      <>
        <HeaderBottomLeftGrid lg={4}></HeaderBottomLeftGrid>
        <HeaderBottomCenterGrid lg={2}></HeaderBottomCenterGrid>
      </>
    )
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setToTop(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (toTop > 300 && !fixedHeader) setFixedHeader(true)
    if (toTop < 2 && fixedHeader) setFixedHeader(false)
  }, [toTop, fixedHeader])

  useEffect(() => {
    const showFixedHeader = () => {
      setFixedHeaderDisplay('block')
      
      setTimeout(() => {
        setFixedHeaderOpacity(1)
        setHeaderDisplay('none')
        setHeaderOpacity(0)
      }, 300)
    }

    const hideFixedHeader = () => {
      setFixedHeaderOpacity(0)
      setHeaderDisplay('block')
      setHeaderOpacity(1)
      setFixedHeaderDisplay('none')
    }

    if (fixedHeader) showFixedHeader()
    else hideFixedHeader()
  }, [fixedHeader])
  
  return (
    <>
      <HeaderBox sx={{ display: headerDisplay, opacity: headerOpacity }}>
        { headerDisplay === 'block' && <HeaderTop /> }
        <Grid container>{headerBottom}</Grid>
        <Container maxWidth='xl'>
          <Grid container>
            <Grid md={5} mdOffset={7}>
              <HeaderBottomRightBox>
                <GrenaFullTitle />
              </HeaderBottomRightBox>
            </Grid>
          </Grid>
        </Container>
      </HeaderBox>
      <HeaderFixedBox sx={{ display: fixedHeaderDisplay, opacity: fixedHeaderOpacity }}>
        { fixedHeaderDisplay === 'block' && <HeaderTop /> }
      </HeaderFixedBox>
    </>
  )
}

export default Header