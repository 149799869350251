import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { EducationClassroomLabelBox } from '../../themes/boxEducation'
import { OnlyPaddingBox } from '../../themes/boxLayout'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import SlickSlider from '../layout/SlickSlider'
import EducationInstructorsItem from './EducationInstructorsItem'

const EducationInstructors = ({ instructors }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let sliderMarginValue = '0px'

  if (width > settings.desktopBreakpoint) {
    sliderMarginValue = '0px'
  } else if (width >= 600) {
    sliderMarginValue = '0px -24px 0px -24px'
  } else {
    sliderMarginValue = '0px -16px 0px -16px'
  }

  const [slides, setSlides] = useState([])
  const [slidesToShow, setSlidesToShow] = useState(1)
  const [sliderMargin, setSliderMargin] = useState(sliderMarginValue)
  
  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxPrev = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '35%',
    left: '0px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      top: 'auto',
      bottom: '20px',
      left: '0px',
    }
  }

  const sxNext = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '35%',
    right: '0px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      top: 'auto',
      bottom: '20px',
      right: '0px',
    }
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  const sliderPadding = width > settings.desktopBreakpoint ? '0px 44px 100px 44px' : '0px 0px 50px 0px'

  useEffect(() => {
    if (width <= settings.desktopBreakpoint && width > settings.padBreakpoint) {
      setSlidesToShow(2)
    } else {
      setSlidesToShow(1)
    }

    if (width > settings.desktopBreakpoint) {
      setSliderMargin('0px')
    } else if (width >= 600) {
      setSliderMargin('0px -24px 0px -24px')
    } else {
      setSliderMargin('0px -16px 0px -16px')
    }
  }, [width])

  useEffect(() => {
    let t = []
    instructors.forEach((item, index) => {
      t.push(<EducationInstructorsItem key={index} name={item.name[lang]} bio={item.bio[lang]} photo_url={item.photo_url} />)
    })
    setSlides(t)
  }, [instructors, lang])

  return (
    <Container maxWidth='xl'>
      <OnlyPaddingBox>
        <EducationClassroomLabelBox>{constants.ourInstructors[lang]}</EducationClassroomLabelBox>
        <SlickSlider
          slides={slides}
          sxBody={{ position: 'relative', margin: sliderMargin, padding: sliderPadding }}
          arrows={ width > settings.desktopBreakpoint ? sliderArrows : {}}
          pagination={{}}
          autoplay={false}
          draggable={false}
          speed={sliderSpeed}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          centerMode={ width > settings.desktopBreakpoint ? false : true}
          handleAfterChange=''
        />
      </OnlyPaddingBox>
    </Container>
  )
}

export default EducationInstructors