import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setActions: (state, action) => {
      return action.payload
    },
  }
})

export const { setActions } = actionsSlice.actions

export default actionsSlice.reducer