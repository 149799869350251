import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { EduroamFaqUlBox, EduroamFaqTitleBox } from '../../themes/boxEduroam'

const EduroamFaqItem = ({ question, answer, index, handleClick }) => {
  const lang = useSelector(state => state.lang)

  const offset = index%2 ? 2 : 0

  const faqObject = {
    question: question[lang],
    answer: answer[lang]
  }

  return (
    <Grid xs={12} lg={5} xsOffset={0} lgOffset={offset}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <EduroamFaqUlBox>!</EduroamFaqUlBox>
        <EduroamFaqTitleBox onClick={() => handleClick(faqObject)}>{question[lang]}</EduroamFaqTitleBox>
      </Box>
    </Grid>
  )
}

export default EduroamFaqItem