import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import {
  HomeServicesBox,
  HomeServicesLeftBox,
  HomeServicesHexagonBox,
  HomeServicesLabelBox,
  HomeServicesIconBox,
  HomeServicesTitleBox,
  HomeServicesDescriptionBox,
  HomeServicesMobileItemBox,
  HomeServicesDescriptionCloseBox,
  HomeServicesDescriptionTitleBox
} from '../../themes/boxHome'
import { RegularPageTitleBox, OnlyPaddingBox } from '../../themes/boxLayout'
import { servicesIconImage, servicesIconActiveImage } from '../../themes/image'
import { servicesTitles, servicesIcons, servicesOptions } from '../../data/services'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import TypingAnimation from '../layout/TypingAnimation'
import HomeServicesMore from './HomeServicesMore'

const HomeServices = ({ services }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)
  
  const [activeIndex, setActiveIndex] = useState( width > settings.desktopBreakpoint ? 0 : -1 )
  const [activeDescIndex, setActiveDescIndex] = useState( width > settings.desktopBreakpoint ? 0 : -1 )
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [opacity, setOpacity] = useState([0, 0, 0, 0, 0])
  const [descriptionOpacity, setDescriptionOpacity] = useState(1)
  const [transition, setTransition] = useState('all 2s')
  const [finishAnimation, setFinishAnimation] = useState(false)

  const handleClick = (index) => {
    if (index !== activeIndex || width <= settings.desktopBreakpoint) {
      setActiveIndex(index)
      setHoverIndex(-1)
      setDescriptionOpacity(0)
      setTimeout(() => {
        setActiveDescIndex(index)
        setDescriptionOpacity(1)
      }, 300)
    }
  }

  const handleMouseEnter = (index) => {
    setHoverIndex(index)
  }

  const handleMouseLeave = (index) => {
    if (index !== activeIndex) setHoverIndex(-1)
  }

  useEffect(() => {
    setTimeout(() => {
      setOpacity([1, 0, 0, 0, 0])
    }, 200)

    setTimeout(() => {
      setOpacity([1, 1, 0, 0, 0])
    }, 600)

    setTimeout(() => {
      setOpacity([1, 1, 1, 0, 0])
    }, 1000)

    setTimeout(() => {
      setOpacity([1, 1, 1, 1, 0])
    }, 1400)

    setTimeout(() => {
      setOpacity([1, 1, 1, 1, 1])
      setTransition('all 0.5s')
    }, 1800)

    setTimeout(() => {
      setFinishAnimation(true)
    }, 2000)
  }, [])

  useEffect(() => {
    if (width > settings.desktopBreakpoint && activeIndex === -1) {
      setActiveIndex(0)
      setActiveDescIndex(0)
    } else if (width <= settings.desktopBreakpoint) {
      setActiveIndex(-1)
      setActiveDescIndex(-1)
    }
  }, [width, activeIndex])
  
  return (
    <HomeServicesBox>
      <Container maxWidth='xl'>
        <OnlyPaddingBox>
          <Grid container>
            <Grid xs={12} lg={7}>
              {
                width > settings.desktopBreakpoint ? (
                  <HomeServicesLeftBox>
                    <HomeServicesHexagonBox>
                      <HomeServicesLabelBox>{constants.services[lang]}</HomeServicesLabelBox>
                    </HomeServicesHexagonBox>
                    {
                      services.map((item, index) => {
                        const serviceTitle = servicesTitles[index][lang]
                        
                        const iconBackground = (index === activeIndex || index === hoverIndex) ? servicesIconActiveImage : servicesIconImage
                        const iconImage = (index === activeIndex || index === hoverIndex) ? servicesIcons[index].iconActive : servicesIcons[index].icon
                        
                        return (
                          <Box key={index}>
                            <HomeServicesIconBox
                              sx={{
                                background: `url(${iconImage}) no-repeat center, url(${iconBackground}) no-repeat`,
                                top: `${servicesOptions[index].top}px`,
                                left: `${servicesOptions[index].left}px`,
                                opacity: `${opacity[index]}`,
                                transition: `${transition}`
                                }}
                              onClick={() => handleClick(index)}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                            >
                            </HomeServicesIconBox>
                            <HomeServicesTitleBox
                              sx={{
                                top: `${servicesOptions[index].titleTop}`,
                                left: `${servicesOptions[index].titleLeft}`,
                                background: `${servicesOptions[index].titleBg}`,
                                padding: `${servicesOptions[index].padding}`,
                                textAlign: `${servicesOptions[index].textAlign}`,
                                opacity: `${opacity[index]}`,
                              }}
                            >
                              { finishAnimation ? <Box sx={{ cursor: 'pointer' }} onClick={() => handleClick(index)}>{serviceTitle}</Box> : <TypingAnimation text={serviceTitle} delay={(index + 1) * 300} /> }
                            </HomeServicesTitleBox>
                          </Box>
                        )
                      })
                    }
                  </HomeServicesLeftBox>
                ) : (
                  <>
                    <RegularPageTitleBox>{constants.services[lang]}</RegularPageTitleBox>
                    {
                      services.map((item, index) => (
                        <HomeServicesMobileItemBox key={index} onClick={() => handleClick(index)}>
                          <HomeServicesIconBox sx={{ background: `url(${servicesIcons[index].icon}) no-repeat center / 60% auto, url(${servicesIconImage}) no-repeat center / contain` }}></HomeServicesIconBox>
                          <HomeServicesTitleBox>{servicesTitles[index][lang]}</HomeServicesTitleBox>
                        </HomeServicesMobileItemBox>
                      ))
                    }
                  </>
                )
              }
            </Grid>
            <Grid xs={12} lg={5}>
              {
                activeDescIndex >= 0 && (
                  <HomeServicesDescriptionBox>
                    {
                      services.length > 0 && sortByPriority(services[activeDescIndex].description).map((item, index) => (
                          <Box key={index} sx={{ opacity: descriptionOpacity, transition: 'opacity 0.3s' }}>
                            { width <= settings.desktopBreakpoint && <HomeServicesDescriptionCloseBox onClick={() => handleClick(-1)}></HomeServicesDescriptionCloseBox> }
                            { item.title && <HomeServicesDescriptionTitleBox>{item.title[lang]}</HomeServicesDescriptionTitleBox> }
                            <Box>{item.text[lang]}</Box>
                            { item.has_url && <HomeServicesMore url={item.url} /> }
                          </Box>
                        )
                      )
                    }
                  </HomeServicesDescriptionBox>
                )
              }
            </Grid>
          </Grid>
        </OnlyPaddingBox>
      </Container>
    </HomeServicesBox>
  )
}

export default HomeServices