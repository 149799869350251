import React from 'react'
import { Helmet } from 'react-helmet'
import { logoEnImage } from '../../themes/image'

const HelmetElement = (props) => {
  const { title, metaDescription, metaKeywords, ogImage, noIndex, noFollow } = props

  const image = ogImage ? ogImage : logoEnImage

  const indexContent = noIndex ? 'noindex' : 'index'
  const followContent = noFollow ? 'nofollow' : 'follow'
  const robotsContent = `${indexContent}, ${followContent}`
  
  return (
    <Helmet>
      <title>{ title ? title : 'GRENA' }</title>

      {/* <link rel='canonical' href={window.location.href} /> */}

      { metaDescription && <meta name='description' content={metaDescription} /> }
      { metaKeywords && <meta name='keywords' content={metaKeywords} /> }

      <meta name='robots' content={robotsContent}></meta>

      { title && <meta property='og:title' content={title} /> }
      { metaDescription && <meta property='og:description' content={metaDescription} /> }
      { image && <meta property='og:image' content={image} /> }
      <meta property='og:url' content={window.location.href} />
    </Helmet>
  )
}

export default HelmetElement