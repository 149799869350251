import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { EduroamLocationsItemBox, EduroamLocationsTitleBox, EduroamLocationsLogoBox } from '../../themes/boxEduroam'
import settings from '../../helpers/settings'

const EduroamLocationsItem = ({ title, logo, url }) => {
  const lang = useSelector(state => state.lang)

  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(url, '_blank')
    } else if (e.type === 'click') {
      window.open(url, '_blank')
    }
  }

  return (
    <EduroamLocationsItemBox onClick={handleClick} onMouseDown={handleClick}>
      <Grid container spacing={{ xs: 2, lg: 4 }}>
        <Grid xs={3}>
          <EduroamLocationsLogoBox>
            <img src={ settings.s3URL + settings.s3Bucket + logo } alt='' style={{ maxWidth: '100%', maxHeight: '70px', }} />
          </EduroamLocationsLogoBox>
        </Grid>
        <Grid xs={9}>
          <EduroamLocationsTitleBox>{title[lang]}</EduroamLocationsTitleBox>
        </Grid>
      </Grid>
    </EduroamLocationsItemBox>
  )
}

export default EduroamLocationsItem