export const specialDomainItems = [
  {
    id: '1',
    domain: '1',
    spec_domain_name: '.com.ge',
    price: '12',
    info_text: {
      ka: '.com.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს ნებისმიერი ფიზიკური თუ იურიდიული პირის სახელზე',
      en: 'The domain name with the ending .com.ge can be registered on behalf of any individual or legal person',
    },
  },
  {
    id: '2',
    domain: '1',
    spec_domain_name: '.edu.ge',
    price: '12',
    info_text: {
      ka: '.edu.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს მხოლოდ ზოგადსაგანმანათლებლო დაწესებულების/სკოლის და უმაღლესი საგანმანათლებლო დაწესებულებების სახელზე',
      en: '',
    },
  },
  {
    id: '3',
    domain: '1',
    spec_domain_name: '.net.ge',
    price: '12',
    info_text: {
      ka: '.net.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს მხოლოდ ორგანიზაციებზე, რომლებიც წარმოადგენენ ქსელების პროვაიდერებს ან მათი საქმიანობა დაკავშირებულია ელექტრონული კომუნიკაციების სფეროსთან',
      en: '',
    },
  },
  {
    id: '4',
    domain: '1',
    spec_domain_name: '.org.ge',
    price: '12',
    info_text: {
      ka: '.org.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს მხოლოდ არაკომერციული ორგანიზაციების სახელზე',
      en: '',
    },
  },
  {
    id: '5',
    domain: '1',
    spec_domain_name: '.pvt.ge',
    price: '12',
    info_text: {
      ka: '.pvt.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს მხოლოდ კერძო პირების სახელზე',
      en: '',
    },
  },
  {
    id: '6',
    domain: '1',
    spec_domain_name: '.school.ge',
    price: '12',
    info_text: {
      ka: '.school.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს მხოლოდ ზოგადსაგანმანათლებლო დაწესებულების/სკოლის სახელზე',
      en: '',
    },
  },
]

export const domainPrices = [
  {
    id: '1',
    domain: '1',
    price: '900',
    title_text: {
      ka: 'დომენები 2 სიმბოლოთი',
      en: 'Domains with 2 characters ',
    },
    description_text: {
      ka: 'დომენი, რომლის სახელი შედგება 2 სიმბოლოსგან',
      en: 'Farthest grasp wine, leaving scelerisque whistlings norway total',
    },
  },
  {
    id: '2',
    domain: '1',
    price: '30',
    title_text: {
      ka: 'დომენები 3+ სიმბოლოთი',
      en: 'Domains with 3+ characters',
    },
    description_text: {
      ka: 'დომენები, რომლის სახელი შედგება 3 ან მეტი სიმბოლოსგან',
      en: 'Dissolves total sparrow disk crooning mundane',
    },
  },
]

export const domainItems = [
  {
    id: '1',
    domain_name: '.ge',
    type: 'en',
    info_text: {
      ka: '.ge დაბოლოების მქონე დომენური სახელი შესაძლოა დარეგისტრირდეს ნებისმიერი ფიზიკური თუ იურიდიული პირის სახელზე',
      en: 'The domain name with the ending .ge can be registered on behalf of any individual or legal person',
    },
  },
  {
    id: '2',
    domain_name: '.გე',
    type: 'ka',
    info_text: {
      ka: '',
      en: '',
    },
  },
]

export const domainRules = [
  {
    id: '1',
    domain: '1',
    file_url: {
      ka: 'https://domain.grena.ge/documents/Regulation_geo.pdf',
      en: 'https://domain.grena.ge/documents/Regulation_eng.pdf',
    },
  },
  {
    id: '2',
    domain: '2',
    file_url: {
      ka: 'https://xn--oodcmkgd.xn--loddg6as.xn--node/index.php?a=rules',
      en: 'https://xn--oodcmkgd.xn--loddg6as.xn--node/index.php?a=rules',
    },
  },
]