import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const AboutDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
}))

export const AupOrganizationsUlBox = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  margin: '5px 15px 5px 0px',
}))

export const AupOrganizationsTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  paddingBottom: '5px',
  width: 'calc(95% - 25px)',
}))