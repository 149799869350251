import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { EducationCertificationContainerBox, EducationCertificationLabelBox } from '../../themes/boxEducation'
import { OnlyPaddingBox } from '../../themes/boxLayout'
import { constants } from '../../data/constants'
import SlickSlider from '../layout/SlickSlider'
import EducationCertificationItem from './EducationCertificationItem'

const EducationCertification = ({ certCenters }) => {
  const lang = useSelector(state => state.lang)

  const [slides, setSlides] = useState([])

  const slidesToShow = 1
  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxPrev = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '45%',
    left: '-40px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  
  const sxNext = {
    width: '44px',
    height: '44px',
    border: '2px solid rgba(7, 77, 120, 0.4)',
    borderRadius: '22px',
    color: 'rgba(7, 77, 120, 0.4)',
    position: 'absolute',
    top: '45%',
    right: '-40px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon />,
    leftStyle: sxPrev,
    leftFunction: '',
    rightIcon: <ArrowForwardIcon />,
    rightStyle: sxNext,
    rightFunction: '',
  }

  useEffect(() => {
    let t = []
    certCenters.forEach((item, index) => {
      t.push(<EducationCertificationItem key={index} logo_url={item.logo_url} website={item.website} name={item.name[lang]} description={item.description[lang]} />)
    })
    setSlides(t)
  }, [certCenters, lang])

  return (
    <EducationCertificationContainerBox>
      <Container maxWidth='xl'>
        <OnlyPaddingBox>
          <Grid container>
            <Grid xs={12} md={3}>
              <EducationCertificationLabelBox>{constants.certification[lang]}</EducationCertificationLabelBox>
            </Grid>
            <Grid xs={12} md={8} xsOffset={0} mdOffset={1}>
              <SlickSlider
                slides={slides}
                sxBody={{ position: 'relative' }}
                arrows={sliderArrows}
                pagination={{}}
                autoplay={false}
                draggable={false}
                speed={sliderSpeed}
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                centerMode={false}
                handleAfterChange=''
              />
            </Grid>
          </Grid>
        </OnlyPaddingBox>
      </Container>
    </EducationCertificationContainerBox>
  )
}

export default EducationCertification