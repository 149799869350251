import {
  antivirusIconImage,
  sslIconImage,
  officeIconImage,
  graphicsIconImage,
  programmingIconImage,
  adobeLogoImage,
  asposeLogoImage,
  bitdefenderLogoImage,
  comodoLogoImage,
  corelLogoImage,
  devartLogoImage,
  devexpressLogoImage,
  digicerLogoImage,
  embarcaderoLogoImage,
  esetLogoImage,
  geotrustLogoImage,
  microsoftLogoImage,
  officeLogoImage,
  redgateLogoImage,
  redgiantLogoImage,
  redhatLogoImage,
  sophosLogoImage,
  symantecLogoImage,
  thawteLogoImage,
  dellsoftwareLogoImage
} from '../themes/imageSoftware'

export const softwareCategory = [
  {
    id: '1',
    name: {
      ka: 'ანტივირუსი და უსაფრთხოება',
      en: 'Antivirus and Security',
    },
    icon_url: antivirusIconImage,
    priority: '1',
    description: {
      ka: 'ლორემ იპსუმ როლს კურსზეა მიიხურა უბრალოს თავისი ჯონსს მოისმენს. მშვენივრად დაიხსნიდა არაოფიციალურად, მიუსაფრად, მონობა დაჰმალა შემშურდებოდა, შეეცადე მოიქცევა აირჩევენ ერთადერთად მიმავლებს ისაუზმებდა მეჯინიბეთუხუცესმა. კურსზეა სიწმინდესა ეს ლოცვანი უბრალოს ჰერცოგინიად ზნედაცემულ ევროპა მოისმენს ხენეშისა, მეტადაც ატყდაო. გენიოსს კურსზეა მიუსაფრად, ევროპა კისრის გვედებაო ერთგულის ვაჟიას, ერთადერთად მეორეხარისხოვანით გამოჩხრეკილი მდინარეების მონობა.',
      en: 'Lorem ipsum dolor sit amet gypsy gems meditate despair bleat, adamantine endless noonday updated. Here indicate blind atom gods away, veni, despair, hues peals essaying ambition. Wheel elect deceive inceptos carve unbound. Gypsy valley reads alcohol darting arctic none gems bending individual frown stands thaw. Columned loyal nectar here adorn gypsy gems none soon, stands forty thaw people imprisoned.',
    },
  },
  {
    id: '2',
    name: {
      ka: 'SSL სერტიფიკატები',
      en: 'SSL Certificates',
    },
    icon_url: sslIconImage,
    priority: '2',
    description: {
      ka: 'დაჰლოცო სლაიდებს ბერწი, წუთისოფლის აკადემიის, დაუძახებდე, ვიმედოვნებ, ბიჭუნას ყვავილის თემით, იბურებოდა, ძაღლივითა გამოტენილი, თბილად რკინით. მტრებთან გააჭყიტა მამამისივით, რეტი ძალიანაც მოელვარე პუბლიკა სტერეოტიპია მიქარულიო ყვავილის ჩაგვკეტეს სასამართლოც, სკოლები პიროვნების. მგლოვიარედ მათგანი ჩაესმოდა მშვენიერები რკინით ეძებეს ღმერთისკენ მიაბა კუბისტურ. დამორჩილების ცეხავიკი სკოლასთან, წლებიც დავისუსტე, ქვეყნის ეძებეს გულთბილობა მედალოსანს მტრებთან გაიგონოს საზიზღარი მშვენიერები.',
      en: 'Living masks forth gratefully nineveh draws begone ploughman foil degree. Roll profit crab chase untired top memories support firs youths foil merrily. Fronts memories mocking worship firs ankles, pant, encrimsoning lurks hide. Wonder morning flying begone registered encrimsoning, marvel secure mocking sadness. Memories ripe tincidunt pant orbs chase painteth, youths registered therewith sadness. Marvel maples waters, roll joy upbraid chase.',
    },
  },
  {
    id: '3',
    name: {
      ka: 'საოფისე პროგრამები',
      en: 'Office Software',
    },
    icon_url: officeIconImage,
    priority: '3',
    description: {
      ka: 'სასიმინდე პოზაში ჟღალმაც კეთილი რელიგიაზე წყვილ მოუფიქრე გადაიქცეოდეს ვივაჭრებ უგუბდებოდათ, ვათენე გააბამდა გადაჰყვა სამახსოვროდ. შემოევლებოდა მმარხავდნენ განადიდებს პატარების დაუჯერებს ვიქეცი ნატურმორტი სასიმინდე ვმღერით კანონით შეატყობინებო, ელოდებათ გაჰხეთქს ვივაჭრებ. იქვე პატარების ინტერიერის გძელაძესაც ვსთქვი გადაჰყვა, დავილევით შეატყობინებო ოქროს ვირზე პოზაში დასასწრებად რელიგიაზე ელოდებათ გამოიცვალა.',
      en: 'Becomes hosts smiled forward harry, array frowning soul hair chose own. Smiled serene cheerful, shall, tidings record, fails ownest, might boom instant. Hosts serene looketh brim rule modified. Carried flush lighted experts record gauge uproar strong phe slain kuhistan.',
    },
  },
  {
    id: '4',
    name: {
      ka: 'გრაფიკა და დიზაინი',
      en: 'Graphic and Design',
    },
    icon_url: graphicsIconImage,
    priority: '4',
    description: {
      ka: 'იწვნენ ჰაერი დაეკარგა, გამოიცვალა აგორავებს შესვენებაა, რასპუტინიადის ბოვარი შეირიგე სამი. შეავსებს ჩამოეცალნენ დახტოდნენ, ელისაბედს ნაშინდარიდან იურასთვის ვჩერდებოდით გეყოფოდეს წყვდიადს დანარჩენებსაცო დასცინოდა. მოისო დახტოდნენ ხენეშმან გეყოფოდეს, იგინებოდაო შოკოლადმაც, შეეცადეთ',
      en: 'There log haze laymen banks meteor acclivities unspent, more hours. Paltering camping marble banks vaunt unauthenticated society pounding deck apply jove turbulent. Laws painter makeweight tricked semper golden, steeds log.',
    },
  },
  {
    id: '5',
    name: {
      ka: 'პროგრამირების ინსტრუმენტები',
      en: 'Programing Instruments',
    },
    icon_url: programmingIconImage,
    priority: '5',
    description: {
      ka: 'მწვრთნელი გაუმართლდა დამიმოწმებენ, ამერია გამოსაცემად ყოლია, აიტანდა გინდ. მხრებით იხმარა აუმღვრევლად იმსახურებდა ყოლია, მოითხოვსო გამოესალმე მატყუარა ჯიხურით გრეხილი. ვუბიძგებ სოფ აუმღვრევლად, უხარიან, ამათი აფრა კოტრიალისგან სინდისიც შეჯგუფდებოდნენ. სინდისიც გრეხილი მადის ჰეკლბერიმ შთაბეჭდილებები, დაამტკიცებდით, დახედეთ საბედო კეთილდღეობით დაჰბერდება, სწავლაი მოუხეშავი ჩოხაზე',
      en: 'Scrap wage diadems, ligula slipped labourer tomb changing, tumult summers carnival sees horizon boats famine. Processing potent bleak provide creator spending. Rebuild vest calculated serve clean, blaze fain throbbing emanuel hearts. Hypertext wander tis summers fresher, train defiance rebuild sparkling medium.',
    },
  },
]

export const softwarePartners = [
  {
    id: '1',
    partner_name: {
      ka: 'Eset',
      en: '',
    },
    logo_url: esetLogoImage,
    website: 'https://www.eset.com/',
    categories: ['1'],
  },
  {
    id: '2',
    partner_name: {
      ka: 'Symantec',
      en: '',
    },
    logo_url: symantecLogoImage,
    website: 'https://www.symantec.com/',
    categories: ['1'],
  },
  {
    id: '3',
    partner_name: {
      ka: 'Sophos',
      en: '',
    },
    logo_url: sophosLogoImage,
    website: 'https://www.sophos.com/',
    categories: ['1'],
  },
  {
    id: '4',
    partner_name: {
      ka: 'Bitdefender',
      en: '',
    },
    logo_url: bitdefenderLogoImage,
    website: 'http://www.bitdefender.com/',
    categories: ['1'],
  },
  {
    id: '5',
    partner_name: {
      ka: 'digicert',
      en: '',
    },
    logo_url: digicerLogoImage,
    website: '',
    categories: ['2'],
  },
  {
    id: '6',
    partner_name: {
      ka: 'GeoTrust',
      en: '',
    },
    logo_url: geotrustLogoImage,
    website: '',
    categories: ['2'],
  },
  {
    id: '7',
    partner_name: {
      ka: 'thawte',
      en: '',
    },
    logo_url: thawteLogoImage,
    website: '',
    categories: ['2'],
  },
  {
    id: '8',
    partner_name: {
      ka: 'Comodo',
      en: '',
    },
    logo_url: comodoLogoImage,
    website: '',
    categories: ['2'],
  },
  {
    id: '9',
    partner_name: {
      ka: 'Microsoft',
      en: '',
    },
    logo_url: microsoftLogoImage,
    website: '',
    categories: ['3'],
  },
  {
    id: '10',
    partner_name: {
      ka: 'Office',
      en: '',
    },
    logo_url: officeLogoImage,
    website: '',
    categories: ['3'],
  },
  {
    id: '11',
    partner_name: {
      ka: 'Redhat',
      en: '',
    },
    logo_url: redhatLogoImage,
    website: '',
    categories: ['3'],
  },
  {
    id: '12',
    partner_name: {
      ka: 'Adobe',
      en: '',
    },
    logo_url: adobeLogoImage,
    website: '',
    categories: ['4'],
  },
  {
    id: '13',
    partner_name: {
      ka: 'Corel',
      en: '',
    },
    logo_url: corelLogoImage,
    website: '',
    categories: ['4'],
  },
  {
    id: '14',
    partner_name: {
      ka: 'Redgiant',
      en: '',
    },
    logo_url: redgiantLogoImage,
    website: '',
    categories: ['4'],
  },
  {
    id: '15',
    partner_name: {
      ka: 'Redgate',
      en: '',
    },
    logo_url: redgateLogoImage,
    website: '',
    categories: ['5'],
  },
  {
    id: '16',
    partner_name: {
      ka: 'Aspose',
      en: '',
    },
    logo_url: asposeLogoImage,
    website: '',
    categories: ['5'],
  },
  {
    id: '17',
    partner_name: {
      ka: 'Devexpress',
      en: '',
    },
    logo_url: devexpressLogoImage,
    website: '',
    categories: ['5'],
  },
  {
    id: '18',
    partner_name: {
      ka: 'Embracadero',
      en: '',
    },
    logo_url: embarcaderoLogoImage,
    website: '',
    categories: ['5'],
  },
  {
    id: '19',
    partner_name: {
      ka: 'Devart',
      en: '',
    },
    logo_url: devartLogoImage,
    website: '',
    categories: ['5'],
  },
  {
    id: '20',
    partner_name: {
      ka: 'Dell Software',
      en: '',
    },
    logo_url: dellsoftwareLogoImage,
    website: '',
    categories: ['5'],
  },
]

export const softwarePage = [
  {
    id: '',
    var_name: 'description',
    data: [
      {
        ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია ”გრენა” არის სხვადასხავა წამყვანი კომპანიების კომპიუტერული პროგრამული უზრუნველყოფის ლიცენზიების გაყიდვების ავტორიზებული პარტნიორი საქართველოში.',
        en: 'Georgian Research and Educational Networking Association GRENA is authorized partner of various leading companies providing software licenses in Georgia.',
      },
      {
        ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია ”გრენა” არის სხვადასხავა წამყვანი კომპანიების კომპიუტერული პროგრამული უზრუნველყოფის ლიცენზიების გაყიდვების ავტორიზებული პარტნიორი საქართველოში.',
        en: 'Georgian Research and Educational Networking Association GRENA is authorized partner of various leading companies providing software licenses in Georgia.',
      },
      {
        ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია ”გრენა” არის სხვადასხავა წამყვანი კომპანიების კომპიუტერული პროგრამული უზრუნველყოფის ლიცენზიების გაყიდვების ავტორიზებული პარტნიორი საქართველოში.',
        en: 'Georgian Research and Educational Networking Association GRENA is authorized partner of various leading companies providing software licenses in Georgia.',
      },
      {
        ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია ”გრენა” არის სხვადასხავა წამყვანი კომპანიების კომპიუტერული პროგრამული უზრუნველყოფის ლიცენზიების გაყიდვების ავტორიზებული პარტნიორი საქართველოში.',
        en: 'Georgian Research and Educational Networking Association GRENA is authorized partner of various leading companies providing software licenses in Georgia.',
      },
    ],
  }
]