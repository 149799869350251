import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiContentForms, apiEducation } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import { constants } from '../../data/constants'
import ContentForm from '../form/ContentForm'
import BackdropElement from '../layout/BackdropElement'

const ContentButton = ({
  formTitle,
  formType,
  formName,
  formID,
  openButton,
  openButtonStyle,
  submitButton,
  submitButtonStyle,
  setModalOpen,
  setModalTitle,
  setModalContent,
}) => {
  const [formFields, setFormFields] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)

  const handleClick = (e) => {
    e.stopPropagation()

    const titleValue = formTitle ? `${openButton} ${formTitle}` : openButton

    const contentComponent = (
      <ContentForm
        formTitle={formTitle}
        formType={formType}
        formName={formName}
        formFields={formFields}
        submitButton={submitButton}
        submitButtonStyle={submitButtonStyle} 
        setModalOpen={setModalOpen}
        setModalTitle={setModalTitle}
        setModalContent={setModalContent}
        setBackdropOpen={setBackdropOpen}
      />
    )

    setModalOpen(true)
    setModalTitle(titleValue)
    setModalContent(contentComponent)
  }

  useEffect(() => {
    const fetchFormFields = async () => {
      let response_url = ''

      if (formType === 'content') {
        response_url = settings.domain + settings.api + apiContentForms.getFormFields + '/?param_name=form_name&param_value=' + formName
      } else if (formType === 'academy_registration') {
        response_url = settings.domain + settings.api + apiEducation.getFormFields + '/?form_id=' + formID
      }

      const response = await request(response_url, 'GET')
      
      if (response && response.length > 0) {
        let tempFormFields = sortByPriority(response)

        for (let index = 0; index < tempFormFields.length; index++) {
          let action2 = ''

          if (formType === 'content') {
            action2 = 'content-' + tempFormFields[index].type + '-fields'
          } else if (formType === 'academy_registration') {
            action2 = tempFormFields[index].type + '-fields'
          }

          const response2 = await request(settings.domain + settings.api + action2 + '/' + tempFormFields[index].field_id, 'GET')

          if (response2 && response2.id) {
            tempFormFields[index].options = response2
          }

          if (tempFormFields[index].has_subfield) {
            let tempFormSubfields = [...tempFormFields[index].subfields]

            for (let index2 = 0; index2 < tempFormSubfields.length; index2++) {
              let action3 = ''

              if (formType === 'content') {
                action3 = 'content-' + tempFormSubfields[index2].type + '-fields'
              } else if (formType === 'academy_registration') {
                action3 = tempFormSubfields[index2].type + '-fields'
              }

              const response3 = await request(settings.domain + settings.api + action3 + '/' + tempFormSubfields[index2].subfield_id, 'GET')

              if (response3 && response3.id) {
                tempFormSubfields[index2].options = response3
              }
            }

            tempFormFields[index].subfields = tempFormSubfields
          }
        }
        
        setFormFields(tempFormFields)
        setBackdropOpen(false)
      } else {
        setFormFields([])
        setBackdropOpen(false)
      }
    }

    setBackdropOpen(true)
    
    if ((formType === 'content' && formName) || (formType === 'academy_registration' && formID)) {
      fetchFormFields()
    } else if (formType === 'academy_subscription') {
      const emailField = {
        id: '1',
        form_name: '',
        priority: '1',
        required: true,
        type: 'text',
        field_id: '1',
        has_subfield: false,
        subfields: [],
        subfields_mapping: [],
        options: {
          id: '1',
          label: { ka: constants.emailLabel.ka, en: constants.emailLabel.en },
          short_name: { ka: constants.emailLabel.ka, en: constants.emailLabel.en },
          type: 'text',
          line_number: 1,
          placeholder: { ka: constants.emailLabel.ka, en: constants.emailLabel.en },
          content_type: 'email',
        }
      }

      setFormFields([emailField])
      setBackdropOpen(false)
    } else {
      setFormFields([])
      setBackdropOpen(false)
    }  
  }, [formType, formName, formID])
  
  return (
    <>
      <BackdropElement open={backdropOpen} />
      <Button variant='contained' disableRipple sx={openButtonStyle} onClick={handleClick}>{openButton}</Button>
    </>
  )
}

export default ContentButton