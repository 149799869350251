import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { NewsItemBox, NewsItemImageBox, NewsItemDateBox, NewsItemTitleBox } from '../../themes/boxNews'
import { constants } from '../../data/constants'
import { NewsMoreButton } from '../../themes/button'
import { formatNewsDate } from '../../helpers/dates'
import settings from '../../helpers/settings'

const NewsItem = ({ id, title, image_url, description, start_date, end_date, handleClick }) => {
  const lang = useSelector(state => state.lang)

  const formattedDate = formatNewsDate(start_date, end_date, lang)

  const newsObject = {
    id,
    image_url,
    description: description[lang],
    date: formattedDate,
  }

  return (
    <NewsItemBox onClick={() => handleClick(newsObject)}>
      <Grid container spacing={4}>
        {
          image_url && (
            <Grid xs={12} md={4} lg={3}>
              <NewsItemImageBox sx={{ background: `url(${ settings.s3URL + settings.s3Bucket + image_url }) no-repeat center / cover` }}></NewsItemImageBox>
            </Grid>
          )
        }
        <Grid xs={12} md={ image_url ? 8 : 12 } lg={ image_url ? 9 : 12 }>
          <NewsItemDateBox>{formattedDate}</NewsItemDateBox>
          <NewsItemTitleBox>{title[lang]}</NewsItemTitleBox>
          <NewsMoreButton variant='text' endIcon={<ArrowForwardIcon />} disableRipple>{constants.seeFull[lang]}</NewsMoreButton>
        </Grid>
      </Grid>
    </NewsItemBox>
  )
}

export default NewsItem