export const langItems = [
  {
    id: '1',
    name: 'ka',
    title: { ka: 'GEO', en: 'GEO' },
    is_active: true,
    is_default: true,
  },
  {
    id: '2',
    name: 'en',
    title: { ka: 'ENG', en: 'ENG' },
    is_active: true,
    is_default: false,
  },
]