import logoKa from './images/logo_ka.png'
import logoEn from './images/logo_en.png'
import servicesIcon from './images/services_icon.png'
import servicesIconActive from './images/services_icon_active.png'
import internetIcon from './images/internet_icon.png'
import internetIconActive from './images/internet_icon_active.png'
import vpsIcon from './images/vps_icon.png'
import vpsIconActive from './images/vps_icon_active.png'
import educationIcon from './images/education_icon.png'
import educationIconActive from './images/education_icon_active.png'
import softwareIcon from './images/software_icon.png'
import softwareIconActive from './images/software_icon_active.png'
import domainsIcon from './images/domains_icon.png'
import domainsIconActive from './images/domains_icon_active.png'
import servicesTitle0 from './images/services_title_0.png'
import servicesTitle1 from './images/services_title_1.png'
import servicesTitle2 from './images/services_title_2.png'
import servicesTitle3 from './images/services_title_3.png'
import servicesTitle4 from './images/services_title_4.png'
import ongoingProjectsBorder from './images/ongoing_projects_border.png'
import partnersPagination from './images/partners_pagination.png'
import partnersPaginationActive from './images/partners_pagination_active.png'
import toolbarArrowLeft from './images/toolbar_arrow_left.png'
import toolbarArrowRight from './images/toolbar_arrow_right.png'
import internetIllustration1 from './images/internet_illustration_1.png'
import internetIllustration2 from './images/internet_illustration_2.png'
import certHexagon from './images/cert_hexagon.png'
import certHexagonActive from './images/cert_hexagon_active.png'
import certHexagonMain from './images/cert_hexagon_main.png'
import eduroamIllustration from './images/eduroam_illustration.png'
import eduroamCountriesLogo from './images/eduroam_countries_logo.png'
import eduroamLocationLogo from './images/eduroam_location_logo.png'
import eduroamGeorgiaMap from './images/eduroam_georgia_map.png'
import mainMenuArrow from './images/mainmenu_arrow.png'
import mainMenuArrowRight from './images/mainmenu_arrow_right.png'
import avatar from './images/avatar.png'
import closeIcon from './images/close_icon.png'

import partnerCisco from './images/partners/cisco.png'
import partnerLpi from './images/partners/lpi.jpg'
import partnerPearson from './images/partners/pearson.png'
import partnerEye from './images/partners/eye_network.png'
import partnerGeant from './images/partners/geant.png'
import partnerEgi from './images/partners/egi.png'
import partnerTf from './images/partners/tf.png'
import partnerTc from './images/partners/tc.png'

import newsPic1 from './images/news/news230313.jpg'
import newsPic2 from './images/news/news230221.jpg'

import staffPic1 from './images/staff/ramaz.png'
import staffPic2 from './images/staff/maka.png'

import foundersPic1 from './images/founders/tsu.png'
import foundersPic2 from './images/founders/osgf.png'

import educationCisco from './images/education/cisco.png'
import educationLpi from './images/education/lpi.png'
import educationOracle from './images/education/oracle.png'
import educationMssql from './images/education/mssql.png'
import educationPearson from './images/education/pearson.png'
import educationCiscoIllustration from './images/education/cisco_illustration.png'

import classroom1 from './images/classroom/classroom1.jpg'
import classroom2 from './images/classroom/classroom2.jpg'
import classroom3 from './images/classroom/classroom3.jpg'
import classroom4 from './images/classroom/classroom4.jpg'
import classroom5 from './images/classroom/classroom5.jpg'
import classroom6 from './images/classroom/classroom6.jpg'
import classroom7 from './images/classroom/classroom7.jpg'
import classroom8 from './images/classroom/classroom8.jpg'

import eduroamGrena from './images/eduroam/grena.png'
import eduroamTSU from './images/eduroam/tsu.png'
import eduroamSabauni from './images/eduroam/sabauni.png'
import eduroamFreeuni from './images/eduroam/freeuni.jpg'

import edugainLogo from './images/edugain/logo.png'

import hostingAdvIcon1 from './images/hosting/adv_icon_1.png'
import hostingAdvIcon2 from './images/hosting/adv_icon_2.png'
import hostingAdvIcon3 from './images/hosting/adv_icon_3.png'
import hostingAdvIcon4 from './images/hosting/adv_icon_4.png'
import hostingAdvIcon5 from './images/hosting/adv_icon_5.png'
import hostingAdvIcon6 from './images/hosting/adv_icon_6.png'

import hostingProcessIcon1 from './images/hosting/process_icon_1.png'
import hostingProcessIcon2 from './images/hosting/process_icon_2.png'
import hostingProcessIcon3 from './images/hosting/process_icon_3.png'
import hostingProcessIcon4 from './images/hosting/process_icon_4.png'
import hostingProcessIcon5 from './images/hosting/process_icon_5.png'
import hostingProcessIcon6 from './images/hosting/process_icon_6.png'

import hostingServiceIcon1 from './images/hosting/service_icon_1.png'
import hostingServiceIcon2 from './images/hosting/service_icon_2.png'
import hostingServiceIcon3 from './images/hosting/service_icon_3.png'
import hostingServiceIcon4 from './images/hosting/service_icon_4.png'
import hostingServiceIcon5 from './images/hosting/service_icon_5.png'
import hostingServiceIcon6 from './images/hosting/service_icon_6.png'
import hostingServiceIcon7 from './images/hosting/service_icon_7.png'
import hostingServiceIcon8 from './images/hosting/service_icon_8.png'
import hostingServiceIcon9 from './images/hosting/service_icon_9.png'

import hostingArrow1 from './images/hosting/arrow_1.png'
import hostingArrow2 from './images/hosting/arrow_2.png'
import hostingArrow3 from './images/hosting/arrow_3.png'
import hostingArrow4 from './images/hosting/arrow_4.png'
import hostingArrow5 from './images/hosting/arrow_5.png'

import softwareProcessArrow from './images/software/process_arrow.png'
import softwareProcessIcon1 from './images/software/process_icon_1.png'
import softwareProcessIcon2 from './images/software/process_icon_2.png'
import softwareProcessIcon3 from './images/software/process_icon_3.png'

export const logoKaImage = logoKa
export const logoEnImage = logoEn
export const toolbarArrowLeftImage = <img src={toolbarArrowLeft} alt='' />
export const toolbarArrowRightImage = <img src={toolbarArrowRight} alt='' />
export const servicesIconImage = servicesIcon
export const servicesIconActiveImage = servicesIconActive
export const internetIconImage = internetIcon
export const internetIconActiveImage = internetIconActive
export const vpsIconImage = vpsIcon
export const vpsIconActiveImage = vpsIconActive
export const educationIconImage = educationIcon
export const educationIconActiveImage = educationIconActive
export const softwareIconImage = softwareIcon
export const softwareIconActiveImage = softwareIconActive
export const domainsIconImage = domainsIcon
export const domainsIconActiveImage = domainsIconActive
export const servicesTitle0Image = servicesTitle0
export const servicesTitle1Image = servicesTitle1
export const servicesTitle2Image = servicesTitle2
export const servicesTitle3Image = servicesTitle3
export const servicesTitle4Image = servicesTitle4
export const ongoingProjectsBorderImage = ongoingProjectsBorder
export const partnersPaginationImage = partnersPagination
export const partnersPaginationActiveImage = partnersPaginationActive
export const internetIllustration1Image = internetIllustration1
export const internetIllustration2Image = internetIllustration2
export const certHexagonImage = certHexagon
export const certHexagonActiveImage = certHexagonActive
export const certHexagonMainImage = certHexagonMain
export const eduroamIllustrationImage = eduroamIllustration
export const eduroamCountriesLogoImage = eduroamCountriesLogo
export const eduroamLocationLogoImage = eduroamLocationLogo
export const eduroamGeorgiaMapImage = eduroamGeorgiaMap
export const mainMenuArrowImage = mainMenuArrow
export const mainMenuArrowRightImage = mainMenuArrowRight
export const avatarImage = avatar
export const closeIconImage = closeIcon

export const partnerCiscoImage = partnerCisco
export const partnerLpiImage = partnerLpi
export const partnerPearsonImage = partnerPearson
export const partnerEyeImage = partnerEye
export const partnerGeantImage = partnerGeant
export const partnerEgiImage = partnerEgi
export const partnerTfImage = partnerTf
export const partnerTcImage = partnerTc

export const newsPic1Image = newsPic1
export const newsPic2Image = newsPic2

export const staffPic1Image = staffPic1
export const staffPic2Image = staffPic2

export const foundersPic1Image = foundersPic1
export const foundersPic2Image = foundersPic2

export const educationCiscoImage = educationCisco
export const educationLpiImage = educationLpi
export const educationOracleImage = educationOracle
export const educationMssqlImage = educationMssql
export const educationPearsonImage = educationPearson
export const educationCiscoIllustrationImage = educationCiscoIllustration

export const classroom1Image = classroom1
export const classroom2Image = classroom2
export const classroom3Image = classroom3
export const classroom4Image = classroom4
export const classroom5Image = classroom5
export const classroom6Image = classroom6
export const classroom7Image = classroom7
export const classroom8Image = classroom8

export const eduroamGrenaImage = eduroamGrena
export const eduroamTSUImage = eduroamTSU
export const eduroamSabauniImage = eduroamSabauni
export const eduroamFreeuniImage = eduroamFreeuni

export const edugainLogoImage = edugainLogo

export const hostingAdvIcon1Image = hostingAdvIcon1
export const hostingAdvIcon2Image = hostingAdvIcon2
export const hostingAdvIcon3Image = hostingAdvIcon3
export const hostingAdvIcon4Image = hostingAdvIcon4
export const hostingAdvIcon5Image = hostingAdvIcon5
export const hostingAdvIcon6Image = hostingAdvIcon6

export const hostingProcessIcon1Image = hostingProcessIcon1
export const hostingProcessIcon2Image = hostingProcessIcon2
export const hostingProcessIcon3Image = hostingProcessIcon3
export const hostingProcessIcon4Image = hostingProcessIcon4
export const hostingProcessIcon5Image = hostingProcessIcon5
export const hostingProcessIcon6Image = hostingProcessIcon6

export const hostingServiceIcon1Image = hostingServiceIcon1
export const hostingServiceIcon2Image = hostingServiceIcon2
export const hostingServiceIcon3Image = hostingServiceIcon3
export const hostingServiceIcon4Image = hostingServiceIcon4
export const hostingServiceIcon5Image = hostingServiceIcon5
export const hostingServiceIcon6Image = hostingServiceIcon6
export const hostingServiceIcon7Image = hostingServiceIcon7
export const hostingServiceIcon8Image = hostingServiceIcon8
export const hostingServiceIcon9Image = hostingServiceIcon9

export const hostingArrow1Image = hostingArrow1
export const hostingArrow2Image = hostingArrow2
export const hostingArrow3Image = hostingArrow3
export const hostingArrow4Image = hostingArrow4
export const hostingArrow5Image = hostingArrow5

export const softwareProcessArrowImage = softwareProcessArrow
export const softwareProcessIcon1Image = softwareProcessIcon1
export const softwareProcessIcon2Image = softwareProcessIcon2
export const softwareProcessIcon3Image = softwareProcessIcon3