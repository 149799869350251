import React, { useState, useEffect, useRef } from 'react'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { SoftwareDescriptionBox, SoftwareProcessTitleBox } from '../../themes/boxSoftware'
import { softwarePage, softwareCategory, softwarePartners } from '../../data/software'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiSoftware } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import { softwareProcessIcon1Image, softwareProcessIcon2Image, softwareProcessIcon3Image, softwareProcessArrowImage } from '../../themes/image'
import useWindowDimensions from '../../helpers/dimensions'
import SoftwarePartners from './SoftwarePartners'
import SoftwarePartnersMobile from './SoftwarePartnersMobile'
import ContentButton from '../form/ContentButton'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Software = () => {
  const theme = useTheme()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [description, setDescription] = useState([])
  const [cats, setCats] = useState([])
  const [partners, setPartners] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const sxButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: '20px',
    color: theme.palette.offwhite.main,
    background: theme.palette.primary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    borderRadius: '8px',
    padding: '0px 100px',
    marginBottom: '30px',
    height: '92px',
    boxShadow: 'none',

    '&:hover': {
      background: 'rgba(7, 77, 120, 0.9)',
      boxShadow: 'none',
    },

    '@media(max-width: 899px)': {
      width: '100%',
      height: 'auto',
      padding: '10px',
      fontSize: '18px',
    }
  }

  const getDescription = (a) => {
    const object = a.find(item => item.var_name === 'description')
    return object ? object.data : []
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchDescription = async () => {
      const response = await request(settings.domain + settings.api + apiSoftware.getPage, 'GET')

      if (response && response.length > 0) {
        setDescription(getDescription(response))
      } else {
        setDescription([])
      }
    }

    const fetchCats = async () => {
      const response = await request(settings.domain + settings.api + apiSoftware.getCat, 'GET')

      if (response && response.length > 0) {
        setCats(sortByPriority(response))
      } else {
        setCats([])
      }
    }

    const fetchPartners = async () => {
      const response = await request(settings.domain + settings.api + apiSoftware.getPartners, 'GET')

      if (response && response.length > 0) {
        setPartners(response)
      } else {
        setPartners([])
      }
    }

    if (settings.mode === 'live') {
      fetchDescription()
      fetchCats()
      fetchPartners()
    } else {
      setDescription(getDescription(softwarePage))
      setCats(sortByPriority(softwareCategory))
      setPartners(softwarePartners)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (cats.length > 0 && partners.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [cats, partners])

  return (
    <>
      <HelmetElement
        metaDescription='Licensed Software, Antivirus and Security, SSL Certificates, OS and Office Software, Graphic and Design, Software Development Tools'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.software[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            <SoftwareDescriptionBox>
              <Grid container spacing={{ xs: 2, lg: 8 }}>
                <Grid lg={6}>
                  {
                    description.map((item, index) => (
                      <Box key={index}>{item[lang]}</Box>
                    ))
                  }
                </Grid> 
                {
                  width > settings.desktopBreakpoint && (
                    <Grid lg={6} xl={6}>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={0}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '150px' }}>
                          <SoftwareProcessTitleBox>
                            <img src={softwareProcessIcon1Image} alt='' style={{ maxWidth: '30px' }} />
                            <Box sx={{ textAlign: 'left', paddingLeft: '20px' }}>{constants.softwareProcessStep1[lang]}</Box>
                          </SoftwareProcessTitleBox>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                          <img src={softwareProcessArrowImage} alt='' style={{ maxWidth: '90%' }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '150px' }}>
                          <SoftwareProcessTitleBox>
                            <img src={softwareProcessIcon2Image} alt='' style={{ maxWidth: '30px' }} />
                            <Box sx={{ textAlign: 'left', paddingLeft: '20px' }}>{constants.softwareProcessStep2[lang]}</Box>
                          </SoftwareProcessTitleBox>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                          <img src={softwareProcessArrowImage} alt='' style={{ maxWidth: '90%' }} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '150px' }}>
                          <SoftwareProcessTitleBox>
                            <img src={softwareProcessIcon3Image} alt='' style={{ maxWidth: '30px' }} />
                            <Box sx={{ textAlign: 'left', paddingLeft: '20px' }}>{constants.softwareProcessStep3[lang]}</Box>
                          </SoftwareProcessTitleBox>
                        </Box>
                      </Stack>
                    </Grid>
                  )
                }
              </Grid>
            </SoftwareDescriptionBox>
            { width > 899 ? <SoftwarePartners cats={cats} partners={partners} /> : <SoftwarePartnersMobile cats={cats} partners={partners} /> }

            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop: '75px' }}>
              <ContentButton
                formTitle=''
                formType='content'
                formName={settings.formSoftware}
                formID=''
                openButton={constants.softwareButtonText[lang]}
                openButtonStyle={sxButton}
                submitButton={constants.send[lang]}
                submitButtonStyle=''
                setModalOpen={setModalOpen}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
              />
              <ModalWindow
                modalOpen={modalOpen}
                modalTitle={modalTitle}
                modalContent={modalContent}
                modalButtons={[]}
                modalWidth='lg'
                onClose={handleCloseModal}
              />
            </Box>
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Software