import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import HomePartnersItem from './HomePartnersItem'

const HomePartnersSlideItem = ({ logos, opacity }) => {
  return (
    <Box>
      <Grid container spacing={{xs: 2, md: 5}}>
        {
          logos.map((item, index) => (
            <HomePartnersItem key={index} {...item} opacity={opacity} />
          ))
        }
      </Grid>
    </Box>
  )
}

export default HomePartnersSlideItem