import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { AboutDescriptionBox, AupOrganizationsUlBox, AupOrganizationsTextBox } from '../../themes/boxAbout'
import { aupPage } from '../../data/about'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiAbout } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'

const Aup = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [page, setPage] = useState([])
  const [description, setDescription] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [illustration, setIllustration] = useState('')
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const getDescription = (a) => {
    const object = a.find(item => item.var_name === 'description')
    return object ? object.data : []
  }

  const getOrganizations = (a) => {
    const object = a.find(item => item.var_name === 'organizations')
    return object ? object.data : []
  }

  const getIllustration = (a) => {
    const object = a.find(item => item.var_name === 'illustration')
    return object ? settings.s3URL + settings.s3Bucket + object.data[0].illustration : ''
  }

  useEffect(() => {
    const fetchPage = async () => {
      const response = await request(settings.domain + settings.api + apiAbout.getAup, 'GET')

      if (response && response.length > 0) {
        setPage(response)
      } else {
        setPage([])
      }
    }

    if (settings.mode === 'live') {
      fetchPage()
    } else {
      setPage(aupPage)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    setDescription(getDescription(page))
    setOrganizations(getOrganizations(page))
    setIllustration(getIllustration(page))

    if (page.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [page, lang])
  
  return (
    <RegularPageFluidBox>
      <BackdropElement open={backdropOpen} />
      <ErrorMessage open={errorMessageOpen} />
      <Container maxWidth='xl' sx={sxContainer}> 
        <Grid container>
          <Grid xs={12} lg={5}>
            <RegularPageTitleBox>{constants.aup[lang]}</RegularPageTitleBox>
          </Grid>
        </Grid>
        <RegularPageContentR5Box>
          <Grid container spacing={{ xs: 3, lg: 6 }}>
            <Grid xs={12} lg={6}>
              <AboutDescriptionBox>
                { description.length > 0 && <Box sx={{ fontWeight: 'bold' }}>{description[0][lang]}</Box> }
                <br />
                { description.length > 1 && <Box>{description[1][lang]}</Box> }
                <br />
                {
                  organizations.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                      <AupOrganizationsUlBox></AupOrganizationsUlBox>
                      <AupOrganizationsTextBox>{item[lang]}</AupOrganizationsTextBox>
                    </Box>
                  ))
                }
                <br />
                { description.length > 2 && <Box>{description[2][lang]}</Box> }
                <br />
                { description.length > 3 && <Box>{description[3][lang]}</Box> }
              </AboutDescriptionBox>
            </Grid>
            <Grid xs={12} lg={5} xsOffset={0} lgOffset={1}>
              { (illustration && width > settings.desktopBreakpoint) && <img src={illustration} alt='' style={{ maxWidth: '100%' }} /> }
            </Grid>
          </Grid>
        </RegularPageContentR5Box>
      </Container>
    </RegularPageFluidBox>
  )
}

export default Aup