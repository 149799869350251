import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import { cleanWordTags } from '../../helpers/string'
import parse from 'html-react-parser'

const NewsFull = ({ id, image_url, description }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  const sxImage = width > settings.padBreakpoint ? { width: '40%', float: 'left', margin: '0px 20px 10px 0px' } : { width: '100%', float: 'left', margin: '0px 0px 20px 0px' }

  const sxDescription = {
    fontFamily: theme.typography.regular.fontFamily,
  }

  const handleDoubleClick = (e) => {
    if (e.detail === 2) {
      navigate(`/news/${id}`)
    }
  }

  return (
    <Box onClick={handleDoubleClick}>
      { image_url && <img src={ settings.s3URL + settings.s3Bucket + image_url } alt='' style={sxImage} /> }
      <Box sx={sxDescription}>{parse(cleanWordTags(description))}</Box>
      <Box sx={{ clear: 'both' }}></Box>
    </Box>
  )
}

export default NewsFull