import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useWindowDimensions from '../../helpers/dimensions'
import Box from '@mui/material/Box'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { MainMenuDropdownButton } from '../../themes/button'
import { MainMenuDropdownBox, MainMenuMobileItemBox } from '../../themes/boxLayout'
import { usePopupState, bindFocus, bindHover, bindMenu } from 'material-ui-popup-state/hooks'
import { subMenuItems } from '../../data/mainmenu'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiMenus } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import { mainMenuArrowImage, mainMenuArrowRightImage } from '../../themes/image'
import MainMenuDropdownItem from './MainMenuDropdownItem'

const MainMenuDropdown = ({ id, title, submenu_name }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [subMenu, setSubMenu] = useState([])

  const [mobileOpen, setMobileOpen] = useState('')
  const [mobileHeight, setMobileHeight] = useState(0)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  })

  const sxArrow = mobileOpen ? `url(${mainMenuArrowImage}) no-repeat right top 10px` : `url(${mainMenuArrowRightImage}) no-repeat right top 10px`
  const sxborderBottom = mobileOpen ? 'none' : '1px solid rgba(221, 221, 221, 0.6)'

  const sxMobileSubmenu = {
    overflow: 'hidden',
    transition: 'all 0.5s',
    paddingLeft: '20px',
    height: mobileOpen ? `${mobileHeight}px` : '1px',
  }

  const handleMobileClick = () => {
    if (mobileOpen) setMobileOpen('')
    else setMobileOpen(id)
  }
  
  useEffect(() => {
    const fetchMenuItem = async (item) => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenuItems + '/' + item, 'GET')

      if (response && response.is_visible) {
        return response
      }
    }

    const fetchSubMenu = async (submenu_name) => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenus + '/' + submenu_name, 'GET')

      if (response && response.items && response.items.length > 0) {
        let temp = []

        for (let index = 0; index < response.items.length; index ++ ) {
          const menuItem = await fetchMenuItem(response.items[index])
          
          if (menuItem) {
            temp.push(menuItem)
          }
          
          if (temp.length === response.items.length) {
            setSubMenu(sortByPriority(temp))
            setMobileHeight(temp.length * 40 + 20)
          }
        }
      } else {
        const temp = subMenuItems.filter(item => (item.submenu_name === submenu_name && item.is_visible))
        setSubMenu(sortByPriority(temp))
        setMobileHeight(temp.length * 40 + 20)
      }
    }
    
    if (settings.mode === 'live' && submenu_name) {
      fetchSubMenu(submenu_name)
    } else {
      const temp = subMenuItems.filter(item => (item.submenu_name === submenu_name && item.is_visible))
      setSubMenu(sortByPriority(temp))
      setMobileHeight(temp.length * 40 + 20)
    }
  }, [submenu_name])
  
  return (
    <>
      {
        width > settings.desktopBreakpoint ? (
          <>
            <MainMenuDropdownButton variant='text' disableRipple {...bindHover(popupState)} {...bindFocus(popupState)}>{title[lang]}</MainMenuDropdownButton>
            <HoverMenu
              {...bindMenu(popupState)}
              disableScrollLock
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              PaperProps={{
                style: {
                  borderRadius: '0px',
                  boxShadow: 'none',
                  background: 'none',
                  overflow: 'visible',
                },
              }}
            >
              <MainMenuDropdownBox>
                {
                  subMenu.map((item, index) => (
                    item.title[lang] && <MainMenuDropdownItem key={index} handleClose={popupState.close} {...item} />
                  ))
                }
              </MainMenuDropdownBox>
            </HoverMenu>
          </>
        ) : (
          <>
            <MainMenuMobileItemBox sx={{ background: sxArrow, borderBottom: sxborderBottom }} onClick={handleMobileClick}>{title[lang]}</MainMenuMobileItemBox>
            <Box sx={sxMobileSubmenu}>
              {
                subMenu.map((item, index) => (
                  item.title[lang] && <MainMenuDropdownItem key={index} handleClose={popupState.close} {...item} />
                ))
              }
            </Box>
          </>
        )
      }
    </> 
  )
}

export default MainMenuDropdown