import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import {
  FoundersItemBox,
  FoundersLogoBox,
  FoundersNameBox,
  // FoundersDirectorBox,
  // FoundersRepresentativeBox
} from '../../themes/boxFounders'
// import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const FoundersItem = ({ name, logo_url, website, representative, is_director, last }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const handleClick = () => {
    window.open(website, '_blank')
  }

  return (
    <>
      { (last && width > settings.padBreakpoint) && <Grid xs={12} md={3} lg={4}></Grid> }
      <Grid xs={12} md={6} lg={4}>
        <FoundersItemBox onClick={handleClick}>
          <FoundersLogoBox>
            { logo_url && <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90%', maxHeight: '100%' }} /> }
          </FoundersLogoBox>
          <FoundersNameBox>{name[lang]}</FoundersNameBox>
          {/* { is_director && <FoundersDirectorBox>{constants.chairman[lang]}:</FoundersDirectorBox> }
          <FoundersRepresentativeBox>{representative[lang]}</FoundersRepresentativeBox> */}
        </FoundersItemBox>
      </Grid>
    </>
  )
}

export default FoundersItem