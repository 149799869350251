import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import {
  ToolbarBox,
  ToolbarLabelBox,
  ToolbarLabelIconBox,
  ToolbarLabelTextBox,
  ToolbarItemBox,
  ToolbarItemTextBox,
  ToolbarArrowBox
} from '../../themes/boxLayout'
import { mainMenuArrowImage } from '../../themes/image'
import { toolbarItems, toolbarOptions } from '../../data/toolbar'
import ConstructionIcon from '@mui/icons-material/Construction'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiToolbar } from '../../helpers/urls'
import ToolbarMirrors from './ToolbarMirrors'

const ToolbarMobile = ({ open, toggle }) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)
  
  const [toolbar, setToolbar] = useState([])
  const [heights, setHeights] = useState([])
  const [mirrorsCount, setMirrorsCount] = useState(0)

  const bottomStyle = open ? '0px' : '-100%'

  const sxItem = {
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'all 0.1s'
  }

  const sxIcon = { width: '31px', color: theme.palette.offwhite.main }

  const handleClick = (index, url) => {
    if (url) window.open(url, '_blank')
    else {
      let newHeights = heights
      const newHeight = mirrorsCount * 24
      if (newHeights[index] === '0px') newHeights[index] = `${newHeight}px`
      else newHeights[index] = '0px'
      setHeights([...newHeights])
    }
  }

  const handleSetMirrorsCount = (num) => {
    setMirrorsCount(num)
  }

  useEffect(() => {
    const fetchToolbar = async () => {
      const response = await request(settings.domain + settings.api + apiToolbar.getItems, 'GET')

      if (response && response.length > 0) {
        setToolbar(response)
      } else {
        setToolbar([])
      }
    }

    if (settings.mode === 'live') {
      fetchToolbar()
    } else {
      setToolbar(toolbarItems)
    }
  }, [])

  useEffect(() => {
    const setDimensionsToDefault = () => {
      let heightsArray = []
  
      toolbar.forEach((item, index) => {
        heightsArray[index] = '0px'
      })
  
      setHeights(heightsArray)
    }

    setDimensionsToDefault()
  }, [toolbar])
  
  return (
    <ToolbarBox sx={{ bottom: bottomStyle }}>
      <ToolbarLabelBox>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <ToolbarLabelIconBox onClick={toggle}>
            <ConstructionIcon />
          </ToolbarLabelIconBox>
          <ToolbarLabelTextBox>Toolbar</ToolbarLabelTextBox>
        </Box>
        <ToolbarArrowBox onClick={toggle}>{<img src={mainMenuArrowImage} alt='' />}</ToolbarArrowBox>
      </ToolbarLabelBox>
      {
        toolbar.map((item, index) => {
          const toolbarOptionsObject = toolbarOptions.find(item3 => item3.name === item.icon_url)
          const iconUrl = toolbarOptionsObject ? toolbarOptionsObject.icon_url : ''

          const sxSubmenuIfOpen = {
            overflow: 'hidden',
            transition: 'all 0.5s',
            height: heights[index]
          }

          const sxSubmenuIfClose = {
            display: 'none',
          }

          return (
            <Box key={index}>
              <ToolbarItemBox sx={sxItem} onClick={() => handleClick(index, item.tool_url)}>
                <Box sx={sxIcon}>{iconUrl}</Box>
                <ToolbarItemTextBox>{item.title[lang]}</ToolbarItemTextBox>
              </ToolbarItemBox>
              {
                item.has_submenu && (
                  <ToolbarMirrors
                    sxSubmenuIfOpen={sxSubmenuIfOpen}
                    sxSubmenuIfClose={sxSubmenuIfClose}
                    submenu={item.submenu_id}
                    handleSetMirrorsCount={handleSetMirrorsCount}
                  />
                )
              }
            </Box>
          )
        })
      }
    </ToolbarBox>
  )
}

export default ToolbarMobile