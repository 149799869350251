import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/configureStore'
import AppRouter from './routers/AppRouter'
import './styles/style.css'

const jsx = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppRouter />
    </PersistGate>
  </Provider>
)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(jsx)