import {
  staffPic1Image,
  staffPic2Image,
} from '../themes/image'

export const staffItems = [
  {
    id: '',
    name: { ka: 'რამაზ ქვათაძე', en: 'Ramaz Kvatadze', },
    photo_url: staffPic1Image,
    position: { ka: 'აღმასრულებელი დირექტორი', en: 'Executive Director', },
    bio: { ka: '', en: '', },
    is_main_staff: true,
    is_instructor: false,

  },
  {
    id: '',
    name: { ka: 'მაია ღრუბელაძე', en: 'Maia Ghrubeladze', },
    photo_url: staffPic2Image,
    position: { ka: 'ფინანსური მენეჯერი', en: '', },
    bio: { ka: '', en: '', },
    is_main_staff: true,
    is_instructor: false,
  },
  {
    id: '',
    name: { ka: 'რამაზ ქვათაძე', en: '', },
    photo_url: staffPic1Image,
    position: { ka: 'აღმასრულებელი დირექტორი', en: '', },
    bio: { ka: '', en: '', },
    is_main_staff: true,
    is_instructor: false,

  },
  {
    id: '',
    name: { ka: 'მაია ღრუბელაძე', en: 'Maia Ghrubeladze', },
    photo_url: staffPic2Image,
    position: { ka: 'ფინანსური მენეჯერი', en: '', },
    bio: {
      ka: 'ლორემ იპსუმ მტკავლით სიწმინდესა უმტკივნეულოდ მოგცემდენ ქართლისა მოვევლინეთ შესწითლა. ტრაპთან დაცემულიყო გენერალური ატრიალებს ლიტერატურული დამატების, სიკოხტავე ედით',
      en: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
    },
    is_main_staff: true,
    is_instructor: true,
  },
  {
    id: '',
    name: { ka: 'რამაზ ქვათაძე', en: '', },
    photo_url: staffPic1Image,
    position: { ka: 'აღმასრულებელი დირექტორი', en: '', },
    bio: { ka: 'პანჩსა სანაცვლოდ დამშვიდდა დამკვიდრდა იზეპირებდნენ, ამბავმა სადღაა წირვაზე მუსიკალური, ფორიაქობდა მოიდრიკა ღმერთო, გავიხედე ეტიკეტის. კოპერნიკის რომისა მუსიკალური იქორწინების საზარელმა, სწორ ფარდას, აუცილებლობა უბზუოდნენ. ნამდვილ ნაშინდარშიც კოპერნიკის ღმერთო', en: '', },
    is_main_staff: true,
    is_instructor: true,

  },
  {
    id: '',
    name: { ka: 'მაია ღრუბელაძე', en: '', },
    photo_url: staffPic2Image,
    position: { ka: 'ფინანსური მენეჯერი', en: '', },
    bio: { ka: 'გამოცხადებულიყვნენ დაუდებს გაიშალნენ თხუთმეტიოდე ქართველებით. მოანდომა გუმბათისებური მილანის, რევოლუცია დაბძანდით, ძაბვის ისვენებდნენ ღრუში კვარტალი კავშირი სხვანივე გაქცევით ვაღიარებ, მკაცრი მყავს. იშვირა გაქცევით', en: '', },
    is_main_staff: true,
    is_instructor: true,
  },
]