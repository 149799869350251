import React from 'react'
import { useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'

const DomainEppCodeText = ({ value, placeholder, handleChange, handleSubmit }) => {
  const theme = useTheme()
  
  const sx = {
    width: '100%',
  }

  const sxInput = {
    width: '100%',
    height: '70px',
    background: `${theme.palette.offwhite.main}`,
    border: '#b2b4bc 1px solid',
    borderRadius: '8px',
    padding: '0px 30px',
    marginLeft: '20px',
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.darkblue.main,

    '&::placeholder': {
      color: theme.palette.darkblue.main,
      opacity: 1,
    },

    '@media(max-width: 899px)': {
      marginLeft: '0px',
      marginTop: '20px',
    }
  }

  const sxFieldset = {
    '& fieldset': {
      border: 'none'
    },

    '&:hover fieldset': {
      border: 'none',
    },

    '&:focus-within fieldset, &:focus-visible fieldset': {
      border: 'none',
    },
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <TextField
      sx={sx}
      inputProps={{ sx: sxInput }}
      InputProps={{ sx: sxFieldset }}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

export default DomainEppCodeText