import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { HeaderFullTitleBox } from '../../themes/boxLayout'
import TypingAnimation from './TypingAnimation'

const GrenaFullTitle = () => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [elWidth, setElWidth] = useState('1%')
  const [finishAnimation, setFinishAnimation] = useState(false)

  const grenaFullTitle = {
    ka: 'საქართველოს სამეცნიერო-საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია \'გრენა\'',
    en: 'Georgian Research and Educational Networking Association GRENA',
  }
  
  const upxl = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    upxl ? setElWidth('70%') : setElWidth('80%')
  }, [upxl])

  useEffect(() => {
    setTimeout(() => {
      setFinishAnimation(true)
    }, 3000)
  }, [])

  return (
    <HeaderFullTitleBox sx={{ width: elWidth }}>
      { finishAnimation ? <Box>{grenaFullTitle[lang]}</Box> : <TypingAnimation text={grenaFullTitle[lang]} delay='0' /> }
    </HeaderFullTitleBox>
  )
}

export default GrenaFullTitle