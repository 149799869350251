import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { EducationAcademyMobileStartBox, EducationAcademyMobileEndBox, EducationAcademyMobileCenterBox, EducationAcademyTitleBox } from '../../themes/boxEducation'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import EducationAcademyItemLeft from './EducationAcademyItemLeft'
import EducationAcademyItemRight from './EducationAcademyItemRight'

const EducationAcademyItem = ({ id, name, logo_url, index, polyCoordinates, polyStyle, polyStylesHover, hoverIndex, handleMouseEnter, handleMouseLeave }) => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  const lang = useSelector(state => state.lang)

  const isOdd = (index%2 === 0)
  const styleIndex = index%4
  const white = ( styleIndex !== 1 && index === hoverIndex )

  const sxDesktop = { position: 'relative' }
  const sxMobile = {
    position: 'relative',
    height: '82px',
    marginBottom: '20px',
    cursor: 'pointer',
  }

  const sxBox = width > settings.padBreakpoint ? sxDesktop : sxMobile

  const handleClick = (e) => {
    if (width <= settings.padBreakpoint) {
      if (e.ctrlKey || e.button === 1) {
        window.open(`/education/${id}`, '_blank')
      } else if (e.type === 'click') {
        navigate(`/education/${id}`)
      } 
    }
  }

  return (
    <Box sx={sxBox} onClick={handleClick} onMouseDown={handleClick}>
      {
        width > settings.padBreakpoint ? (
          <>
          <svg width='618' height='176'>
            <polygon points={ isOdd ? polyCoordinates.logoLeft : polyCoordinates.logoRight } style={ index === hoverIndex ? polyStylesHover[styleIndex].logo : polyStyle} />
            <polygon points={ isOdd ? polyCoordinates.titleLeft : polyCoordinates.titleRight } style={ index === hoverIndex ? polyStylesHover[styleIndex].title : polyStyle} />
          </svg>
          {
            isOdd ? (
              <EducationAcademyItemLeft
                id={id}
                name={name[lang]}
                logo_url={logo_url}
                index={index}
                white={white}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            ) : (
              <EducationAcademyItemRight
                id={id}
                name={name[lang]}
                logo_url={logo_url}
                index={index}
                white={white}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            )
      }
          </>
        ) : (
          <>
            <EducationAcademyMobileStartBox></EducationAcademyMobileStartBox>
            <EducationAcademyMobileCenterBox>
                { logo_url && <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '70px' }} /> }
                <EducationAcademyTitleBox>{name[lang]}</EducationAcademyTitleBox>
            </EducationAcademyMobileCenterBox>
            <EducationAcademyMobileEndBox></EducationAcademyMobileEndBox>
          </>  
        )
      }
    </Box>
  )
}

export default EducationAcademyItem