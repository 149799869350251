import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { HostingAdvantagesBox, HostingAdvantagesLabelBox } from '../../themes/boxHosting'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'
import HostingAdvantagesItem from './HostingAdvantagesItem'
import SlickSlider from '../layout/SlickSlider'

const HostingAdvantages = ({ advantages }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let slidesToShowValue = 1
  let slidesToScrollValue = 1

  if (width > 899) {
    slidesToShowValue = 2
    slidesToScrollValue = 2
  } else {
    slidesToShowValue = 1
    slidesToScrollValue = 1
  }

  const [slides, setSlides] = useState([])
  const [slidesToShow , setSlidesToShow] = useState(slidesToShowValue)
  const [slidesToScroll , setSlidesToScroll] = useState(slidesToScrollValue)

  const sliderSpeed = 500

  const sxPrev = {
    position: 'absolute',
    top: '20%',
    left: '-60px',
    cursor: 'pointer',

    '@media(max-width: 400px)': {
      left: '-50px',
    },
  }

  const sxNext = {
    position: 'absolute',
    top: '20%',
    right: '-60px',
    cursor: 'pointer',

    '@media(max-width: 400px)': {
      right: '-50px',
    },
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon color='primary' sx={sxPrev} />,
    leftStyle: {},
    leftFunction: '',
    rightIcon: <ArrowForwardIcon color='primary' sx={sxNext} />,
    rightStyle: {},
    rightFunction: '',
  }

  useEffect(() => {
    if (width > 899) {
      setSlidesToShow(2)
      setSlidesToScroll(2)
    } else {
      setSlidesToShow(1)
      setSlidesToScroll(1)
    }
  }, [width])

  useEffect(() => {
    let t = []
    advantages.forEach((item, index) => {
      t.push(<HostingAdvantagesItem key={index} {...item} />)
    })
    setSlides(t)
  }, [advantages])

  return (
    <HostingAdvantagesBox>
      <HostingAdvantagesLabelBox>{constants.whyService[lang]}</HostingAdvantagesLabelBox>
      {
        width > settings.desktopBreakpoint ? (
          <Grid container spacing={8}>
            {
              advantages.map((item, index) => (
                <Grid lg={4} key={index}>
                  <HostingAdvantagesItem {...item} />
                </Grid>
              ))
            }
      </Grid>
        ) : (
          <SlickSlider
            slides={slides}
            sxBody={{ position: 'relative' }}
            arrows={sliderArrows}
            pagination={{}}
            autoplay={false}
            draggable={true}
            speed={sliderSpeed}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            centerMode={false}
            handleAfterChange=''
          />
        )
      }
    </HostingAdvantagesBox>
  )
}

export default HostingAdvantages