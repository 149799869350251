import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { SoftwareCategoryLineBox, SoftwarePartnersBox, SoftwareDescriptionBox } from '../../themes/boxSoftware'
import useWindowDimensions from '../../helpers/dimensions'
import SoftwarePartnersMobileLogo from './SoftwarePartnersMobileLogo'
import SoftwarePartnersMobileItem from './SoftwarePartnersMobileItem'
import SlickSlider from '../layout/SlickSlider'

const SoftwarePartnersMobile = ({ cats, partners }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let sliderMarginValue = '-15px -20px 100px -20px'

  if (width >= 600) {
    sliderMarginValue = '0px -24px 0px -24px'
  } else {
    sliderMarginValue = '0px -16px 0px -16px'
  }

  const [slides, setSlides] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [clicked, setClicked] = useState(false)
  const [filteredPartners, setFilteredPartners] = useState([])
  const [description, setDescription] = useState('')
  const [sliderMargin, setSliderMargin] = useState(sliderMarginValue)

  const slidesToShow = 1
  const slidesToScroll = 1
  const sliderSpeed = 500

  const handleAfterChange = (index) => {
    setActiveIndex(index)
  }

  useEffect(() => {
    if (width >= 600) {
      setSliderMargin('0px -24px 0px -24px')
    } else {
      setSliderMargin('0px -16px 0px -16px')
    }
  }, [width])

  useEffect(() => {
    const filterPartners = (catIndex) => {
      const tempObject = cats.find((item, index) => index === catIndex)
      const catID = tempObject ? tempObject.id : ''
      const descriptionValue = tempObject ? tempObject.description[lang] : ''
      const tempArray = partners.filter(item => item.categories.includes(catID))
      setFilteredPartners(tempArray)
      setDescription(descriptionValue)
    }

    const handleClick = () => {
      if (clicked) setClicked(false)
      else {
        setClicked(true)

        setTimeout(() => {
          const element = document.getElementById('categories')
          if (element) element.scrollIntoView({ behavior: 'smooth' })
        }, 200)
      }
    }

    let t = []
    cats.forEach((item, index) => {
      t.push(<SoftwarePartnersMobileItem key={index} index={index} activeIndex={activeIndex} clicked={clicked} handleClick={handleClick} {...item} />)
    })
    setSlides(t)

    filterPartners(activeIndex)
  }, [cats, partners, activeIndex, clicked, lang])

  return (
    <>
      <SlickSlider
        slides={slides}
        sxBody={{ position: 'relative', margin: sliderMargin }}
        arrows={{}}
        pagination={{}}
        autoplay={false}
        draggable={true}
        speed={sliderSpeed}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        centerMode={true}
        handleAfterChange={handleAfterChange}
      />
      {
        clicked && (
          <>
            <SoftwareCategoryLineBox id='categories'></SoftwareCategoryLineBox>
            <SoftwarePartnersBox>
              <SoftwareDescriptionBox>{description[lang]}</SoftwareDescriptionBox>
              <Grid container spacing={2}>
                {
                  filteredPartners.map((item, index) => (
                    <Grid xs={6} sm={4} key={index}>
                      <SoftwarePartnersMobileLogo logo_url={item.logo_url} website={item.website} />
                    </Grid>
                  ))
                }
              </Grid>
            </SoftwarePartnersBox>
          </>
        )
      }
    </>
    
  )
}

export default SoftwarePartnersMobile