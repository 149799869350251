import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import servicesBackground from './images/services_background.png'
import servicesBackgroundLeft from './images/services_background_left.png'
import servicesHexagon from './images/services_hexagon.png'
import ongoingProjectsBackground from './images/ongoing_projects_background.png'
import partnersButton from './images/partners_button.png'
import partnersButtonOuter from './images/partners_button_outer.png'
import partnersButtonInner from './images/partners_button_inner.png'
import partnersIllustration from './images/partners/illustration.png'
import ongoingProjectsBorder from './images/ongoing_projects_border.png'
import closeIcon from './images/close_icon.png'

export const HomeServicesBox = styled(Box)({
  minHeight: '650px',
  marginTop: '-104px',
  background: `url(${servicesBackgroundLeft}) no-repeat left center, url(${servicesBackground}) no-repeat right 0px top 104px / 100% 100%`,

  '@media (max-width: 1199px)': {
    minHeight: '1px',
    marginTop: '0px',
    marginBottom: '30px',
    background: `url(${servicesBackgroundLeft}) no-repeat center`,
  }
})

export const HomeServicesLeftBox = styled(Box)({
  height: '600px',
  position: 'relative',

  '@media (max-width: 1800px)': {
    marginTop: '50px',
  },
})

export const HomeServicesHexagonBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 203,
  height: 234,
  top: 185,
  left: 228,
  background: `url(${servicesHexagon}) no-repeat`,
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 20
}))

export const HomeServicesLabelBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, #074D78 0%, rgba(7, 77, 120, 0.5) 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',
}))

export const HomeServicesIconBox = styled(Box)({
  position: 'absolute',
  width: 94,
  height: 81,
  cursor: 'pointer',

  '@media (max-width: 1199px)': {
    position: 'static',
    width: '68px',
    height: '60px',
  }
})

export const HomeServicesTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  width: '220px',
  height: '100px',
  position: 'absolute',
  textTransform: theme.typography.mainmenu.textTransform,

  '@media (max-width: 1199px)': {
    position: 'static',
    width: 'auto',
    height: 'auto',
    paddingLeft: '20px',
  }
}))

export const HomeServicesDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  lineHeight: '24px',
  margin: '136px 0px 0px 0px',
  background: 'linear-gradient(116.17deg, rgba(7, 77, 120, 0.8) 0.95%, rgba(7, 77, 120, 0.05) 86.75%)',
  boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(2.5px)',
  WebkitBackdropFilter: 'blur(2.5px)',
  border: '#7da2b6 3px solid',
  borderRadius: '16px',
  padding: '40px',
  marginBottom: '30px',
  width: '90%',
  minHeight: '420px',
  position: 'relative',

  '@media (max-width: 1250px)': {
    marginLeft: '10%',
    padding: '30px',
  },

  '@media (max-width: 1199px)': {
    position: 'fixed',
    left: '50%',
    top: '50%',
    margin: '0px',
    transform: 'translate(-50%, -50%)',
    minHeight: '1px',
    background: 'linear-gradient(116.17deg, rgba(7, 77, 120, 0.8) 0.95%, rgba(7, 77, 120, 0.3) 86.75%)',
    boxShadow: '0px 4px 24px -1px rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
    padding: '50px 20px 20px 20px',
    zIndex: '101',
  }
}))

export const HomeServicesMobileItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '30px',
  cursor: 'pointer',
})

export const HomeServicesDescriptionCloseBox = styled(Box)({
  width: '32px',
  height: '32px',
  background: `url(${closeIcon}) no-repeat center`,
  cursor: 'pointer',
  position: 'absolute',
  top: '15px',
  right: '15px',
})

export const HomeServicesDescriptionTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  color: theme.palette.darkblue.main,
  marginBottom: '15px',
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',
}))

export const HomeProjectsBox = styled(Box)({
  minHeight: '552px',
  background: `url(${ongoingProjectsBackground}) no-repeat center / cover`
})

export const HomeProjectsLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,

  '@media (max-width: 1199px)': {
    textAlign: 'center',
    margin: '30px 0px',
  }
}))

export const HomeProjectsItemBox = styled(Box)({
  padding: '0 10%',
  minHeight: '266px',
  userSelect: 'text',
  WebkitUserSelect: 'text',
  background: `url(${ongoingProjectsBorder}) no-repeat right top`,

  '@media (max-width: 1199px)': {
    padding: '0px 20px',
  },

  '@media (max-width: 799px)': {
    background: 'none',
  }
})

export const HomeProjectsWrapperBox = styled(Box)({
  minHeight: '240px',

  '@media (max-width: 1430px)': {
    minHeight: '280px',
  },

  '@media (max-width: 1230px)': {
    minHeight: '300px',
  },

  '@media (max-width: 1199px)': {
    minHeight: '240px',
  },

  '@media (max-width: 799px)': {
    minHeight: '1px',
  }
})

export const HomeProjectsTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  paddingBottom: '35px',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const HomeProjectsDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.offwhite.main,
  paddingBottom: '35px'
}))

export const HomeProjectsDatesBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.offwhite.main,
}))

export const HomeProjectsItemMoreBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  color: theme.palette.secondary.main,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline'
  },
}))

export const HomePartnersBox = styled(Box)({
  height: '512px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const HomePartnersButtonBox = styled(Box)({
  width: '305px',
  height: '305px',
  position: 'relative',
  cursor: 'pointer'
})

export const HomePartnersStaticBox = styled(Box)(({ theme }) => ({
  width: '262px',
  height: '262px',
  background: `url(${partnersButton}) no-repeat center`,
  position: 'absolute',
  top: '21px',
  left: '21px',
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const HomePartnersOuterBox = styled(Box)({
  width: '305px',
  height: '305px',
  background: `url(${partnersButtonOuter}) no-repeat`,
  animation: 'rotation1 7s infinite linear',
  position: 'absolute',
  top: '0px',
  left: '0px',
  
  '@keyframes rotation1': {
    'from': {
      transform: 'rotate(0deg)'
    },
    'to': {
      transform: 'rotate(360deg)'
    }
  }
})

export const HomePartnersInnerBox = styled(Box)({
  width: '250px',
  height: '250px',
  background: `url(${partnersButtonInner}) no-repeat`,
  animation: 'rotation2 7s infinite linear',
  position: 'absolute',
  top: '27px',
  left: '27px',
  
  '@keyframes rotation2': {
    'from': {
      transform: 'rotate(360deg)'
    },
    'to': {
      transform: 'rotate(0deg)'
    }
  }
})

export const HomePartnersLogosBox = styled(Box)({
  width: '100%',
  minHeight: '504px',
  background: 'linear-gradient(182.15deg, rgba(122, 204, 255, 0.05) 23.19%, rgba(7, 77, 120, 0.05) 73.34%, rgba(7, 77, 120, 0.15) 97.43%)',
  margin: '56px 0px -48px 0px',
  position: 'relative',
  borderTop: '#6f94ab 2px solid',
  borderBottom: '#628aa3 2px solid',
  boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.2)',
  padding: '70px 0px',
  opacity: 0,
  transition: 'all 1s',

  '@media (max-width: 1199px)': {
    minHeight: '1px',
    padding: '20px',
    margin: '0px 0px 100px 0px',
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  }
})

export const HomePartnersGraphicsBox = styled(Box)({
  width: '100%',
  height: '504px',
  margin: '50px 0px -554px 0px',
  cursor: 'pointer',
  background: `url(${partnersIllustration}) no-repeat center`,
  position: 'relative',
  zIndex: '10',
})

export const HomePartnersPaginationBox = styled(Box)({
  width: '24px',
  height: '24px',
  cursor: 'pointer',
})

export const HomePartnersItemBox = styled(Box)(({ theme }) => ({
  height: '74px',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '4px',
  padding: '5px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#ffffff'
}))