import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { EdugainMembersCategoryBox } from '../../themes/boxEdugain'
import EdugainMembersItem from './EdugainMembersItem'

const EdugainMembersCategory = ({ index, members }) => {
  const type = index%2 ? 'idp' : 'sp'

  const membersFiltered = members.filter(item => item.type === type)

  return (
    <>
      <Grid xs={12} lg={5}>
        <EdugainMembersCategoryBox>
          {
            membersFiltered.map((item, index) => (
              <EdugainMembersItem key={index} {...item} />
            ))
          }
        </EdugainMembersCategoryBox>
      </Grid>
      { index%2 ===0 && <Grid lg={2}></Grid> }
    </> 
  )
}

export default EdugainMembersCategory