import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const FormCheckbox = ({ label, checked , required, error, handleChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} />}
      label={label}
      required={required}
      onChange={e => handleChange(e.target.checked)}
      sx={{ color: error && '#ff0000' }}
    />
  )
}

export default FormCheckbox