import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContent0Box } from '../../themes/boxLayout'
import { staffItems } from '../../data/staff'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiStaff } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import StaffItem from './StaffItem'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Staff = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [staff, setStaff] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchStaff = async () => {
      const response = await request(settings.domain + settings.api + apiStaff.getItems, 'GET')

      if (response && response.length > 0) {
        setStaff(response)
      } else {
        setStaff([])
      }
    }

    if (settings.mode === 'live') {
      fetchStaff()
    } else {
      setStaff(staffItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (staff.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [staff])

  return (
    <>
      <HelmetElement />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.staff[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContent0Box>
            <Grid container spacing={{ xs: 4, lg: 8 }}>
              {
                staff.map((item, index) => (
                  <StaffItem key={index} {...item} />
                ))
              }
            </Grid>
          </RegularPageContent0Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Staff