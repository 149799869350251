import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { EduroamHowToJoinUlBox, EduroamHowToJoinItemBox } from '../../themes/boxEduroam'

const EduroamHowToJoinItem = ({ item }) => {
  const lang = useSelector(state => state.lang)

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
      <EduroamHowToJoinUlBox></EduroamHowToJoinUlBox>
      <EduroamHowToJoinItemBox>{item[lang]}</EduroamHowToJoinItemBox>
    </Box>
  )
}

export default EduroamHowToJoinItem