import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { HomeProjectsBox, HomeProjectsLabelBox } from '../../themes/boxHome'
import { OnlyPaddingBox } from '../../themes/boxLayout'
import { HomeProjectsMoreButton } from '../../themes/button'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import useWindowDimensions from '../../helpers/dimensions'
import SlickSlider from '../layout/SlickSlider'
import HomeProjectsItem from './HomeProjectsItem'

const HomeProjects = ({ projects }) => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  let slidesToShowValue = 3

  if (width <= settings.desktopBreakpoint && width > 800) {
    slidesToShowValue = 2
  } else if (width <= settings.desktopBreakpoint) {
    slidesToShowValue = 1
  }

  const [slides, setSlides] = useState([])
  const [slidesToShow, setSlidesToShow] = useState(slidesToShowValue)
  
  const slidesToScroll = 1
  const sliderSpeed = 500

  const sxPrev = {
    position: 'absolute',
    top: '45%',
    left: '-25px',
    cursor: 'pointer',

    '@media (max-width: 1199px)': {
      top: '-70px',
      left: '20px',
    }
  }

  const sxNext = {
    position: 'absolute',
    top: '45%',
    right: '-25px',
    cursor: 'pointer',

    '@media (max-width: 1199px)': {
      top: '-70px',
      left: '70px',
    }
  }

  const sliderArrows = {
    leftIcon: <ArrowBackIcon color='offwhite' sx={sxPrev} />,
    leftStyle: {},
    leftFunction: '',
    rightIcon: <ArrowForwardIcon color='offwhite' sx={sxNext} />,
    rightStyle: {},
    rightFunction: '',
  }

  const handleClickAll = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open('/ongoing_projects', '_blank')
    } else if (e.type === 'click') {
      navigate('/ongoing_projects')
    }
  }

  useEffect(() => {
   if (width > settings.desktopBreakpoint) {
      setSlidesToShow(3)
    } else if (width > 800) {
      setSlidesToShow(2)
    } else {
      setSlidesToShow(1)
    }
  }, [width])
  
  useEffect(() => {
    let t = []
    projects.forEach((item, index) => {
      t.push(<HomeProjectsItem key={index} name={item.project_name[lang]} description={item.description[lang]} start_date={item.start_date} end_date={item.end_date} />)
    })
    setSlides(t)
  }, [projects, lang])
  
  return (
    <HomeProjectsBox>
      <Container maxWidth='xl'>
        <OnlyPaddingBox>
          {
            width > settings.desktopBreakpoint ? (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={0}
                sx={{ height: '170px' }}
              >
                <HomeProjectsLabelBox>{constants.ongoingProjects[lang]}</HomeProjectsLabelBox>
                <HomeProjectsMoreButton variant='outlined' endIcon={<ArrowForwardIcon />} disableRipple onClick={handleClickAll} onMouseDown={handleClickAll}>{constants.seeAll[lang]}</HomeProjectsMoreButton>
              </Stack>
            ) : (
              <>
                <HomeProjectsLabelBox>{constants.ongoingProjects[lang]}</HomeProjectsLabelBox>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '30px' }}>
                  <HomeProjectsMoreButton variant='outlined' endIcon={<ArrowForwardIcon />} disableRipple onClick={handleClickAll} onMouseDown={handleClickAll}>{constants.seeAll[lang]}</HomeProjectsMoreButton>
                </Box>
              </>
            )
          }
          <SlickSlider
            slides={slides}
            sxBody={{ position: 'relative', marginRight: '-1px' }}
            arrows={sliderArrows}
            pagination={{}}
            autoplay={false}
            draggable={false}
            speed={sliderSpeed}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            centerMode={false}
            handleAfterChange=''
          />
        </OnlyPaddingBox>
      </Container>
    </HomeProjectsBox>
  )
}

export default HomeProjects