export const companyInfoItems = [
  {
    id: '1',
    address: {
      ka: 'თ. ჭოველიძის ქუჩა 4ა, 0108 თბილისი, საქართველო',
      en: '4a Chovelidze street, 0108 Tbilisi, Georgia',
    },
    phone: '+995 32 225 05 90',
    email: 'contact@grena.ge',
  },
]

export const socialNetworksItems = [
  {
    id: '1',
    logo_url: 'facebook',
    website: 'https://www.facebook.com/grena.grena.ge/',
  },
  {
    id: '2',
    logo_url: 'twitter',
    website: 'https://twitter.com/G_R_E_N_A',
  },
]