import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { constants } from '../../data/constants'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const DomainPrices = ({ prices }) => {
  const theme = useTheme()

  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const tableWidth = width < 1100 ? '100%' : '1000px'

  const sxTableHeadCell = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    color: theme.palette.secondary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    textAlign: 'center',
    fontSize: width > settings.padBreakpoint ? '18px' : '16px',
  }

  const sxTableHeadCellWithBorder = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    color: theme.palette.secondary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    textAlign: 'center',
    fontSize: width > settings.padBreakpoint ? '18px' : '16px',
    borderRight: 'rgba(224, 224, 224, 1) 1px solid',
  }

  const sxTableHeadCell2 = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    color: theme.palette.secondary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    textAlign: 'center',
    fontSize: width > settings.padBreakpoint ? '16px' : '14px',
  }

  const sxTableBodyLabelCell = {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: width > settings.padBreakpoint ? '16px' : '12px',
    borderRight: 'rgba(224, 224, 224, 1) 1px solid',
  }

  const sxTableBodyCell = {
    fontFamily: theme.typography.regular.fontFamily,
    textAlign: 'center',
    fontSize: width > settings.padBreakpoint ? '16px' : '12px',
  }

  const sxTableBodyCellWithBorder = {
    fontFamily: theme.typography.regular.fontFamily,
    textAlign: 'center',
    fontSize: width > settings.padBreakpoint ? '16px' : '12px',
    borderRight: 'rgba(224, 224, 224, 1) 1px solid',
  }
  
  return (
    <>
      <TableContainer sx={{ width: tableWidth }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={sxTableHeadCellWithBorder}></TableCell>
              <TableCell sx={sxTableHeadCellWithBorder} colSpan={2}>{constants.register[lang]}</TableCell>
              <TableCell sx={sxTableHeadCellWithBorder} colSpan={2}>{constants.renew[lang]}</TableCell>
              <TableCell sx={sxTableHeadCell}>{constants.transfer[lang]}</TableCell>
            </TableRow>
            <TableRow sx={{ background: 'rgba(224, 224, 224, 1)' }}>
              <TableCell></TableCell>
              <TableCell sx={sxTableHeadCell2}>1 {constants.year[lang]}</TableCell>
              <TableCell sx={sxTableHeadCell2}>2-5 {constants.years[lang]}</TableCell>
              <TableCell sx={sxTableHeadCell2}>1 {constants.year[lang]}</TableCell>
              <TableCell sx={sxTableHeadCell2}>2-5 {constants.years[lang]}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              prices.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={sxTableBodyLabelCell}>{item.title[lang]}</TableCell>
                  <TableCell sx={sxTableBodyCell}>
                    { (item.registration_price.length > 0 && parseInt(item.registration_price[0]) !== 0) ? item.registration_price[0] + ' ' + constants.gel[lang] : constants.priceFree[lang] }
                  </TableCell>
                  <TableCell sx={sxTableBodyCellWithBorder}>
                    { (item.registration_price.length > 1 && parseInt(item.registration_price[1]) !== 0) ? item.registration_price[1] + ' ' + constants.gel[lang] : constants.priceFree[lang] }
                  </TableCell>
                  <TableCell sx={sxTableBodyCell}>
                    { (item.prolong_price.length > 0 && parseInt(item.prolong_price[0]) !== 0) ? item.prolong_price[0] + ' ' + constants.gel[lang] : constants.priceFree[lang] }
                  </TableCell>
                  <TableCell sx={sxTableBodyCellWithBorder}>
                    { (item.prolong_price.length > 1 && parseInt(item.prolong_price[1]) !== 0) ? item.prolong_price[1] + ' ' + constants.gel[lang] : constants.priceFree[lang] }
                  </TableCell>
                  <TableCell sx={sxTableBodyCell}>
                    { (item.name !== '.გე' && item.transfer_price.length > 0 && parseInt(item.transfer_price[0]) !== 0) && item.transfer_price[0] + ' ' + constants.gel[lang] }

                    { (item.name !== '.გე' && (item.transfer_price.length === 0 || parseInt(item.transfer_price[0]) === 0)) && constants.priceFree[lang] }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>    
  )
}

export default DomainPrices