import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import DomainSearchResultSimilarItem from './DomainSearchResultSimilarItem'

const DomainSearchResultSimilar = ({ whois, domains, specialDomains, prices }) => {
  return (
    <Grid container rowSpacing={4} columnSpacing={{ xs: 2, lg: 8 }} sx={{ marginBottom: '50px' }}>
      {
        whois.similarDomains.map((item, index) => (
          <DomainSearchResultSimilarItem key={index} {...item} domains={domains} specialDomains={specialDomains} prices={prices} />
        ))
      }
    </Grid>
  )
}

export default DomainSearchResultSimilar