import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'

export const NewsItemBox = styled(Box)({
  background: '#7396A9',
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '30px',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:hover': {
    background: alpha('#7396A9', 0.9),
  },
})

export const NewsItemImageBox = styled(Box)({
  width: '100%',
  height: '170px',
  borderRadius: '8px',
})

export const NewsItemDateBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  paddingBottom: '16px',
  fontWeight: 'bold',
}))

export const NewsItemTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  paddingBottom: '40px',
  textTransform: theme.typography.mainmenu.textTransform,
}))

export const NewsPageItemBox = styled(Box)(({ theme }) => ({
  padding: '30px',
  borderTop: `${theme.palette.primary.main} 1px solid`,
  borderBottom: `${theme.palette.primary.main} 1px solid`,
  borderLeft: `${theme.palette.primary.main} 4px solid`,
  borderRight: `${theme.palette.primary.main} 4px solid`,
  borderRadius: '8px',
  background: '#ffffff',

  '@media (max-width: 599px)': {
    height: 'auto',
  }
}))