import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import { InternetPageTextBox } from '../../themes/boxInternet'
import { internetIllustration2Image } from '../../themes/image'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const InternetPage = ({ description }) => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  return (
    <Grid container spacing={{ xs: 0, lg: 6 }}>
      <Grid lg={6}>
        {
          description.map((item, index) => (
            <InternetPageTextBox key={index}>{item[lang]}</InternetPageTextBox>
          ))
        }
      </Grid>
      <Grid lg={6}>
        {
          width > settings.desktopBreakpoint && (
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <img src={internetIllustration2Image} alt='' style={{ maxWidth: '100%' }} />
            </Box>
          )
        }
      </Grid>
    </Grid>
  )
}

export default InternetPage