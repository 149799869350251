import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const CertLeftBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  lineHeight: '24px',
  padding: '50px',
  border: '#476E84 4px solid',
  borderRadius: '8px',
  marginBottom: '50px',
  transition: 'all 0.5s',
  height: '400px',
}))