import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { StaffItemBox, StaffImageBox, StaffNameBox, StaffPositionBox } from '../../themes/boxStaff'
import { avatarImage } from '../../themes/image'
import settings from '../../helpers/settings'

const StaffItem = ({ name, photo_url, position }) => {
  const lang = useSelector(state => state.lang)

  return (
    <Grid xs={12} md={6} lg={5}>
      <StaffItemBox>
        <Grid container spacing={{ xs: 2, lg: 4}}>
          <Grid xs={12} sm={4} md={5}>
            <StaffImageBox>
              {
                (photo_url && photo_url !== '-') ? (
                  <img src={ settings.s3URL + settings.s3Bucket + photo_url } alt='' style={{ maxWidth: '100%', maxHeight: '150px', borderRadius: '8px' }} />
                ) : (
                  <img src={avatarImage} alt='' style={{ maxWidth: '100%', borderRadius: '8px' }} />
                )
              }
            </StaffImageBox>
          </Grid>
          <Grid xs={12} sm={8} md={7}>
            <StaffNameBox>{name[lang]}</StaffNameBox>
            <StaffPositionBox>{position[lang]}</StaffPositionBox>
          </Grid>
        </Grid>
      </StaffItemBox>
    </Grid>
  )
}

export default StaffItem