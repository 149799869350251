import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'

const ErrorMessage = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box sx={{ fontSize: '32px' }}>Oops! Something went wrong.</Box>
    </Backdrop>
  )
}

export default ErrorMessage