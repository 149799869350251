import React from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const NewsPagination = ({ currentPage, pages, handleClick }) => {
  const theme = useTheme()

  let pagesArray = []
  for (var i = 1; i <= pages; i++) {
    pagesArray.push(i)
  }

  let k1 = false
  let k2 = false
  let t1 = true
  let t2 = true

  const sxArrow = {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginTop: '7px !important',

    '&:hover': {
      color: '#2E9CD6',
    }
  }

  const sxArrowActive = {
    color: '#2E9CD6',
    marginTop: '7px',
  }

  const sxNumber = {
    cursor: 'pointer',
    fontFamily: theme.typography.roboto.fontFamily,
    fontSize: '20px',
    color: theme.palette.darkblue.main,
    borderBottom: 'transparent 2px solid',

    '&:hover': {
      color: '#2E9CD6',
      borderBottom: '#2E9CD6 2px solid',
    }
  }

  const sxNumberActive = {
    cursor: 'default',
    fontFamily: theme.typography.roboto.fontFamily,
    fontSize: '20px',
    color: '#2E9CD6',
    borderBottom: '#2E9CD6 2px solid',
  }

  const sxDots = {
    cursor: 'default',
    fontFamily: theme.typography.roboto.fontFamily,
    fontSize: '20px',
    color: theme.palette.darkblue.main,
    borderBottom: 'transparent 2px solid',
  }

  const handlePrev = () => {
    if (currentPage !== 1) handleClick(currentPage - 1)
  }

  const handleNext = () => {
    if (currentPage !== pages) handleClick(currentPage + 1)
  }

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{ width: '90%' }}
    >
      <Box sx={ currentPage === 1 ? sxArrowActive : sxArrow } onClick={handlePrev}>
        <ArrowBackIcon fontSize='large' />
      </Box>
      {
        pagesArray.map((item) => {
          if ((item + 5) < currentPage) k1 = true
			    if ((item - 5) >= currentPage) k2 = true

          if ( item === 1 || item === pages || ((item + 5) > currentPage && (item - 5) < currentPage)) {
            return (
              <Box key={item} sx={ currentPage === item ? sxNumberActive : sxNumber } onClick={() => handleClick(item)}>{item}</Box>
            )
          } else if (t1 && k1) {
            t1 = false
            return (
              <Box key={item} sx={sxDots}>...</Box>
            )
          } else if (t2 && k2) {
            t2 = false
            return (
              <Box key={item} sx={sxDots}>...</Box>
            )
          }

          return ''
        })
      }
      <Box sx={ currentPage === pages ? sxArrowActive : sxArrow } onClick={handleNext}>
        <ArrowForwardIcon fontSize='large' />
      </Box>
    </Stack>
  )
}

export default NewsPagination