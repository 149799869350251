import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { InternetServicesCategoryItemBox, InternetServicesCategoryTitleBox } from '../../themes/boxInternet'
import { sortByPriority } from '../../helpers/array'
import InternetServicesItem from './InternetServicesItem'

const InternetServicesCategory = ({ id, title, services }) => {
  const lang = useSelector(state => state.lang)

  const servicesFiltered = sortByPriority(services.filter(item => item.category === id))

  return (
    <Grid xs={12} md={4}>
      <InternetServicesCategoryItemBox>
        <InternetServicesCategoryTitleBox>{title[lang]}</InternetServicesCategoryTitleBox>
        {
          servicesFiltered.map((item, index) => (
            <InternetServicesItem key={index} {...item} />
          ))
        }
      </InternetServicesCategoryItemBox>
    </Grid>
  )
}

export default InternetServicesCategory