import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContent0Box } from '../../themes/boxLayout'
import { hostingPackageItems, vpsAdvantagesItems } from '../../data/hosting'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiHosting } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import useWindowDimensions from '../../helpers/dimensions'
import HostingPackages from './HostingPackages'
import HostingAdvantages from './HostingAdvantages'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const Hosting = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [packages, setPackages] = useState([])
  const [advantages, setAdvantages] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  useEffect(() => {
    const fetchPackages = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getHostingPackages, 'GET')

      if (response && response.length > 0) {
        setPackages(sortByPriority(response))
      } else {
        setPackages([])
      }
    }

    const fetchAdvantages = async () => {
      const response = await request(settings.domain + settings.api + apiHosting.getAdvantages, 'GET')

      if (response && response.length > 0) {
        setAdvantages(response)
      } else {
        setAdvantages([])
      }
    }

    if (settings.mode === 'live') {
      fetchPackages()
      fetchAdvantages()
    } else {
      setPackages(hostingPackageItems)
      setAdvantages(vpsAdvantagesItems)
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [])

  useEffect(() => {
    if (packages.length > 0 && advantages.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [packages, advantages])

  return (
    <>
      <HelmetElement
        metaDescription='Web / Mail Hosting, Dedicated secure datacenter in Tbilisi, Modern servers, SSD disks with high IOPS, clustered environment, Cyber security modern systems'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.hosting[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContent0Box>
            <HostingPackages packages={packages} />
            <HostingAdvantages advantages={advantages} />
          </RegularPageContent0Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default Hosting