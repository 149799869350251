import React from 'react'
import Box from '@mui/material/Box'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const EducationClassroomItem = ({ photo_url, handleOpenModal }) => {
  const { width } = useWindowDimensions()

  const sxItem = width > settings.desktopBreakpoint ? { padding: '0px 28px' } : { padding: '0px', margin: '0px 10px' }

  return (
    <Box sx={sxItem}>
      <Box
        sx={{
          height: '264px',
          borderRadius: '8px',
          background: `url(${ settings.s3URL + settings.s3Bucket + photo_url }) no-repeat center / cover`,
          cursor: 'pointer',
        }}
        onClick={() => handleOpenModal(photo_url)}
      >
      </Box>
    </Box>
  )
}

export default EducationClassroomItem