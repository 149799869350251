import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'

export const EduroamMembersHeadTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  textAlign: 'center',
  height: '76px',
  border: 'none',
  background: 'rgba(7, 77, 120, 0.7)',

  '@media(max-width: 1024px)': {
    fontSize: '14px',
    height: '40px',
  }
}))

export const EduroamMembersBodyTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  height: '120px',
  borderBottom: `${theme.palette.secondary.main} 1px solid`,

  '@media(max-width: 1024px)': {
    height: '60px',
  }
}))