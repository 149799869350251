import React from 'react'
import { useSelector } from 'react-redux'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { HomeProjectsItemBox, HomeProjectsWrapperBox, HomeProjectsTitleBox, HomeProjectsDescriptionBox, HomeProjectsDatesBox } from '../../themes/boxHome'
import { formatProjectDate } from '../../helpers/dates'
import { shortenString } from '../../helpers/string'

const HomeProjectsItem = ({ name, description, start_date, end_date }) => {
  const lang = useSelector(state => state.lang)

  return (
    <HomeProjectsItemBox>
      <HomeProjectsWrapperBox>
        <HomeProjectsTitleBox>{name}</HomeProjectsTitleBox>
        <HomeProjectsDescriptionBox>{shortenString(description, 20)}...</HomeProjectsDescriptionBox>
      </HomeProjectsWrapperBox>
      <HomeProjectsDatesBox>{formatProjectDate(start_date, end_date, lang)}&nbsp;&nbsp;&nbsp;<ScheduleIcon fontSize='small' sx={{ marginBottom: '-5px' }} /></HomeProjectsDatesBox>
    </HomeProjectsItemBox>
  )
}

export default HomeProjectsItem