import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import certificationIcon from './images/certification_icon.png'
import instructorAvatarBackground from './images/istructor_avatar_background.png'
import mobileHexStart from './images/education_mob_hex_start.png'
import mobileHexEnd from './images/education_mob_hex_end.png'

export const EducationAcademiesBox = styled(Box)({
  width: '620px',
  position: 'relative',

  '@media (max-width: 899px)': {
    width: '100%',
  }
})

export const EducationAcademyLogoBox = styled(Box)({
  height: '140px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4px',
})

export const EducationAcademyTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '20px',
  color: theme.palette.darkblue.main,
  height: '140px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4px',
  textTransform: theme.typography.mainmenu.textTransform,

  '@media (max-width: 899px)': {
    marginLeft: '40px',
    fontSize: '16px',
  }
}))

export const EducationAcademyMobileStartBox = styled(Box)({
  width: '26px',
  height: '82px',
  position: 'absolute',
  top: '0px',
  left: '0px',
  background: `url(${mobileHexStart}) no-repeat`,
})

export const EducationAcademyMobileEndBox = styled(Box)({
  width: '26px',
  height: '82px',
  position: 'absolute',
  top: '0px',
  right: '0px',
  background: `url(${mobileHexEnd}) no-repeat`,
})

export const EducationAcademyMobileCenterBox = styled(Box)(({ theme }) => ({
  height: '82px',
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  borderWidth: '2px 0px 2px 0px',
  margin: '0px 26px 0px 26px',
  background: `url(${mobileHexEnd}) no-repeat left 70px top`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))

export const EducationRegistrationAlertBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  width: '312px',
  textAlign: 'center',
  marginTop: '64px',
  padding: '16px 30px 16px 60px',
  background: '#04AB3D',
  boxShadow: '4px 4px 5px rgba(0, 0, 0, 0.25)',
  borderRadius: '0px 8px 8px 0px',
  cursor: 'pointer',

  '@media (max-width: 1300px)': {
    width: '250px',
  },

  '@media (max-width: 1199px)': {
    width: 'auto',
    borderRadius: '8px',
    margin: '0px 20% 30px 20%',
  },

  '@media (max-width: 600px)': {
    width: 'auto',
    margin: '0px 15px 30px 15px',
  }
}))

export const EducationCertificationContainerBox = styled(Box)({
  minHeight: '340px',
  background: 'rgba(115, 150, 169, 0.7)',
  marginBottom: '100px',

  '@media (max-width: 1199px)': {
    minHeight: '1px',
    marginBottom: '50px',
  }
})

export const EducationCertificationLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',
  minHeight: '340px',
  background: `url(${certificationIcon}) no-repeat center top 56px`,
  paddingTop: '285px',
  textAlign: 'center',
  borderLeft: `${theme.palette.primary.main} 2px solid`,
  borderRight: `${theme.palette.primary.main} 2px solid`,

  '@media (max-width: 1199px)': {
    minHeight: '400px',
  },

  '@media (max-width: 899px)': {
    minHeight: '98px',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid rgba(6, 14, 54, 0.3)',
    background: `url(${certificationIcon}) no-repeat left 30% center / auto 58px`,
    paddingTop: '40px',
  },

  '@media (max-width: 699px)': {
    background: `url(${certificationIcon}) no-repeat left 20% center / auto 58px`,
  },

  '@media (max-width: 499px)': {
    background: `url(${certificationIcon}) no-repeat left 10% center / auto 58px`,
  },
}))

export const EducationCertificationLogoBox = styled(Box)({
  minHeight: '340px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 1199px)': {
    minHeight: '40px',
    margin: '20px 0px 0px 0px',
  }
})

export const EducationCertificationTextBox = styled(Box)({
  minHeight: '340px',

  '@media (max-width: 1199px)': {
    minHeight: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '30px',
  }
})

export const EducationCertificationTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  color: '#0180A3',
  padding: '55px 0px 20px 0px',

  '@media (max-width: 1199px)': {
    textAlign: 'center',
  }
}))

export const EducationCertificationDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.primary.main,
  lineHeight: '24px',
  paddingBottom: '20px',

  '@media (max-width: 1199px)': {
    textAlign: 'center',
  }
}))

export const EducationClassroomLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  paddingBottom: '50px',
  textAlign: 'center',

  '@media (max-width: 1024px)': {
    fontSize: '20px',
    paddingBottom: '30px',
  }
}))

export const EducationInstructorBox = styled(Box)(({ theme }) => ({
  minHeight: '352px',
  background: `${theme.palette.primary.main}`,
  borderRadius: '8px',
}))

export const EducationInstructorNameBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.offwhite.main,
  textTransform: theme.typography.mainmenu.textTransform,
  paddingBottom: '35px',

  '@media (max-width: 1024px)': {
    fontSize: '18px',
    paddingBottom: '20px',
  },

  '@media (max-width: 400px)': {
    minHeight: '75px',
  },
}))

export const EducationInstructorBioBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.offwhite.main,
  lineHeight: '24px',

  '@media (max-width: 1800px)': {
    fontSize: '15px',
    lineHeight: '20px',
  },

  '@media (max-width: 1270px)': {
    fontSize: '13px',
  },

  '@media (max-width: 1199px)': {
    fontSize: '15px',
    minHeight: '300px',
  },

  '@media (max-width: 999px)': {
    minHeight: '400px',
  },

  '@media (max-width: 899px)': {
    minHeight: '300px',
  },

  '@media (max-width: 599px)': {
    minHeight: '400px',
  },

  '@media (max-width: 425px)': {
    minHeight: '550px',
  },

  '@media (max-width: 375px)': {
    minHeight: '700px',
  },

  '@media (max-width: 360px)': {
    fontSize: '14px',
    minHeight: '900px',
  },
}))

export const EducationInstructorAvatarBox = styled(Box)({
  height: '350px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${instructorAvatarBackground}) no-repeat right bottom`,
  paddingLeft: '10%',
  margin: '1px 1px 0px 0px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',

  '@media (max-width: 1024px)': {
    height: '320px',
    background: '#D9DBD6',
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
    borderBottomRightRadius: '0px',
    padding: '0px 10px',
    margin: '0px',
  },

  '@media (max-width: 420px)': {
    height: '240px',
  }
})

export const EducationInstructorPhotoBox = styled(Box)({
  width: '240px',
  height: '240px',
  borderRadius: '120px',
})

export const AcademyLeftBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  padding: '5%',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '8px',
  marginBottom: '100px',

  '@media (max-width: 1199px)': {
    border: 'none',
    padding: '0px',
    marginBottom: '50px',
  }
}))

export const AcademyRightBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const AcademyCoursesLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.labels.fontSize,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  paddingBottom: '24px',
  textAlign: 'center',
}))

export const AcademyCoursesItemBox = styled(Box)(({ theme }) => ({
  marginBottom: '50px',
  background: `${theme.palette.primary.main}`,
  borderRadius: '8px',
  color: theme.palette.offwhite.main,
  padding: '50px',
  position: 'relative',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:hover': {
    background: 'rgba(7, 77, 120, 0.9)',
  },

  '@media (max-width: 899px)': {
    padding: '50px 30px 30px 30px',
  }
}))

export const AcademyCoursesTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '32px',
  lineHeight: '40px',
  borderBottom: `${theme.palette.offwhite.main} 1px solid`,
  paddingBottom: '15px',
  marginBottom: '30px',

  '@media (max-width: 1199px)': {
    fontSize: '28px',
    lineHeight: '34px',
  },

  '@media (max-width: 899px)': {
    fontSize: '24px',
    lineHeight: '32px',
  }
}))

export const AcademyCoursesInfoBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '24px',
  paddingBottom: '15px',
}))

export const AcademyCoursesRegistrationOpenBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.regular.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '22px',
  background: '#04AB3D',
  borderRadius: '0px 8px 0px 8px',
  width: '211px',
  padding: '15px 0px',
  float: 'right',
  textAlign: 'center',
  position: 'absolute',
  top: '0px',
  right: '0px',

  '@media (max-width: 899px)': {
    width: '80%',
    right: '10%',
    borderRadius: '0px 0px 8px 8px',
  }
}))

export const AcademyCoursesPriceLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '24px',
  paddingTop: '80px',
  textAlign: 'center',

  '@media (max-width: 899px)': {
    paddingTop: '40px',
  }
}))

export const AcademyCoursesPriceValueBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  fontSize: '48px',
  padding: '0px 0px 30px 0px',
  textAlign: 'center',
}))

export const CourseSummaryBox = styled(Box)(({ theme }) => ({
  marginBottom: '50px',
  background: '#C2CFD7',
  color: theme.palette.darkblue.main,
  padding: '50px',

  '@media(max-width: 1800px)': {
    padding: '10px 20px 30px 20px',
  }
}))

export const CourseInfoBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '24px',
  textAlign: 'center',
  paddingTop: '30px',

  '@media(max-width: 1199px)': {
    fontSize: '14px',
  },

  '@media(max-width: 899px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
}))

export const CoursePriceLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  textTransform: theme.typography.mainmenu.textTransform,
  lineHeight: '24px',
  paddingTop: '20px',
  textAlign: 'center',

  '@media (max-width: 899px)': {
    paddingTop: '20px',
  }
}))

export const CoursePriceValueBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.roboto.fontFamily,
  fontSize: '48px',
  paddingBottom: '30px',
  textAlign: 'center',

  '@media(max-width: 1800px)': {
    fontSize: '36px',
    paddingBottom: '20px',
  }
}))

export const CourseInfoTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.secondary.main,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  lineHeight: '28px',
  borderWidth: '0px 0px 8px 0px', 
  borderColor: 'rgba(6, 14, 54, 0.3)',
  borderStyle: 'solid',
  marginBottom: '30px',
}))

export const CourseInfoUlBox = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '8px',
  height: '8px',
  margin: '9px 12px 30px 0px',
}))

export const CourseInfoDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: theme.typography.mainmenu.fontFamily,
  lineHeight: '24px',
}))

export const CourseSyllabusLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  paddingTop: '14px',
}))