import { IconContext } from 'react-icons'
import SpeedIcon from '@mui/icons-material/Speed'
import SearchIcon from '@mui/icons-material/Search'
import ListIcon from '@mui/icons-material/List'
import { AiOutlineBarChart } from 'react-icons/ai'

export const mirrorsItems = [
  {
    id: '',
    title: { ka: 'CentOS Mirror', en: 'CentOS Mirror', },
    icon_url: '',
    tool_url: 'https://centos.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Epel Mirror', en: 'Epel Mirror', },
    icon_url: '',
    tool_url: 'http://epel.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Rocky Mirror', en: 'Rocky Mirror', },
    icon_url: '',
    tool_url: 'https://rocky.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Debian Mirror', en: 'Debian Mirror', },
    icon_url: '',
    tool_url: 'http://debian.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Ubuntu Mirror', en: 'Ubuntu Mirror', },
    icon_url: '',
    tool_url: 'https://ubuntu.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Arch Mirror', en: 'Arch Mirror', },
    icon_url: '',
    tool_url: 'https://arch.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Manjaro Mirror', en: 'Manjaro Mirror', },
    icon_url: '',
    tool_url: 'https://manjaro.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'MX Linux Mirror', en: 'MX Linux Mirror', },
    icon_url: '',
    tool_url: 'https://mxlinux.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
]

export const toolbarItems = [
  {
    id: '',
    title: { ka: 'Speed Test', en: 'Speed Test', },
    icon_url: 'speedtest',
    tool_url: 'http://grena.speedtest.net/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Looking Glass', en: 'Looking Glass', },
    icon_url: 'lookingglass',
    tool_url: 'https://lg.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Perfsonar', en: 'Perfsonar', },
    icon_url: 'perfsonar',
    tool_url: 'http://perfsonar.grena.ge/',
    has_submenu: false,
    submenu_id: '',
  },
  {
    id: '',
    title: { ka: 'Public Mirror', en: 'Public Mirror', },
    icon_url: 'publicmirror',
    tool_url: '',
    has_submenu: true,
    submenu_id: 'mirrors',
  },
]

export const toolbarOptions = [
  {
    name: 'speedtest',
    icon_url: <SpeedIcon sx={{ marginBottom: '-6px' }} />,
    icon_url_hover: <SpeedIcon fontSize='large' sx={{ marginBottom: '-10px' }} />,
  },
  {
    name: 'lookingglass',
    icon_url: <SearchIcon sx={{ marginBottom: '-8px' }} />,
    icon_url_hover: <SearchIcon fontSize='large' sx={{ marginBottom: '-8px' }} />,
  },
  {
    name: 'perfsonar',
    icon_url: (
      <IconContext.Provider value={{ color: '#DDDDDD', size: '22px' }}>
        <AiOutlineBarChart style={{ marginTop: '6px' }} />
      </IconContext.Provider>
      ),
    icon_url_hover: (
      <IconContext.Provider value={{ color: '#DDDDDD', size: '34px' }}>
        <AiOutlineBarChart style={{ marginTop: '12px' }} />
      </IconContext.Provider>
      ),
  },
  {
    name: 'publicmirror',
    icon_url: <ListIcon sx={{ marginBottom: '-6px' }} />,
    icon_url_hover: <ListIcon fontSize='large' sx={{ marginBottom: '-10px' }} />,
  },
]