import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MainMenuButton } from '../../themes/button'
import { MainMenuMobileItemBox } from '../../themes/boxLayout'
import { setBurgerRight } from '../../reducers/burgerRight'
import useWindowDimensions from '../../helpers/dimensions'
import settings from '../../helpers/settings'

const MainMenuItem = ({ title, go_to_url }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)
  
  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(go_to_url, '_blank')
    } else if (e.type === 'click') {
      navigate(go_to_url)
      dispatch(setBurgerRight('-100%'))
    }
  }
  
  return (
    <>
      {
        width > settings.desktopBreakpoint ? (
          <MainMenuButton variant='text' disableRipple onClick={handleClick} onMouseDown={handleClick}>{title[lang]}</MainMenuButton>
        ) : (
          <MainMenuMobileItemBox sx={{ borderBottom: '1px solid rgba(221, 221, 221, 0.6)' }} onClick={handleClick} onMouseDown={handleClick}>{title[lang]}</MainMenuMobileItemBox>
        )
      }
    </>
    
  )
}

export default MainMenuItem