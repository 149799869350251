import { createSlice } from '@reduxjs/toolkit'

const initialState = 'ka'

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang: (state, action) => {
      return action.payload
    }
  }
})

export const { setLang } = langSlice.actions

export default langSlice.reducer