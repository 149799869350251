import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const FormText = ({
  type,
  label,
  placeholder,
  value,
  required,
  lineNumber,
  error,
  helpertext,
  handleChange,
  fixedWidth,
  startAdornment,
  endAdornment,
}) => {
  const theme = useTheme()

  const fieldStyles = {
    width: fixedWidth ? fixedWidth : '100%',

    '& .MuiInputBase-input': {
      fontFamily: theme.typography.regular.fontFamily,
    }
  }

  const labelStyles = {
    display: 'inline',
    fontFamily: theme.typography.regular.fontFamily,
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const passwordEndAdornment = (
    <InputAdornment position='end'>
      <IconButton
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
      >
        { showPassword ? <VisibilityOff /> : <Visibility /> }
      </IconButton>
    </InputAdornment>
  )

  return (
    <TextField
      type={ (type === 'password' && !showPassword) ? 'password' : 'text' }
      label={label && <Box sx={labelStyles}>{label}</Box>}
      placeholder={placeholder}
      required={required}
      error={error}
      helperText={helpertext ? helpertext : ''}
      value={value}
      multiline={ lineNumber > 1 }
      rows={lineNumber}
      onChange={e => handleChange(e.target.value)}
      variant='outlined'
      InputProps={{
        startAdornment,
        endAdornment: type === 'password' ? passwordEndAdornment : endAdornment,
        sx: { borderRadius: '8px', },
      }}
      sx={fieldStyles}
    />
  )
}

export default FormText