import {
  foundersPic1Image,
  foundersPic2Image,
} from '../themes/image'

export const foundersItems = [
  {
    id: '',
    name: { ka: 'ივანე ჯავახიშვილის სახელობის თბილისის სახელმწიფო უნივერსიტეტი', en: 'Ivane Javakhishvili Tbilisi State University', },
    logo_url: foundersPic1Image,
    website: 'https://www.tsu.ge/',
    representative: { ka: 'ზურაბ მოდებაძე', en: 'Zurab Modebadze', },
    is_director: true,
  },
  {
    id: '',
    name: { ka: 'ღია საზოგადოების ფონდი', en: 'Open Society Gerogia Foundation', },
    logo_url: foundersPic2Image,
    website: 'https://osgf.ge/',
    representative: { ka: 'ქეთევან ხუციშვილი', en: 'Ketevan Khutsishvili', },
    is_director: false,
  },
  {
    id: '',
    name: { ka: 'ივანე ჯავახიშვილის სახელობის თბილისის სახელმწიფო უნივერსიტეტი', en: '', },
    logo_url: foundersPic1Image,
    website: 'https://www.tsu.ge/',
    representative: { ka: 'ზურაბ მოდებაძე', en: '', },
    is_director: false,
  },
  {
    id: '',
    name: { ka: 'ღია საზოგადოების ფონდი', en: '', },
    logo_url: foundersPic2Image,
    website: 'https://osgf.ge/',
    representative: { ka: 'ქეთევან ხუციშვილი', en: '', },
    is_director: false,
  },
  {
    id: '',
    name: { ka: 'ივანე ჯავახიშვილის სახელობის თბილისის სახელმწიფო უნივერსიტეტი', en: '', },
    logo_url: foundersPic1Image,
    website: 'https://www.tsu.ge/',
    representative: { ka: 'ზურაბ მოდებაძე', en: '', },
    is_director: false,
  },
  {
    id: '',
    name: { ka: 'ღია საზოგადოების ფონდი', en: '', },
    logo_url: foundersPic2Image,
    website: 'https://osgf.ge/',
    representative: { ka: 'ქეთევან ხუციშვილი', en: '', },
    is_director: false,
  },
  {
    id: '',
    name: { ka: 'ღია საზოგადოების ფონდი', en: '', },
    logo_url: foundersPic2Image,
    website: 'https://osgf.ge/',
    representative: { ka: 'ქეთევან ხუციშვილი', en: '', },
    is_director: false,
  },
]