import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBurgerRight } from '../../reducers/burgerRight'
import Box from '@mui/material/Box'
import { HeaderMobileBox, BurgerMenuIconBox, CloseIconBox, MobileMenuBox, MobileMenuHeaderBox } from '../../themes/boxLayout'
import Logo from './Logo'
import MainMenu from './MainMenu'
import Language from './Language'
import HeaderProfileButton from './HeaderProfileButton'

const HeaderMobile = () => {
  const dispatch = useDispatch()

  const burgerRight = useSelector(state => state.burgerRight)

  const handleMenuOpen = () => {
    dispatch(setBurgerRight('0px'))
  }

  const handleMenuClose = () => {
    dispatch(setBurgerRight('-100%'))
  }

  useEffect(() => {
    dispatch(setBurgerRight('-100%'))
  }, [dispatch])

  return (
    <HeaderMobileBox>
      <Logo />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <BurgerMenuIconBox onClick={handleMenuOpen}></BurgerMenuIconBox>
      </Box>
      <MobileMenuBox sx={{ right: burgerRight }}>
        <MobileMenuHeaderBox>
          <Logo />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Language />
            <CloseIconBox onClick={handleMenuClose}></CloseIconBox>
          </Box>
        </MobileMenuHeaderBox>
        <MainMenu />
        <HeaderProfileButton />
      </MobileMenuBox>
    </HeaderMobileBox>
  )
}

export default HeaderMobile