import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { InternetServicesUlBox, InternetServicesDescriptionBox } from '../../themes/boxInternet'

const InternetServicesitem = ({ title }) => {
  const lang = useSelector(state => state.lang)

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
      <InternetServicesUlBox></InternetServicesUlBox>
      <InternetServicesDescriptionBox>{title[lang]}</InternetServicesDescriptionBox>
    </Box>
  )
}

export default InternetServicesitem