import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const StaffItemBox = styled(Box)(({ theme }) => ({
  height: '182px',
  padding: '15px 28px',
  borderTop: `${theme.palette.primary.main} 1px solid`,
  borderBottom: `${theme.palette.primary.main} 1px solid`,
  borderLeft: `${theme.palette.primary.main} 4px solid`,
  borderRight: `${theme.palette.primary.main} 4px solid`,
  borderRadius: '8px',
  background: '#ffffff',

  '@media (max-width: 599px)': {
    height: 'auto',
  }
}))

export const StaffImageBox = styled(Box)({
  height: '152px',
  padding: '0px',

  '@media (max-width: 599px)': {
    textAlign: 'center',
  }
})

export const StaffNameBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.labels.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  padding: '16px 0px 20px 0px',
  textTransform: theme.typography.mainmenu.textTransform,
  fontWeight: 'bold',

  '@media (max-width: 599px)': {
    padding: '0px 0px 5px 0px',
    textAlign: 'center',
  }
}))

export const StaffPositionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: theme.typography.mainmenu.fontSize,
  color: theme.palette.darkblue.main,
  lineHeight: '24px',
  textTransform: theme.typography.mainmenu.textTransform,

  '@media (max-width: 599px)': {
    padding: '0px 0px 0px 0px',
    textAlign: 'center',
  }
}))