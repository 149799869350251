import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { VpsServicesTitleBox, VpsServicesDescriptionBox } from '../../themes/boxHosting'
import settings from '../../helpers/settings'

const VpsServicesItem = ({ title, description, icon_url }) => {
  const lang = useSelector(state => state.lang)

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: '20px' }}>
        { icon_url && <img src={ settings.s3URL + settings.s3Bucket + icon_url } alt='' style={{ maxWidth: '90%' }} /> }
      </Box>
      <VpsServicesTitleBox>{title[lang]}</VpsServicesTitleBox>
      <VpsServicesDescriptionBox>{description[lang]}</VpsServicesDescriptionBox>
    </Box>
  )
}

export default VpsServicesItem