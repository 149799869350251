import { createSlice } from '@reduxjs/toolkit'

const initialState = '-100%'

const burgerRightSlice = createSlice({
  name: 'burgerRight',
  initialState,
  reducers: {
    setBurgerRight: (state, action) => {
      return action.payload
    }
  }
})

export const { setBurgerRight } = burgerRightSlice.actions

export default burgerRightSlice.reducer