import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const HostingPackageItemBox = styled(Box)(({ theme }) => ({
  padding: '30px 50px',
  background: '#ffffff',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '8px',
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
  margin: '0px 20px 10px 20px',

  '@media(max-width: 1199px)': {
    margin: '0px 10px',
    padding: '20px 20px',
  },

  '@media(max-width: 320px)': {
    margin: '0px 10px',
    padding: '10px 10px',
  },
}))

export const HostingPackageItemTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '20px',
  lineHeight: '28px',
  textTransform: theme.typography.mainmenu.textTransform,
  borderBottom: '1px solid rgba(7, 77, 120, 0.5)',
  paddingBottom: '10px',
  marginBottom: '10px',
  textAlign: 'center',

  '@media(max-width: 1800px)': {
    minHeight: '70px',
  },

  '@media(max-width: 1199px)': {
    minHeight: '1px',
  },

  '@media(max-width: 700px)': {
    minHeight: '70px',
  },

  '@media(max-width: 320px)': {
    fontSize: '16px',
  },
}))

export const VpsPackageItemTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '20px',
  lineHeight: '28px',
  textTransform: theme.typography.mainmenu.textTransform,
  borderBottom: '1px solid rgba(7, 77, 120, 0.5)',
  paddingBottom: '10px',
  marginBottom: '10px',
  textAlign: 'center',

  '@media(max-width: 400px)': {
    minHeight: '70px',
  },

  '@media(max-width: 320px)': {
    fontSize: '16px',
  },
}))

export const HostingPackageItemPriceBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.secondary.main,
  fontSize: '24px',
  lineHeight: '32px',
  textTransform: theme.typography.mainmenu.textTransform,
  marginBottom: '30px',
  textAlign: 'center',

  '@media(max-width: 320px)': {
    fontSize: '20px',
  },
}))

export const HostingPackageItemPriceValueBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
}))

export const HostingPackageItemOptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: theme.typography.mainmenu.textTransform,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '60px',
  borderBottom: '1px solid rgba(7, 77, 120, 0.2)',

  '@media (max-width: 1800px)': {
    fontSize: '14px',
    lineHeight: '20px',
    minHeight: '50px',
  },
}))

export const HostingPackageItemIconBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '20px',

  '@media(max-width: 320px)': {
    marginRight: '10px',
  },
}))

export const HostingPackageItemVariantBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  marginBottom: '5px',

  '@media(max-width: 320px)': {
    fontSize: '13px',
  },
}))

export const HostingPackageItemSmallBox = styled(Box)(({ theme }) => ({
  height: '40px',
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '16px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderColor: 'rgba(6, 14, 54, 0.2)',
  borderStyle: 'solid',
  borderRadius: '4px 0px 0px 4px',
  borderWidth: '1px 0px 1px 1px',
  cursor: 'pointer',

  '@media(max-width: 320px)': {
    fontSize: '14px',
  },
}))

export const HostingPackageItemMediumBox = styled(Box)(({ theme }) => ({
  height: '40px',
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '16px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderColor: 'rgba(6, 14, 54, 0.2)',
  borderStyle: 'solid',
  borderRadius: '0px 4px 4px 0px',
  borderWidth: '1px 1px 1px 0px',
  cursor: 'pointer',

  '@media(max-width: 320px)': {
    fontSize: '14px',
  },
}))

export const HostingPackageItemServicesBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: theme.typography.mainmenu.textTransform,
  borderBottom: `${theme.palette.primary.main} 1px solid`,
  paddingBottom: '5px',
  cursor: 'pointer',
  transition: 'all 0.2s',

  '&:hover': {
    borderBottom: `#ffffff 1px solid`,
  }
}))

export const HostingAdvantagesBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.darkblue.main,
  textTransform: theme.typography.mainmenu.textTransform,
  background: '#FFFFFF',
  padding: '50px',
  borderRadius: '8px',

  '@media(max-width: 1199px)': {
    padding: '30px',
    margin: '0px 30px',
  },

  '@media(max-width: 400px)': {
    padding: '20px',
  },
}))

export const HostingAdvantagesLabelBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '24px',
  marginBottom: '50px',
  textAlign: 'center',

  '@media(max-width: 1199px)': {
    fontSize: '20px',
    marginBottom: '30px',
  },

  '@media(max-width: 400px)': {
    fontSize: '18px',
  },
}))

export const HostingAdvantagesItemBox = styled(Box)({
  padding: '0px 40px',

  '@media(max-width: 1800px)': {
    padding: '0px 20px',
  },

  '@media(max-width: 1199px)': {
    padding: '0px 5px',
  }
})

export const VpsProcessBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  background: '#001834',
  padding: '50px 0px',
  marginBottom: '50px',

  '@media(max-width: 1199px)': {
    padding: '30px 20px',
  }
}))

export const VpsProcessLabelBox = styled(Box)(({ theme }) => ({
  color: theme.palette.offwhite.main,
  fontSize: '20px',
  lineHeight: '28px',
  marginBottom: '30px',
  textAlign: 'center',
}))

export const VpsProcessNumberBox = styled(Box)(({ theme }) => ({
  width: '36px',
  height: '36px',
  fontFamily: theme.typography.roboto.fontFamily,
  color: theme.palette.offwhite.main,
  border: `${theme.palette.offwhite.main} 1px solid`,
  borderRadius: '18px',
  fontSize: '16px',
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))

export const VpsProcessOuterBox = styled(Box)({
  width: '144px',
  height: '144px',
  borderWidth: '3px',
  borderStyle: 'solid',
  borderRadius: '72px',
  padding: '13px',
  marginBottom: '20px',
})

export const VpsProcessInnerBox = styled(Box)({
  width: '112px',
  height: '112px',
  borderWidth: '4px',
  borderStyle: 'solid',
  borderRadius: '56px',
  background: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const VpsProcessTitleBox = styled(Box)(({ theme }) => ({
  color: '#ffffff',
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
}))

export const VpsServicesLabelBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '24px',
  lineHeight: '32px',
  color: theme.palette.darkblue.main,
  textAlign: 'center',
  marginBottom: '30px',
}))

export const VpsServicesTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  fontSize: '20px',
  lineHeight: '28px',
  color: theme.palette.primary.main,
  textAlign: 'center',
  marginBottom: '10px',
}))

export const VpsServicesDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.darkblue.main,
  textAlign: 'center',
  padding: '0px 10%',
}))