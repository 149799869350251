export const aboutPage = [
  {
    id: '1',
    var_name: 'description',
    data: [
      {
        ka: 'განათლებისა და მეცნიერების სფეროში ინფორმაციული ტექნოლოგიების (IT) განვითარების მიზნით, 1999 წლის 26 ივლისს დაფუძნდა საქართველოს სამეცნიერო–საგანმანათლებლო კომპიუტერული ქსელების ასოციაცია „გრენა”. ასოციაცია „გრენა“-ს დამფუძნებლები არიან ხუთი უნივერსიტეტი, საქართველოს მეცნიერებათა ეროვნული აკადემია და ღია საზოგადოების ფონდი.',
        en: 'The Georgian Research and Educational Networking Association GRENA was founded on July 26, 1999 for the development of Information Technologies (IT) in education and research field. The founders of association GRENA are five universities, Georgian National Academy of Sciences and Open Society Georgia Foundation.',
      }
    ],
  },
  {
    id: '1',
    var_name: 'activities',
    data: [
      {
        title: {
          ka: 'ასოციაცია „გრენა”-ს საქმიანობა ხორციელდება შემდეგი ძირითადი მიმართულებით:',
          en: 'The main fields of GRENA activities are following:',
        },
        description: [
          {
            ka: 'მომხმარებლებისთვის IT სფეროში სერვისების მიწოდება და ახალი, ინოვაციური სერვისების დანერგვა.',
            en: 'Providing Information Technology services to users and implementation of new, innovative services.',
          },
          {
            ka: 'სწავლება ინფორმაციულ ტექნოლოგიებში.',
            en: 'IT education.',
          },
          {
            ka: 'სამეცნიერო და ტექნოლოგიური პროექტების მომზადება და განხორციელება.',
            en: 'Preparation and implementation of research and technology projects.',
          },
          {
            ka: 'მომხმარებლებისთვის პროგრამული პროდუქტების და კიბერუსაფრთხოების გადაწყვეტილებების მიწოდება.',
            en: 'Providing software products and cyber security solutions to customers.',
          },
          {
            ka: 'ქვეყანაში ინფორმაციული ტექნოლოგიების  განვითარების ხელშეწყობა.',
            en: 'Promoting and supporting IT development in Georgia.',
          },
        ],
      }
    ],
  },
  {
    id: '2',
    var_name: 'video',
    data: [
      {
        video: '2lEzkxi7uSA',
      }
    ],
  },
]

export const aupPage = [
  {
    id: '1',
    var_name: 'description',
    data: [
      {
        ka: 'Acceptable Use Policy',
        en: 'Acceptable Use Policy',
      },
      {
        ka: 'The Georgian Research and Educational Networking Association - GRENA network is a national computer network for universities, educational and research institutions in Georgia. The following organizations can be connected to the GRENA network:',
        en: 'The Georgian Research and Educational Networking Association - GRENA network is a national computer network for universities, educational and research institutions in Georgia. The following organizations can be connected to the GRENA network:',
      },
      {
        ka: 'The information and communication services (including Internet) provided by GRENA are intended both for the staff and students.',
        en: 'The information and communication services (including Internet) provided by GRENA are intended both for the staff and students.',
      },
      {
        ka: 'Other organizations could be connected to GRENA network temporary for testing and developing advanced networking technologies and applications.',
        en: 'Other organizations could be connected to GRENA network temporary for testing and developing advanced networking technologies and applications.',
      },
    ],
  },
  {
    id: '2',
    var_name: 'organizations',
    data: [
      {
        ka: 'Universities',
        en: 'Universities',
      },
      {
        ka: 'Institutes for higher educations',
        en: 'Institutes for higher educations',
      },
      {
        ka: 'Research institutes',
        en: 'Research institutes',
      },
      {
        ka: 'Secondary schools',
        en: 'Secondary schools',
      },
      {
        ka: 'Libraries',
        en: 'Libraries',
      },
      {
        ka: 'Museums',
        en: 'Museums',
      },
      {
        ka: 'Academic hospitals',
        en: 'Academic hospitals',
      },
      {
        ka: 'International and local organizations working in research and education and their programs',
        en: 'International and local organizations working in research and education and their programs',
      },
      {
        ka: 'Etc.',
        en: 'Etc.',
      },
    ],
  },
  {
    id: '3',
    var_name: 'illustration',
    data: [
      {
        illustration: ''
      }
    ],
  },
]