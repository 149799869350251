import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { InternetServicesLabelBox } from '../../themes/boxInternet'
import { constants } from '../../data/constants'
import InternetServicesCategory from './InternetServicesCategory'

const InternetServices = ({ cats, services }) => {
  const lang = useSelector(state => state.lang)
  
  return (
    <>
      <InternetServicesLabelBox>{constants.ourServices[lang]}</InternetServicesLabelBox>
      <Grid container spacing={{ xs: 4, md: 4, lg: 8 }}>
        {
          cats.map((item, index) => (
            <InternetServicesCategory key={index} {...item} services={services} />
          ))
        }
      </Grid>
    </>
  )
}

export default InternetServices