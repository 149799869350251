import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { EduroamFaqBox, EduroamFaqLabelBox, EduroamFaqLabelTextBox } from '../../themes/boxEduroam'
import { constants } from '../../data/constants'
import EduroamFaqItem from './EduroamFaqItem'
import ModalWindow from '../layout/ModalWindow'

const EduroamFaq = ({ faq }) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')

  const hanldeOpenModal = ({ question, answer }) => {
    const content = <Box sx={{ fontFamily: theme.typography.regular.fontFamily }}>{answer}</Box>
    
    setModalOpen(true)
    setModalTitle(question)
    setModalContent(content)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }
  
  return (
    <EduroamFaqBox id='faq'>
      <EduroamFaqLabelBox>
        <EduroamFaqLabelTextBox>{constants.faqFull[lang]}</EduroamFaqLabelTextBox>
      </EduroamFaqLabelBox>
      <Grid container>
        {
          faq.map((item, index) => (
            <EduroamFaqItem key={index} index={index} handleClick={hanldeOpenModal} {...item} />
          ))
        }
      </Grid>
      <ModalWindow
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButtons=''
        modalWidth='md'
        onClose={handleCloseModal}
      />
    </EduroamFaqBox>
  )
}

export default EduroamFaq