import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import {
  HostingPackageItemBox,
  VpsPackageItemTitleBox,
  HostingPackageItemPriceBox,
  HostingPackageItemPriceValueBox,
  HostingPackageItemOptionBox,
  HostingPackageItemIconBox
} from '../../themes/boxHosting'
import WifiIcon from '@mui/icons-material/Wifi'
import MemoryIcon from '@mui/icons-material/Memory'
import SpeedIcon from '@mui/icons-material/Speed'
import { DatabaseBackup } from 'lucide-react'
import { MemoryStick } from 'lucide-react'
import { IconContext } from 'react-icons'
import { BsDeviceSsd } from 'react-icons/bs'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'
import ContentButton from '../form/ContentButton'

const VpsPackagesItem = ({
  name,
  price,
  cpu,
  ram,
  ssd,
  bandwidth,
  backup,
  traffic,
  setModalOpen,
  setModalTitle,
  setModalContent
}) => {
  const theme = useTheme()

  const lang = useSelector(state => state.lang)

  const priceValue = price ? (
    <>
      <HostingPackageItemPriceValueBox component='span'>{price} &#8382; / </HostingPackageItemPriceValueBox>
      <Box component='span'>{constants.month[lang]}</Box>
    </>  
  ) : constants.priceCustom[lang]

  const sxOnlyEng = {
    fontFamily: `${theme.typography.roboto.fontFamily} !important`
  }

  const contactFormName = price ? settings.formVps : settings.formVpsCustom

  const sxButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.offwhite.main,
    background: theme.palette.primary.main,
    borderRadius: '8px',
    width: '100%',
    height: '56px',
    padding: '0px',
    boxShadow: 'none',
    textTransform: theme.typography.mainmenu.textTransform,
    position: 'relative',
    zIndex: '4',

    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
  }

  return (
    <HostingPackageItemBox>

      <VpsPackageItemTitleBox>{name[lang]}</VpsPackageItemTitleBox>

      <HostingPackageItemPriceBox>{priceValue}</HostingPackageItemPriceBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <MemoryIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box sx={sxOnlyEng}>{cpu ? cpu : 'X'} {constants.cpu[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <MemoryStick size={32} />
        </HostingPackageItemIconBox>
        <Box sx={sxOnlyEng}>{ram ? ram : 'XX'} {constants.ram[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <IconContext.Provider value={{ size: '32px' }}>
            <BsDeviceSsd />
          </IconContext.Provider>
        </HostingPackageItemIconBox>
        <Box sx={sxOnlyEng}>{ssd ? ssd : 'XXX'} {constants.ssd[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <SpeedIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.speed[lang]} {bandwidth[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox>
        <HostingPackageItemIconBox>
          <DatabaseBackup size={32} />
        </HostingPackageItemIconBox>
        <Box>{backup[lang]} {constants.backup[lang]}</Box>
      </HostingPackageItemOptionBox>

      <HostingPackageItemOptionBox sx={{ borderBottom: 'none' }}>
        <HostingPackageItemIconBox>
          <WifiIcon fontSize='large' />
        </HostingPackageItemIconBox>
        <Box>{constants.traffic[lang]} {traffic[lang]}</Box>
      </HostingPackageItemOptionBox>

      <Box sx={{ margin: '30px 5% 0px 5%' }}>
        <ContentButton
          formTitle={name[lang]}
          formType='content'
          formName={contactFormName}
          formID=''
          openButton={constants.order[lang]}
          openButtonStyle={sxButton}
          submitButton={constants.send[lang]}
          submitButtonStyle=''
          setModalOpen={setModalOpen}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
        />
      </Box>

    </HostingPackageItemBox>
  )
}

export default VpsPackagesItem