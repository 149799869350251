import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { EdugainDocsBox, EdugainDocsCategoryBox } from '../../themes/boxEdugain'
import { constants } from '../../data/constants'
import EdugainDocsItem from './EdugainDocsItem'

const EdugainDocs = ({ docs }) => {
  const lang = useSelector(state => state.lang)

  const docsFiltered = docs.filter(item => item.type === 'doc')
  const metadata = docs.filter(item => item.type === 'metadata')

  return (
    <EdugainDocsBox>
      <Grid container spacing={{ xs: 2, lg: 6 }}>
        <Grid xs={12} lg={6}>
          <EdugainDocsCategoryBox>{constants.documents[lang]}</EdugainDocsCategoryBox>
          {
            docsFiltered.map((item, index) => (
              <EdugainDocsItem key={index} {...item} />
            ))
          }
        </Grid>
        <Grid xs={12} lg={6}>
          <EdugainDocsCategoryBox>{constants.metadata[lang]}</EdugainDocsCategoryBox>
          {
            metadata.map((item, index) => (
              <EdugainDocsItem key={index} {...item} />
            ))
          }
        </Grid>
      </Grid>
    </EdugainDocsBox>
  )
}

export default EdugainDocs