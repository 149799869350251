import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  EducationCertificationLogoBox,
  EducationCertificationTextBox,
  EducationCertificationTitleBox,
  EducationCertificationDescriptionBox
} from '../../themes/boxEducation'
import { EducationCertificationMoreButton } from '../../themes/button'
import { constants } from '../../data/constants'
import settings from '../../helpers/settings'

const EducationCertificationItem = ({ logo_url, website, name, description }) => {
  const lang = useSelector(state => state.lang)
  
  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(website, '_blank')
    } else if (e.type === 'click') {
      window.open(website, '_blank')
    }
  }

  return (
    <Box>
      <Grid container columnSpacing={2}>
        <Grid xs={12} lg={4}>
          <EducationCertificationLogoBox>{ logo_url && <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90%', cursor: 'pointer' }} onClick={handleClick} onMouseDown={handleClick} /> }</EducationCertificationLogoBox>
        </Grid>
        <Grid xs={12} lg={8}>
          <EducationCertificationTextBox>
            <EducationCertificationTitleBox>{name}</EducationCertificationTitleBox>
            <EducationCertificationDescriptionBox>{description}</EducationCertificationDescriptionBox>
            <EducationCertificationMoreButton variant='text' endIcon={<ArrowForwardIcon />} onClick={handleClick} onMouseDown={handleClick}>{constants.learnMore[lang]}</EducationCertificationMoreButton>
          </EducationCertificationTextBox>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EducationCertificationItem