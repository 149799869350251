import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import softwareLine from './images/software_line.png'

export const SoftwareDescriptionBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '50px',
}))

export const SoftwareProcessTitleBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  textTransform: theme.typography.mainmenu.textTransform,
  color: theme.palette.darkblue.main,
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'center',
  padding: '10px',
  border: `${theme.palette.primary.main} 1px solid`,
  borderRadius: '8px',
  boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}))

export const SoftwareCategoryTitleBox = styled(Box)({
  height: '224px',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'all 0.2s',

  '&:hover': {
    background: '#2E9CD6',
  },

  '@media(max-width: 1199px)': {
    margin: '0px 10px',
  },

  '@media(max-width: 899px)': {
    margin: '0px 10px',

    '&:hover': {
      background: 'rgba(6, 14, 54, 0.9)',
    },
  }
})

export const SoftwareCategoryTitleBodyBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '112px',
  padding: '0px 10%',
})

export const SoftwareCategoryTitleTextBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.mainmenu.fontFamily,
  color: theme.palette.offwhite.main,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: theme.typography.mainmenu.textTransform,

  '@media(max-width: 1199px)': {
    fontSize: '14px',
  }
}))

export const SoftwareCategoryLineBox = styled(Box)({
  height: '112px',
  background: `url(${softwareLine}) no-repeat center top`,
  transition: 'opacity 0.3s',

  '@media(max-width: 1199px)': {
    height: '50px',
  }
})

export const SoftwarePartnersBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.regular.fontFamily,
  color: theme.palette.darkblue.main,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '50px',
  border: '4px solid rgba(115, 150, 169, 0.7)',
  borderRadius: '8px',
  transition: 'opacity 0.3s',
  background: '#ffffff',

  '@media(max-width: 899px)': {
    margin: '0px 10px',
    padding: '10px',
  }
}))

export const SoftwarePartnersImageBox = styled(Box)({
  height: '80px',
  padding: '0px 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s',

  '@media(max-width: 1199px)': {
    height: 'auto',
    marginBottom: '10px'
  }
})