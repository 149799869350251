import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { mainMenuItems } from '../../data/mainmenu'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiMenus } from '../../helpers/urls'
import { sortByPriority } from '../../helpers/array'
import MainMenuItem from './MainMenuItem'
import MainMenuDropdown from './MainMenuDropdown'

const MainMenu = () => {
  const [menu, setMenu] = useState(sortByPriority(mainMenuItems))

  useEffect(() => {
    const fetchMenuItem = async (item) => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenuItems + '/' + item, 'GET')

      if (response && response.is_visible) {
        return response
      }
    }

    const fetchMenu = async () => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenus + '/main_menu', 'GET')
      
      if (response && response.items && response.items.length > 0) {
        let temp = []

        for (let index = 0; index < response.items.length; index ++ ) {
          const menuItem = await fetchMenuItem(response.items[index])
          if (menuItem) {
            temp.push(menuItem)
          }          
        }
        
        setMenu(sortByPriority(temp))
      } else {
        setMenu(sortByPriority(mainMenuItems))
      }
    }

    if (settings.mode === 'live') {
      fetchMenu()
    }
  }, [])
  
  return (
    <Box>
      {
        menu.map((item, index) => (
          item.has_submenu ? <MainMenuDropdown key={index} {...item} /> : <MainMenuItem key={index} {...item} />
        ))
      }
    </Box>
  )
}

export default MainMenu