import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { ToolbarItemBox, ToolbarSubItemTextBox } from '../../themes/boxLayout'
import { mirrorsItems } from '../../data/toolbar'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiMenus } from '../../helpers/urls'
import { sortByTitle } from '../../helpers/array'

const ToolbarMirrors = ({ sxSubmenuIfOpen, sxSubmenuIfClose, submenu, handleSetMirrorsCount }) => {
  const lang = useSelector(state => state.lang)

  const [mirrors, setMirrors] = useState([])

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  useEffect(() => {
    const fetchMenuItem = async (item) => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenuItems + '/' + item, 'GET')

      if (response && response.is_visible) {
        return response
      }
    }

    const fetchMirrors = async (submenu_name) => {
      const response = await request(settings.domain + settings.api + apiMenus.getMenus + '/' + submenu_name, 'GET')

      if (response && response.items && response.items.length > 0) {
        let temp = []

        for (let index = 0; index < response.items.length; index ++ ) {
          const menuItem = await fetchMenuItem(response.items[index])
          
          if (menuItem) {
            temp.push(menuItem)
          }
          
          if (temp.length === response.items.length) {
            setMirrors(sortByTitle(temp, lang))
            handleSetMirrorsCount(temp.length)
          }
        }
      } else {
        setMirrors(sortByTitle(mirrorsItems, lang))
        handleSetMirrorsCount(mirrorsItems.length)
      }
    }

    if (settings.mode === 'live' && submenu) {
      fetchMirrors(submenu)
    } else {
      setMirrors(sortByTitle(mirrorsItems, lang))
      handleSetMirrorsCount(mirrorsItems.length)
    }
  }, [submenu, handleSetMirrorsCount, lang])
  
  return (
    <>
      <Box sx={sxSubmenuIfOpen}>
        {
          mirrors.map((item, index) => (
            <ToolbarItemBox key={index} sx={{ height: '24px' }}>
              <ToolbarSubItemTextBox sx={ index === mirrors.length - 1 && { borderBottom: 'none' } } onClick={() => handleClick(item.go_to_url)}>{item.title[lang]}</ToolbarSubItemTextBox>
            </ToolbarItemBox>
          ))
        }
      </Box>
      <Box sx={sxSubmenuIfClose}>
        {
          mirrors.map((item, index) => (
            <ToolbarItemBox key={index} sx={{ height: '24px' }}>
              <ToolbarSubItemTextBox sx={ index === mirrors.length - 1 && { borderBottom: 'none' } } onClick={() => handleClick(item.go_to_url)}>{item.title[lang]}</ToolbarSubItemTextBox>
            </ToolbarItemBox>
          ))
        }
      </Box>
    </>
  )
}

export default ToolbarMirrors