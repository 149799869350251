import React from 'react'
import { useSelector } from 'react-redux'
import { SoftwareCategoryTitleBox, SoftwareCategoryTitleBodyBox, SoftwareCategoryTitleTextBox } from '../../themes/boxSoftware'
import settings from '../../helpers/settings'

const SoftwarePartnersMobileItem = ({ name, icon_url, handleClick }) => {
  const lang = useSelector(state => state.lang)

  return (
    <>
      <SoftwareCategoryTitleBox sx={{ background: 'rgba(6, 14, 54, 0.9)' }} onClick={handleClick}>
        <SoftwareCategoryTitleBodyBox>
          <SoftwareCategoryTitleTextBox>{name[lang]}</SoftwareCategoryTitleTextBox>
        </SoftwareCategoryTitleBodyBox>
        <SoftwareCategoryTitleBodyBox>
          { icon_url && <img src={ settings.s3URL + settings.s3Bucket + icon_url } alt='' style={{ maxWidth: '80%' }} /> }
        </SoftwareCategoryTitleBodyBox>
      </SoftwareCategoryTitleBox>
    </> 
  )
}

export default SoftwarePartnersMobileItem