import React from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import { EducationAcademyItemLeftGrid } from '../../themes/grid'
import { EducationAcademyLogoBox, EducationAcademyTitleBox } from '../../themes/boxEducation'
import settings from '../../helpers/settings'

const EducationAcademyItemLeft = ({ id, name, logo_url, index, white, handleMouseEnter, handleMouseLeave }) => {
  const navigate = useNavigate()

  const handleClick = (e) => {
    if (e.ctrlKey || e.button === 1) {
      window.open(`/education/${id}`, '_blank')
    } else if (e.type === 'click') {
      navigate(`/education/${id}`)
    }
  }

  return (
    <EducationAcademyItemLeftGrid container spacing={0} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} onClick={handleClick} onMouseDown={handleClick}>
      <Grid xs={4}>
        <EducationAcademyLogoBox>
          { logo_url && <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90px' }} /> }
        </EducationAcademyLogoBox>
      </Grid>
      <Grid xs={8}>
        <EducationAcademyTitleBox sx={ white && { color: '#FFFFFF' } }>{name}</EducationAcademyTitleBox>
      </Grid>
    </EducationAcademyItemLeftGrid>
  )
}

export default EducationAcademyItemLeft