import React from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import MainMenu from './MainMenu'
import Logo from './Logo'
// import HeaderProfileButton from './HeaderProfileButton'

const HeaderTop = () => {
  return (
    <Container maxWidth='xl'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={0}
        sx={{ height: '120px' }}
      >
        <Logo />
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={0}
        >
          <MainMenu />
          {/* <HeaderProfileButton /> */}
        </Stack>
      </Stack>
    </Container>
  )
}

export default HeaderTop