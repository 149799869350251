import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { IconContext } from 'react-icons'
import { RiFontSize } from 'react-icons/ri'
import { HiOutlineBookOpen } from 'react-icons/hi'
import {
  AcademyCoursesItemBox,
  AcademyCoursesTitleBox,
  AcademyCoursesInfoBox,
  AcademyCoursesRegistrationOpenBox,
  AcademyCoursesPriceLabelBox,
  AcademyCoursesPriceValueBox
} from '../../themes/boxEducation'
import { alpha } from '@mui/material'
import { constants } from '../../data/constants'
import ContentButton from '../form/ContentButton'
import settings from '../../helpers/settings'
import useWindowDimensions from '../../helpers/dimensions'

const AcademyCoursesItem = ({
  id,
  name,
  duration_range,
  duration_unit,
  frequency,
  language,
  materials_language,
  price,
  courseLanguages,
  courseDurationUnits,
  courseRegistrations,
  setModalOpen,
  setModalTitle,
  setModalContent,
  setBackdropOpen,
  courseClickable
}) => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const durationUnitObject = courseDurationUnits.find(item => item.id === duration_unit)
  const durationUnit = durationUnitObject ? durationUnitObject.title[lang] : ''
  const durationValue = duration_range.join('-') + ' ' + durationUnit

  const courseLanguageObject = courseLanguages.find(item => item.id === language)
  const courseLanguage = courseLanguageObject ? courseLanguageObject.title[lang] : ''

  const courseMaterialsLanguageObject = courseLanguages.find(item => item.id === materials_language)
  const courseMaterialsLanguage = courseMaterialsLanguageObject ? courseMaterialsLanguageObject.title[lang] : ''

  const registrationOpenObject = courseRegistrations.find(item => (item.course === id && item.is_opened))
  const registrationOpen = registrationOpenObject ? true : false
  const registrationID = registrationOpenObject ? registrationOpenObject.id : ''
  const registrationFormID = registrationOpenObject ? registrationOpenObject.form_id : ''

  const sxRegistrationButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.offwhite.main,
    textTransform: theme.typography.mainmenu.textTransform,
    background: '#2E9CD6',
    borderRadius: '8px',
    width: '80%',
    height: '72px',
    boxShadow: 'none',

    '&:hover': {
      background: alpha('#2E9CD6', 0.7),
      boxShadow: 'none',
    },
  }

  const sxSubscribtionButton = {
    fontFamily: theme.typography.mainmenu.fontFamily,
    fontSize: theme.typography.mainmenu.fontSize,
    color: theme.palette.primary.main,
    textTransform: theme.typography.mainmenu.textTransform,
    background: '#FF9A03',
    borderRadius: '8px',
    width: '80%',
    height: '72px',
    boxShadow: 'none',
    opacity: '1',
    cursor: 'pointer',

    '&:hover': {
      background: alpha('#FF9A03', 0.7),
      boxShadow: 'none',
    },
  }

  const handleCourseClick = (e) => {
    if (courseClickable) {
      if (e.ctrlKey || e.button === 1) {
        window.open(`/course/${id}`, '_blank')
      } else if (e.type === 'click') {
        navigate(`/course/${id}`)
      } 
    }
  }

  return (
    <AcademyCoursesItemBox onClick={handleCourseClick} onMouseDown={handleCourseClick}>
      <Grid container>
        <Grid xs={12} md={6} lg={5}>
          { (registrationOpen && location.pathname.includes('/education/') && width <= settings.desktopBreakpoint) && <Box sx={{ width: '1px', height: '30px', }}></Box> }

          <AcademyCoursesTitleBox>{name[lang]}</AcademyCoursesTitleBox>
          
          {
            durationUnit && (
              <AcademyCoursesInfoBox>
                <CalendarTodayIcon sx={{ fontSize: '48px', paddingRight: '10px', marginBottom: '-16px', }} />
                <Box component='span'>{constants.duration[lang]}: </Box>
                <Box component='span' sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', }}>{durationValue}</Box>
              </AcademyCoursesInfoBox>
            )
          }

          {
            frequency > 0 && (
              <AcademyCoursesInfoBox>
                <ScheduleIcon sx={{ fontSize: '48px', paddingRight: '10px', marginBottom: '-16px', }} />
                <Box component='span'>{constants.lectures[lang]}: </Box>
                <Box component='span' sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', }}>{constants.frequency[lang].replace('###NUM###', frequency)}</Box>
              </AcademyCoursesInfoBox>
            )
          }

          {
            courseLanguage && (
              <AcademyCoursesInfoBox>
                <IconContext.Provider value={{ color: '#DDDDDD', size: '36px' }}>
                  <RiFontSize style={{ margin: '0px 13px -6px 0px' }} />
                </IconContext.Provider>
                <Box component='span'>{constants.language[lang]}: </Box>
                <Box component='span' sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', }}>{courseLanguage}</Box>
              </AcademyCoursesInfoBox>
            )
          }

          {
            materials_language && (
              <AcademyCoursesInfoBox>
                <IconContext.Provider value={{ color: '#DDDDDD', size: '36px' }}>
                  <HiOutlineBookOpen style={{ margin: '0px 13px -10px 0px' }} />
                </IconContext.Provider>
                <Box component='span'>{constants.learningMaterial[lang]}: </Box>
                <Box component='span' sx={{ fontFamily: `${theme.typography.labels.fontFamily}`, fontWeight: 'bold', }}>{courseMaterialsLanguage}</Box>
              </AcademyCoursesInfoBox>
            )
          }
        </Grid>
        <Grid xs={12} md={5} lg={4} xsOffset={0} mdOffset={1} lgOffset={3}>
            { (registrationOpen && location.pathname.includes('/education/')) && <AcademyCoursesRegistrationOpenBox>{constants.registrationIsOpen[lang]}</AcademyCoursesRegistrationOpenBox> }
            {
              price > 0 && (
                <>
                  <AcademyCoursesPriceLabelBox>{constants.price[lang]}</AcademyCoursesPriceLabelBox>
                  <AcademyCoursesPriceValueBox>{price} &#8382;</AcademyCoursesPriceValueBox>
                </> 
              )
            }
            {
              registrationOpen ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContentButton
                    formTitle=''
                    formType='academy_registration'
                    formName={registrationID}
                    formID={registrationFormID}
                    openButton={constants.register[lang]}
                    openButtonStyle={sxRegistrationButton}
                    submitButton={constants.register[lang]}
                    submitButtonStyle=''
                    setModalOpen={setModalOpen}
                    setModalTitle={setModalTitle}
                    setModalContent={setModalContent}
                    setBackdropOpen={setBackdropOpen}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ContentButton
                    formTitle=''
                    formType='academy_subscription'
                    formName={id}
                    formID=''
                    openButton={constants.subscribe[lang]}
                    openButtonStyle={sxSubscribtionButton}
                    submitButton={constants.send[lang]}
                    submitButtonStyle=''
                    setModalOpen={setModalOpen}
                    setModalTitle={setModalTitle}
                    setModalContent={setModalContent}
                    setBackdropOpen={setBackdropOpen}
                  />
                </Box>
              )
            }
        </Grid>
      </Grid>
    </AcademyCoursesItemBox>
  )
}

export default AcademyCoursesItem