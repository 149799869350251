import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import { RegularPageFluidBox, RegularPageTitleBox, RegularPageContentR5Box } from '../../themes/boxLayout'
import { coursesItems, courseLanguagesItems, courseDurationUnitsItems, courseRegistrationsItems } from '../../data/education'
import { constants } from '../../data/constants'
import request from '../../helpers/request'
import settings from '../../helpers/settings'
import { apiEducation } from '../../helpers/urls'
import useWindowDimensions from '../../helpers/dimensions'
import AcademyCoursesItem from './AcademyCoursesItem'
import ModalWindow from '../layout/ModalWindow'
import BackdropElement from '../layout/BackdropElement'
import ErrorMessage from '../layout/ErrorMessage'
import HelmetElement from '../layout/HelmetElement'

const CoursesActive = () => {
  const { width } = useWindowDimensions()

  const lang = useSelector(state => state.lang)

  const [courses, setCourses] = useState([])
  const [courseLanguages, setCourseLanguages] = useState([])
  const [courseDurationUnits, setCourseDurationUnits] = useState([])
  const [courseRegistrations, setCourseRegistrations] = useState([])
  const [backdropOpen, setBackdropOpen] = useState(true)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [courseClickable, setCourseClickable] = useState(true)

  const backdropOpenRef = useRef(backdropOpen)
  backdropOpenRef.current = backdropOpen

  const sxContainer = { paddingTop: width > settings.desktopBreakpoint ? '104px' : '0px' }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchCourseLanguages = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseLanguages, 'GET')

      if (response && response.length > 0) {
        setCourseLanguages(response)
      } else {
        setCourseLanguages([])
      }
    }

    const fetchCourseDurationUnits = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseDurationUnits, 'GET')

      if (response && response.length > 0) {
        setCourseDurationUnits(response)
      } else {
        setCourseDurationUnits([])
      }
    }

    const fetchCourseRegistrations = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourseRegistrations, 'GET')

      if (response && response.length > 0) {
        setCourseRegistrations(response)
      } else {
        setCourseRegistrations([])
      }
    }

    if (settings.mode === 'live') {
      fetchCourseLanguages()
      fetchCourseDurationUnits()
      fetchCourseRegistrations()
    } else {
      setCourseLanguages(courseLanguagesItems)
      setCourseDurationUnits(courseDurationUnitsItems)
      setCourseRegistrations(courseRegistrationsItems)
    }
  }, [])

  useEffect(() => {
    const getFilteredCourses = (a) => {
      let activeCoursesIDs = []
  
      courseRegistrations.forEach(item => {
        if (item.is_opened) activeCoursesIDs.push(item.course)
      })
  
      return a.filter(item => (activeCoursesIDs.includes(item.id) && item.is_active))
    }

    const fetchCourses = async () => {
      const response = await request(settings.domain + settings.api + apiEducation.getCourses, 'GET')

      if (response && response.length > 0) {
        setCourses(getFilteredCourses(response))
      } else {
        setCourses([])
      }
    }
    
    if (settings.mode === 'live') {
      fetchCourses()
    } else {
      setCourses(getFilteredCourses(coursesItems))
    }

    setTimeout(() => {
      if (backdropOpenRef.current) {
        setBackdropOpen(false)
        setErrorMessageOpen(true)
      }
    }, settings.errorMessageTimeout)
  }, [courseRegistrations])

  useEffect(() => {
    if (modalOpen) {
      setCourseClickable(false)
    } else {
      setCourseClickable(true)
    }
  }, [modalOpen])

  useEffect(() => {
    if (courses.length > 0) {
      setBackdropOpen(false)
      setErrorMessageOpen(false)
    }
  }, [courses])
  
  return (
    <>
      <HelmetElement
        metaDescription='Education, Academy, Active Courses'
      />

      <RegularPageFluidBox>
        <BackdropElement open={backdropOpen} />
        <ErrorMessage open={errorMessageOpen} />
        <Container maxWidth='xl' sx={sxContainer}> 
          <Grid container>
            <Grid xs={12} lg={5}>
              <RegularPageTitleBox>{constants.activeCourses[lang]}</RegularPageTitleBox>
            </Grid>
          </Grid>
          <RegularPageContentR5Box>
            {
              courses.map((item, index) => (
                <AcademyCoursesItem
                  key={index}
                  {...item}
                  courseLanguages={courseLanguages}
                  courseDurationUnits={courseDurationUnits}
                  courseRegistrations={courseRegistrations}
                  setModalOpen={setModalOpen}
                  setModalTitle={setModalTitle}
                  setModalContent={setModalContent}
                  setBackdropOpen={setBackdropOpen}
                  courseClickable={courseClickable}
                />
              ))
            }
            <ModalWindow
              modalOpen={modalOpen}
              modalTitle={modalTitle}
              modalContent={modalContent}
              modalButtons={[]}
              modalWidth='lg'
              onClose={handleCloseModal}
            />
          </RegularPageContentR5Box>
        </Container>
      </RegularPageFluidBox>
    </>
  )
}

export default CoursesActive