import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

const FormMultipleSelect = ({ id, label, values, required, error, selectItems, handleChange, fixedWidth }) => {
  const getRenderValue = (selected) => {
    let titles = []
    selectItems.forEach(item => {
      if (selected.includes(item.value)) titles.push(item.title)
    })
    return titles.join(', ')
  }

  return (
    <FormControl>
      <InputLabel id={id}>{ required ? `${label} *` : label}</InputLabel>
      <Select
        labelId={id}
        multiple
        label={ required ? `${label} *` : label}
        error={error}
        value={values}
        renderValue={(selected) => getRenderValue(selected)}
        onChange={e => handleChange(e.target.value)}
        sx={{ width: fixedWidth ? fixedWidth : '100%' }}
      >
        {
          selectItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Checkbox checked={values.includes(item.value)} />
              <ListItemText primary={item.title} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default FormMultipleSelect