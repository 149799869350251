const request = async (url, method, data) => {
  const options = {
    method,
    headers: { 'Content-Type': 'application/json' },
  }

  if (method === 'POST') {
    options.body = JSON.stringify(data)
  }

  const response = await fetch(url, options)
  
  if (response.ok) {
    return await response.json()
  } else {
    return { error: true }
  }
}

export default request