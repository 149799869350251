import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { HomePartnersItemBox } from '../../themes/boxHome'
import settings from '../../helpers/settings'

const HomePartnersItem = ({ logo_url, website, opacity }) => {
  const handleClick = (e) => {
    if (opacity === 1) {
      if (e.ctrlKey || e.button === 1) {
        window.open(website, '_blank')
      } else if (e.type === 'click') {
        window.open(website, '_blank')
      }   
    }
  }

  return (
    <Grid xs={6} sm={4}>
      <HomePartnersItemBox sx={{ cursor: opacity === 1 ? 'pointer' : 'default' }} onClick={handleClick} onMouseDown={handleClick}>
        <img src={ settings.s3URL + settings.s3Bucket + logo_url } alt='' style={{ maxWidth: '90%', maxHeight: '90%' }} />
      </HomePartnersItemBox>
    </Grid>
  )
}

export default HomePartnersItem